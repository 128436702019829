<template>
    <v-dialog v-model="dialog" persistent max-width="1500px">

        <v-card :loading="loading">
            <v-card-text>
                <v-tabs v-model="tab">
                    <v-tab v-if="viewTab && !rubrica">
                        <v-badge dot v-if="!valid1" color="red">Anagrafica</v-badge><span v-else>Anagrafica</span>
                    </v-tab>
                    <v-tab v-if="viewTab && (!nuovo || rubrica)">
                        <v-badge dot v-if="!valid2" color="red">Recapiti</v-badge><span v-else>Recapiti</span>
                    </v-tab>
                    <v-tab v-if="viewTab && (!nuovo && !rubrica)">
                        <v-badge dot v-if="!valid3" color="red">Sedi/Indirizzi</v-badge><span v-else>Sedi/Indirizzi</span>
                    </v-tab>
                    <v-tab v-if="isStudioPro && !isStudioProLight && viewTab && (!nuovo  && !rubrica && (($store.state.utente && $store.state.utente.isGestoreStudio) || ($store.state.utente && $store.state.utente.operatore.professionista && $store.state.utente.operatore.professionista.id==cliente.professionista.id)))">
                        <v-badge dot v-if="!valid4" color="red">Tariffe</v-badge><span v-else>Tariffe</span>
                    </v-tab>
                    <v-tab v-if="isServicePro && viewTab && (!nuovo && !rubrica && ($store.state.utente && ($store.state.utente.isGestoreStudio || $store.state.utente.isReception)))">
                        <v-badge dot v-if="!valid4" color="red">Contratti e Servizi</v-badge><span v-else>Contratti e Servizi</span>
                    </v-tab>
                    <v-tab v-if="viewTab && (!nuovo  && !rubrica)">
                        <v-badge dot v-if="!valid5" color="red">Operatori</v-badge><span v-else>Operatori</span>
                    </v-tab>
                    <v-tab v-if="viewTab && (!nuovo && !rubrica)">
                        <v-badge dot v-if="!valid6" color="red">Utenza App</v-badge><span v-else>Utenza App</span>
                    </v-tab>
                    <v-tab v-if="viewTab && (!nuovo && !rubrica)">
                        <v-badge dot v-if="!valid7" color="red">Documenti</v-badge><span v-else>Documenti</span>
                    </v-tab>
                    <v-spacer></v-spacer>
                    <v-btn class="mt-2 mr-2" color="teal" 
                           :dark="valid1 && valid2 && valid3 && valid4 && valid5 && valid6 && $store.state.annoAperto"
                           @click="salva"
                           :loading="loadingSalva"
                           :disabled="!valid1 || !valid2 || !valid3 || !valid4 || !valid5 || !valid6 || !valid7 || !$store.state.annoAperto ">
                        Salva
                    </v-btn>
                    <v-btn class="mt-2 mr-2"
                           color="orange darken-2" 
                           :dark="$store.state.annoAperto"
                           :disabled="!$store.state.annoAperto"
                           :loading="loadingAnnulla"
                           @click="annulla">
                        Annulla modifiche
                    </v-btn>
                    <v-btn class="mt-2 mr-2"
                           color="blue darken-2" dark
                           @click.stop="chiudi">
                        Esci
                    </v-btn>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-form ref="form1"
                            v-model="valid1"
                            lazy-validation>
                        <v-tab-item v-if="viewTab && !rubrica">
                            <div v-if="isContabilita || isLavoro">

                                <v-row class="mt-4">
                                    <v-col cols="3">
                                        <v-select v-model="cliente.tipoCliente.id"
                                                  :items="listaTipoCliente"
                                                  item-value="id"
                                                  item-text="descrizione"
                                                  label="Tipologia Cliente"
                                                  :rules="[v=> !!v || 'indicare il tipo di cliente']"
                                                  outlined
                                                  v-on:change="setTipoCliente(cliente.tipoCliente.id)"
                                                  dense></v-select>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="cliente.codice"
                                                      label="Codice"
                                                      required
                                                      outlined
                                                      dense></v-text-field>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-select v-model="cliente.professionista.id"
                                                  :items="listaOperatoriProfessionisti"
                                                  item-value="professionista.id"
                                                  item-text="denominazioneNormalizzata"
                                                  label="Professionista di riferimento"
                                                  :rules="[v=> !!v || 'indicare il professionista']"
                                                  outlined
                                                  dense></v-select>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-autocomplete v-model="listaTags"
                                                        :items="listaTagsIni"
                                                        :search-input.sync="labelInput"
                                                        @keyup.enter="aggiungiTag"
                                                        chips
                                                        dense
                                                        outlined
                                                        multiple
                                                        :label="listaTags.length>0 ? '':'assegna un\'etichetta'"
                                                        placeholder="assegna un'etichetta"
                                                        item-text="id"
                                                        item-value="id">
                                            <template v-slot:no-data>
                                                <div v-if="labelInput && checkTag()" class="ml-4">
                                                    premi invio per inserire <b>{{labelInput}}</b>
                                                </div>
                                                <div v-if="labelInput && labelInput.length>2 && !checkTag()" class="ml-4">
                                                    etichetta non valida (non inserire spazi o il trattino)
                                                </div>
                                                <div v-if="!labelInput || labelInput.length<=2" class="ml-4">
                                                    digita un'etichetta
                                                </div>
                                            </template>
                                            <template v-slot:selection="data">
                                                <v-chip v-bind="data.attrs"
                                                        :input-value="data.selected"
                                                        close
                                                        @click="data.select"
                                                        @click:close="removeTag(data.item)">
                                                    {{ data.item.id }}
                                                </v-chip>
                                            </template>
                                            <template v-slot:item="data">
                                                <template>
                                                    <v-list-item-content>
                                                        <v-row>
                                                            <v-col cols="10">
                                                                <v-list-item-title>{{data.item.id}}</v-list-item-title>
                                                            </v-col>
                                                        </v-row>
                                                    </v-list-item-content>
                                                </template>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>


                                </v-row>
                                <v-row v-if="isPersona || isDitta" class="mt-n4">
                                    <v-col cols="3">
                                        <v-text-field v-model="cliente.codiceFiscaleLR"
                                                      label="Codice Fiscale"
                                                      outlined
                                                      dense
                                                      @keyup="uppercaseCFLR"
                                                      :rules="[v=> (v==null || v.length==0 || /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/.test(v)) || 'inserire 16 caratteri', checkCF || 'codice fiscale non valido']">
                                            <v-tooltip slot="append" bottom v-if="cliente.codiceFiscaleLR && cliente.codiceFiscaleLR.length==16">
                                                <template v-slot:activator="{ on }">
                                                    <v-icon v-on="on" color="primary" dark @click="valoridacf">
                                                        mdi-database-search
                                                    </v-icon>
                                                </template>
                                                <span>Estrai dati dal Codice Fiscale</span>
                                            </v-tooltip>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field v-model="cliente.cognome"
                                                      label="Cognome"
                                                      outlined
                                                      :rules="[v=> (!!v && v.length>0) || 'inserire il cognome']"
                                                      dense>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field v-model="cliente.nome"
                                                      label="Nome"
                                                      outlined
                                                      :rules="[v=> (!!v && v.length>0) || 'inserire il nome']"
                                                      dense>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="2" v-if="isDitta">
                                        <v-text-field v-model="cliente.partitaIVA"
                                                      label="Partita Iva"
                                                      outlined
                                                      dense
                                                      :rules="[v=> (v==null || v.length==0 || /^\d{11}$/.test(v)) || 'inserire 11 cifre', v=> checkDupPIVA(v) || 'partita iva già presente nel sistema']"
                                                      required></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row v-if="isPersona || isDitta" class="mt-n4">
                                    <v-col cols="2">
                                        <v-text-field v-model="cliente.dataNascita"
                                                      label="Data di nascita"
                                                      outlined
                                                      :rules="[v=> v==null || v.length==0 || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                      hint="gg/mm/aaaa"
                                                      persistent-hint
                                                      clearable
                                                      dense></v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-combobox v-model="cliente.luogoNascita"
                                                    :items="listaComuni"
                                                    item-value="comune"
                                                    item-text="comune"
                                                    label="Luogo di nascita"
                                                    outlined
                                                    clearable
                                                    v-on:change="setComune"
                                                    ref="v-combobox-comuni"
                                                    :return-object="false"
                                                    dense></v-combobox>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="cliente.provinciaNascita"
                                                      label="Prv.Nascita"
                                                      outlined
                                                      dense
                                                      @keyup="uppercasePRV"
                                                      :rules="[v=> (v==null || v.length==0 || /^[A-Z]{2}$/.test(v)) || 'inserire 2 caratteri']">
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="2" v-if="isDitta">
                                        <v-select v-model="cliente.tipoRegimeIVA.id"
                                                  :items="listaTipoRegimeIVA"
                                                  item-value="id"
                                                  item-text="descrizione"
                                                  label="Regime IVA"
                                                  :rules="[v=> !!v || 'indicare il regime IVA']"
                                                  outlined
                                                  dense></v-select>
                                    </v-col>
                                    <v-col cols="3" v-if="isDitta">
                                        <v-select v-model="cliente.tipoRegimeContabile.id"
                                                  :items="listaTipoRegimeContabile"
                                                  item-value="id"
                                                  item-text="descrizione"
                                                  label="Regime Contabile"
                                                  :rules="[v=> !!v || 'indicare il regime contabile']"
                                                  outlined
                                                  dense></v-select>
                                    </v-col>

                                </v-row>
                                <v-row v-if="isPersona || isDitta" class="mt-n4">
                                    <v-col cols="3">
                                        <v-select v-model="cliente.tipoDocumentoIdentificativoCliente"
                                                  :items="listaTipiDocumentoIndentificativo"
                                                  item-value="id"
                                                  item-text="descrizione"
                                                  label="Tipo documento identificativo"
                                                  outlined
                                                  dense></v-select>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="cliente.numeroDocumentoIdentificativo"
                                                      label="Numero documento"
                                                      outlined
                                                      dense></v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field v-model="cliente.emittenteDocumentoIdentificativo"
                                                      label="Rilasciato da"
                                                      outlined
                                                      dense></v-text-field>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="cliente.dataRilascioDocumentoIdentificativo"
                                                      label="Data rilascio documento"
                                                      outlined
                                                      :rules="[v=> v==null || v.length==0 || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                      hint="gg/mm/aaaa"
                                                      persistent-hint
                                                      clearable
                                                      dense></v-text-field>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="cliente.dataScadenzaDocumentoIdentificativo"
                                                      label="Data scadenza documento"
                                                      outlined
                                                      :rules="[v=> v==null || v.length==0 || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                      hint="gg/mm/aaaa"
                                                      persistent-hint
                                                      clearable
                                                      dense></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row v-if="(isPersona || isDitta) && !isStudioProLight" class="mt-n4">
                                    <v-col cols="3">
                                        <v-text-field v-model="cliente.pec"
                                                      label="PEC"
                                                      outlined
                                                      dense
                                                      :rules="[v=>(v==null || v.length==0 ||  /.+@.+\..+/.test(v) ) || 'inserire una mail valida']"></v-text-field>

                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="cliente.scadenzaPEC"
                                                      label="Scadenza PEC"
                                                      outlined
                                                      :rules="[v=> v==null || v.length==0 || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                      hint="gg/mm/aaaa"
                                                      persistent-hint
                                                      clearable
                                                      dense></v-text-field>
                                    </v-col>
                                    <v-col cols="1">
                                        <v-checkbox class="mt-0"
                                                    v-model="cliente.isFirmaDigitale"
                                                    label="Firma digitale">
                                        </v-checkbox>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="cliente.scadenzaFirmaDigitale"
                                                      label="Scadenza firma digitale"
                                                      outlined
                                                      :rules="[v=> v==null || v.length==0 || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                      hint="gg/mm/aaaa"
                                                      persistent-hint
                                                      clearable
                                                      :disabled="!cliente.isFirmaDigitale"
                                                      dense></v-text-field>
                                    </v-col>
                                    <v-col cols="2" v-if="isDitta">
                                        <v-text-field v-model="cliente.sdi"
                                                      label="SDI"
                                                      outlined
                                                      @keyup="uppercaseSDI"
                                                      :rules="[v=>(v==null || v.length==0 ||  /^[A-Z0-9]{7}$/.test(v) ) || 'inserire 7 caratteri']"
                                                      dense></v-text-field>

                                    </v-col>
                                </v-row>

                                <v-row v-if="(isPersona || isDitta) && isStudioProLight" class="mt-n4">
                                    <v-col cols="3">
                                        <v-text-field v-model="cliente.pec"
                                                      label="PEC"
                                                      outlined
                                                      dense
                                                      :rules="[v=>(v==null || v.length==0 ||  /.+@.+\..+/.test(v) ) || 'inserire una mail valida']"></v-text-field>

                                    </v-col>
                                    <v-col cols="2" v-if="isDitta">
                                        <v-text-field v-model="cliente.sdi"
                                                      label="SDI"
                                                      outlined
                                                      @keyup="uppercaseSDI"
                                                      :rules="[v=>(v==null || v.length==0 ||  /^[A-Z0-9]{7}$/.test(v) ) || 'inserire 7 caratteri']"
                                                      dense></v-text-field>

                                    </v-col>
                                </v-row>

                                <v-row v-if="isPersona || isDitta" class="mt-n4">
                                    <v-col cols="5">
                                        <v-radio-group class="mt-0"
                                                       v-model="rstatus" row>
                                            <v-radio value="0"
                                                     color="green"
                                                     label="Standard">
                                            </v-radio>
                                            <v-radio value="1"
                                                     color="cyan"
                                                     label="Addebiti sospesi">
                                            </v-radio>
                                            <v-radio value="2"
                                                     color="orange"
                                                     label="Bloccato">
                                            </v-radio>
                                            <v-radio value="3"
                                                     color="red"
                                                     label="Eliminato">
                                            </v-radio>
                                        </v-radio-group>
                                    </v-col>

                                    <v-col cols="2" class="mx-n16" v-if="rstatus != '0'">
                                        <v-text-field v-model="cliente.dataStatus"
                                                      label="Data dello status"
                                                      outlined
                                                      :rules="[v=> v==null || v.length==0 || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                      hint="gg/mm/aaaa"
                                                      persistent-hint
                                                      clearable
                                                      dense></v-text-field>
                                    </v-col>

                                </v-row>

                                <v-row v-if="isAzienda" class="mt-n6">
                                    <v-col cols="4">
                                        <v-text-field v-model="cliente.denominazione"
                                                      label="Denominazione"
                                                      outlined
                                                      dense
                                                      required
                                                      :rules="[v=> (!!v && v.length>0) || 'inserire la denominazione']"></v-text-field>

                                    </v-col>
                                    <v-col cols="2" v-if="!isStudioProLight">
                                        <v-text-field v-model="cliente.dataAttoCostitutivo"
                                                      label="Data Atto Costitutivo"
                                                      outlined
                                                      :rules="[v=> v==null || v.length==0 || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                      hint="gg/mm/aaaa"
                                                      persistent-hint
                                                      clearable
                                                      dense></v-text-field>
                                    </v-col>
                                    <v-col cols="3" v-if="!isStudioProLight">
                                        <v-select v-model="cliente.tipoRegimeIVA.id"
                                                  :items="listaTipoRegimeIVA"
                                                  item-value="id"
                                                  item-text="descrizione"
                                                  label="Regime IVA"
                                                  :rules="[v=> !!v || 'indicare il regime IVA']"
                                                  outlined
                                                  dense></v-select>
                                    </v-col>
                                    <v-col cols="3" v-if="!isStudioProLight">
                                        <v-select v-model="cliente.tipoRegimeContabile.id"
                                                  :items="listaTipoRegimeContabile"
                                                  item-value="id"
                                                  item-text="descrizione"
                                                  label="Regime Contabile"
                                                  :rules="[v=> !!v || 'indicare il regime contabile']"
                                                  outlined
                                                  dense></v-select>
                                    </v-col>
                                </v-row>
                                <v-row v-if="isAzienda" class="mt-n6">
                                    <v-col cols="3">
                                        <v-text-field v-model="cliente.codiceFiscale"
                                                      label="Codice Fiscale"
                                                      outlined
                                                      dense
                                                      @keyup="uppercaseCF"
                                                      :rules="[v=> (v==null || v.length==0 || /^\d{11}$/.test(v)) || /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/.test(v) || 'inserire 11 cifre o 16 caratteri', v=> checkDupCF(v) || 'codice fiscale già presente nel sistema']"
                                                      required></v-text-field>

                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="cliente.partitaIVA"
                                                      label="Partita Iva"
                                                      outlined
                                                      dense
                                                      :rules="[v=> (v==null || v.length==0 || /^\d{11}$/.test(v)) || 'inserire 11 cifre', v=> checkDupPIVA(v) || 'partita iva già presente nel sistema']"
                                                      required></v-text-field>

                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field v-model="cliente.pec"
                                                      label="PEC"
                                                      outlined
                                                      dense
                                                      :rules="[v=>(v==null || v.length==0 ||  /.+@.+\..+/.test(v) ) || 'inserire una mail valida']"></v-text-field>

                                    </v-col>
                                    <v-col cols="2" v-if=!isStudioProLight>
                                        <v-text-field v-model="cliente.scadenzaPEC"
                                                      label="Scadenza PEC"
                                                      outlined
                                                      :rules="[v=> v==null || v.length==0 || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                      hint="gg/mm/aaaa"
                                                      persistent-hint
                                                      clearable
                                                      dense></v-text-field>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="cliente.sdi"
                                                      label="SDI"
                                                      outlined
                                                      @keyup="uppercaseSDI"
                                                      :rules="[v=>(v==null || v.length==0 ||  /^[A-Z0-9]{7}$/.test(v) ) || 'inserire 7 caratteri']"
                                                      dense></v-text-field>

                                    </v-col>
                                </v-row>
                                <v-row v-if="isAzienda && !isStudioProLight" class="mt-n6">
                                    <v-col cols="1">
                                        <v-checkbox class="mt-0"
                                                    v-model="cliente.isFirmaDigitale"
                                                    label="Firma digitale">
                                        </v-checkbox>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="cliente.scadenzaFirmaDigitale"
                                                      label="Scadenza firma digitale"
                                                      outlined
                                                      :rules="[v=> v==null || v.length==0 || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                      hint="gg/mm/aaaa"
                                                      persistent-hint
                                                      clearable
                                                      :disabled="!cliente.isFirmaDigitale"
                                                      dense></v-text-field>

                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="cliente.inizioAttivita"
                                                      label="Inizio Attività"
                                                      outlined
                                                      :rules="[v=> v==null || v.length==0 || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                      hint="gg/mm/aaaa"
                                                      persistent-hint
                                                      clearable
                                                      dense></v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field v-model="cliente.annoImposta"
                                                      label="Anno imposta"
                                                      outlined
                                                      :rules="[v=> v==null || v.length==0 || /^[0-9]{2}\/[0-9]{2}-[0-9]{2}\/[0-9]{2}$/.test(v) || 'gg/mm-gg/mm']"
                                                      hint="gg/mm-gg/mm"
                                                      persistent-hint
                                                      clearable
                                                      dense></v-text-field>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="cliente.termineApprovazioneBilancio"
                                                      label="Termine Appr.Bilancio"
                                                      outlined
                                                      :rules="[v=> v==null || v.length==0 || /^[0-9]{2}\/[0-9]{2}$/.test(v) || 'gg/mm']"
                                                      hint="gg/mm"
                                                      persistent-hint
                                                      clearable
                                                      dense></v-text-field>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="cliente.scadenzaCaricheOrganoAmministrativo"
                                                      label="Scadenza Board"
                                                      outlined
                                                      :rules="[v=> v==null || v.length==0 || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                      hint="gg/mm/aaaa"
                                                      persistent-hint
                                                      clearable
                                                      dense></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row v-if="isAzienda && !isStudioProLight" class="mt-n6">
                                    <v-col cols="2">
                                        <v-text-field v-model="cliente.annoPrimaEleborazione"
                                                      label="Anno Prima Elab."
                                                      outlined
                                                      :rules="[v=> v==null || v.length==0 || /^[0-9]{4}$/.test(v) || 'aaaa']"
                                                      hint="aaaa"
                                                      persistent-hint
                                                      dense></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field v-model="cliente.iban"
                                                      label="IBAN"
                                                      outlined
                                                      :rules="[v=> v==null || v.length==0 || v.length<=32 || 'massimo 32 caratteri']"
                                                      @keyup="uppercaseIBAN"
                                                      persistent-hint
                                                      dense></v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-checkbox class="mt-0"
                                                    v-model="cliente.isINPSArtComm"
                                                    label="INPS Artigiani Commercianti">
                                        </v-checkbox>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-checkbox class="mt-0"
                                                    v-model="cliente.isINPSGestSep"
                                                    label="INPS Gestione Separata">
                                        </v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row v-if="isAzienda" class="mt-n6">
                                    <v-col cols="3">
                                        <v-text-field v-model="cliente.codiceFiscaleLR"
                                                      label="Codice Fiscale Rapp.Legale"
                                                      outlined
                                                      dense
                                                      @keyup="uppercaseCFLR"
                                                      :rules="[v=> (v==null || v.length==0 || /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/.test(v)) || 'inserire 16 caratteri', checkCF || 'codice fiscale non valido']">
                                            <v-tooltip slot="append" bottom v-if="cliente.codiceFiscaleLR && cliente.codiceFiscaleLR.length==16">
                                                <template v-slot:activator="{ on }">
                                                    <v-icon v-on="on" color="primary" dark @click="valoridacf">
                                                        mdi-database-search
                                                    </v-icon>
                                                </template>
                                                <span>Estrai dati dal Codice Fiscale</span>
                                            </v-tooltip>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field v-model="cliente.cognome"
                                                      label="Cognome Rapp.Legale"
                                                      outlined
                                                      dense>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field v-model="cliente.nome"
                                                      label="Nome Rapp.Legale"
                                                      outlined
                                                      dense>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field v-model="cliente.dataNascita"
                                                      label="Data di nascita Rapp.Legale"
                                                      outlined
                                                      :rules="[v=> v==null || v.length==0 || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                      hint="gg/mm/aaaa"
                                                      persistent-hint
                                                      clearable
                                                      dense></v-text-field>
                                    </v-col>

                                </v-row>
                                <v-row v-if="isAzienda" class="mt-n6">
                                    <v-col cols="3">
                                        <v-combobox v-model="cliente.luogoNascita"
                                                    :items="listaComuni"
                                                    item-value="comune"
                                                    item-text="comune"
                                                    label="Luogo di nascita Rapp.Legale"
                                                    outlined
                                                    v-on:change="setComune"
                                                    clearable
                                                    ref="v-combobox-comuni-rl"
                                                    :return-object="false"
                                                    dense></v-combobox>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="cliente.provinciaNascita"
                                                      label="Prv.Nascita Rapp.Legale"
                                                      outlined
                                                      dense
                                                      @keyup="uppercasePRV"
                                                      :rules="[v=> (v==null || v.length==0 || /^[A-Z]{2}$/.test(v)) || 'inserire 2 caratteri']">
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="5">
                                        <v-radio-group class="mt-0"
                                                       v-model="rstatus" row>
                                            <v-radio value="0"
                                                     color="green"
                                                     label="Standard">
                                            </v-radio>
                                            <v-radio value="1"
                                                     color="cyan"
                                                     label="Addebiti sospesi">
                                            </v-radio>
                                            <v-radio value="2"
                                                     color="orange"
                                                     label="Bloccato">
                                            </v-radio>
                                            <v-radio value="3"
                                                     color="red"
                                                     label="Eliminato">
                                            </v-radio>
                                        </v-radio-group>
                                    </v-col>

                                    <v-col cols="2" class="mx-n16" v-if="rstatus != '0'">
                                        <v-text-field v-model="cliente.dataStatus"
                                                      label="Data dello status"
                                                      outlined
                                                      :rules="[v=> v==null || v.length==0 || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                      hint="gg/mm/aaaa"
                                                      persistent-hint
                                                      clearable
                                                      dense></v-text-field>
                                    </v-col>

                                </v-row>
                                <v-row v-if="isAzienda" class="mt-n6">
                                    <v-col cols="3">
                                        <v-select v-model="cliente.tipoDocumentoIdentificativoCliente"
                                                  :items="listaTipiDocumentoIndentificativo"
                                                  item-value="id"
                                                  item-text="descrizione"
                                                  label="Tipo documento identificativo Rapp.Legale"
                                                  outlined
                                                  dense></v-select>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="cliente.numeroDocumentoIdentificativo"
                                                      label="N.ro documento Rapp.Legale"
                                                      outlined
                                                      dense></v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field v-model="cliente.emittenteDocumentoIdentificativo"
                                                      label="Rilasciato da"
                                                      outlined
                                                      dense></v-text-field>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="cliente.dataRilascioDocumentoIdentificativo"
                                                      label="Data rilascio documento"
                                                      outlined
                                                      :rules="[v=> v==null || v.length==0 || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                      hint="gg/mm/aaaa"
                                                      persistent-hint
                                                      clearable
                                                      dense></v-text-field>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="cliente.dataScadenzaDocumentoIdentificativo"
                                                      label="Data scadenza documento"
                                                      outlined
                                                      :rules="[v=> v==null || v.length==0 || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                      hint="gg/mm/aaaa"
                                                      persistent-hint
                                                      clearable
                                                      dense></v-text-field>
                                    </v-col>
                                </v-row>

                            </div>
                            <div v-else>
                                <v-row class="mt-4">
                                    <v-col cols="3">
                                        <v-select v-model="cliente.tipoCliente.id"
                                                  :items="listaTipoCliente"
                                                  item-value="id"
                                                  item-text="descrizione"
                                                  label="Tipologia Cliente"
                                                  :rules="[v=> !!v || 'indicare il tipo di cliente']"
                                                  outlined
                                                  v-on:change="setTipoClienteServicePro(cliente.tipoCliente.id)"
                                                  dense></v-select>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="cliente.codice"
                                                      label="Codice"
                                                      required
                                                      outlined
                                                      dense></v-text-field>
                                    </v-col>
                                    <v-col v-if="isStudioPro" cols="2">
                                        <v-select v-model="cliente.professionista.id"
                                                  :items="listaOperatoriProfessionisti"
                                                  item-value="professionista.id"
                                                  item-text="denominazioneNormalizzata"
                                                  label="Professionista di riferimento"
                                                  :rules="[v=> !!v || 'indicare il professionista']"
                                                  outlined
                                                  dense></v-select>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-autocomplete v-model="listaTags"
                                                        :items="listaTagsIni"
                                                        :search-input.sync="labelInput"
                                                        @keyup.enter="aggiungiTag"
                                                        chips
                                                        dense
                                                        outlined
                                                        multiple
                                                        :label="listaTags.length>0 ? '':'assegna un\'etichetta'"
                                                        placeholder="assegna un'etichetta"
                                                        item-text="id"
                                                        item-value="id">
                                            <template v-slot:no-data>
                                                <div v-if="labelInput && checkTag()" class="ml-4">
                                                    premi invio per inserire <b>{{labelInput}}</b>
                                                </div>
                                                <div v-if="labelInput && labelInput.length>2 && !checkTag()" class="ml-4">
                                                    etichetta non valida (non inserire spazi o il trattino)
                                                </div>
                                                <div v-if="!labelInput || labelInput.length<=2" class="ml-4">
                                                    digita un'etichetta
                                                </div>
                                            </template>
                                            <template v-slot:selection="data">
                                                <v-chip v-bind="data.attrs"
                                                        :input-value="data.selected"
                                                        close
                                                        @click="data.select"
                                                        @click:close="removeTag(data.item)">
                                                    {{ data.item.id }}
                                                </v-chip>
                                            </template>
                                            <template v-slot:item="data">
                                                <template>
                                                    <v-list-item-content>
                                                        <v-row>
                                                            <v-col cols="10">
                                                                <v-list-item-title>{{data.item.id}}</v-list-item-title>
                                                            </v-col>
                                                        </v-row>
                                                    </v-list-item-content>
                                                </template>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>

                                <v-row v-if="isDitta || isCondominio || isAmministrazione" class="mt-n4">
                                    <v-col cols="3">
                                        <v-text-field v-model="cliente.denominazione"
                                                      label="Denominazione"
                                                      outlined
                                                      dense
                                                      required
                                                      :rules="[v=> (!!v && v.length>0) || 'inserire la denominazione']"></v-text-field>

                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field v-model="cliente.codiceFiscale"
                                                      label="Codice Fiscale"
                                                      outlined
                                                      dense
                                                      @keyup="uppercaseCF"
                                                      :rules="[v=> (v==null || v.length==0 || /^\d{11}$/.test(v)) || /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/.test(v) || 'inserire 11 cifre o 16 caratteri', v=> checkDupCF(v) || 'codice fiscale già presente nel sistema']"
                                                      required></v-text-field>

                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="cliente.partitaIVA"
                                                      label="Partita Iva"
                                                      outlined
                                                      dense
                                                      :rules="[v=> (v==null || v.length==0 || /^\d{11}$/.test(v)) || 'inserire 11 cifre', v=> checkDupPIVA(v) || 'partita iva già presente nel sistema']"
                                                      required></v-text-field>

                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="cliente.pec"
                                                      label="PEC"
                                                      outlined
                                                      dense
                                                      :rules="[v=>(v==null || v.length==0 ||  /.+@.+\..+/.test(v) ) || 'inserire una mail valida']"></v-text-field>

                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-if="isAmministrazione" v-model="cliente.sdi"
                                                      label="Codice Univoco"
                                                      outlined
                                                      @keyup="uppercaseSDI"
                                                      :rules="[v=>(v==null || v.length==0 ||  /^[A-Z0-9]{6}$/.test(v) ) || 'inserire 6 caratteri']"
                                                      dense></v-text-field>
                                        <v-text-field v-else v-model="cliente.sdi"
                                                      label="SDI"
                                                      outlined
                                                      @keyup="uppercaseSDI"
                                                      :rules="[v=>(v==null || v.length==0 ||  /^[A-Z0-9]{7}$/.test(v) ) || 'inserire 7 caratteri']"
                                                      dense></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row v-if="isDitta || isCondominio || isAmministrazione" class="mt-n4">
                                    <v-col cols="3">
                                        <v-text-field v-model="cliente.codiceFiscaleLR"
                                                      label="Codice Fiscale Rapp.Legale"
                                                      outlined
                                                      dense
                                                      @keyup="uppercaseCFLR"
                                                      :rules="[v=> (v==null || v.length==0 || /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/.test(v)) || 'inserire 16 caratteri', checkCF || 'codice fiscale non valido']">
                                            <v-tooltip slot="append" bottom v-if="cliente.codiceFiscaleLR && cliente.codiceFiscaleLR.length==16">
                                                <template v-slot:activator="{ on }">
                                                    <v-icon v-on="on" color="primary" dark @click="valoridacf">
                                                        mdi-database-search
                                                    </v-icon>
                                                </template>
                                                <span>Estrai dati dal Codice Fiscale</span>
                                            </v-tooltip>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field v-model="cliente.cognome"
                                                      label="Cognome Rapp.Legale"
                                                      outlined
                                                      dense>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field v-model="cliente.nome"
                                                      label="Nome Rapp.Legale"
                                                      outlined
                                                      dense>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field v-model="cliente.dataNascita"
                                                      label="Data di nascita Rapp.Legale"
                                                      outlined
                                                      :rules="[v=> v==null || v.length==0 || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                      hint="gg/mm/aaaa"
                                                      persistent-hint
                                                      clearable
                                                      dense></v-text-field>
                                    </v-col>

                                </v-row>
                                <v-row v-if="isDitta || isCondominio || isAmministrazione" class="mt-n4">
                                    <v-col cols="3">
                                        <v-combobox v-model="cliente.luogoNascita"
                                                    :items="listaComuni"
                                                    item-value="comune"
                                                    item-text="comune"
                                                    label="Luogo di nascita Rapp.Legale"
                                                    outlined
                                                    v-on:change="setComune"
                                                    clearable
                                                    ref="v-combobox-comuni-rl"
                                                    :return-object="false"
                                                    dense></v-combobox>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="cliente.provinciaNascita"
                                                      label="Prv.Nascita Rapp.Legale"
                                                      outlined
                                                      dense
                                                      @keyup="uppercasePRV"
                                                      :rules="[v=> (v==null || v.length==0 || /^[A-Z]{2}$/.test(v)) || 'inserire 2 caratteri']">
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="5">
                                        <v-radio-group class="mt-0"
                                                       v-model="rstatus" row>
                                            <v-radio value="0"
                                                     color="green"
                                                     label="Standard">
                                            </v-radio>
                                            <v-radio value="1"
                                                     color="cyan"
                                                     label="Addebiti sospesi">
                                            </v-radio>
                                            <v-radio value="2"
                                                     color="orange"
                                                     label="Bloccato">
                                            </v-radio>
                                            <v-radio value="3"
                                                     color="red"
                                                     label="Eliminato">
                                            </v-radio>
                                        </v-radio-group>
                                    </v-col>

                                    <v-col cols="2" class="mx-n16" v-if="rstatus != '0'">
                                        <v-text-field v-model="cliente.dataStatus"
                                                      label="Data dello status"
                                                      outlined
                                                      :rules="[v=> v==null || v.length==0 || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                      hint="gg/mm/aaaa"
                                                      persistent-hint
                                                      clearable
                                                      dense></v-text-field>
                                    </v-col>

                                </v-row>

                                <v-row v-if="isPersona" class="mt-n4">
                                    <v-col cols="3">
                                        <v-text-field v-model="cliente.codiceFiscaleLR"
                                                      label="Codice Fiscale"
                                                      outlined
                                                      dense
                                                      @keyup="uppercaseCFLR"
                                                      :rules="[v=> (v==null || v.length==0 || /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/.test(v)) || 'inserire 16 caratteri', checkCF || 'codice fiscale non valido']">
                                            <v-tooltip slot="append" bottom v-if="cliente.codiceFiscaleLR && cliente.codiceFiscaleLR.length==16">
                                                <template v-slot:activator="{ on }">
                                                    <v-icon v-on="on" color="primary" dark @click="valoridacf">
                                                        mdi-database-search
                                                    </v-icon>
                                                </template>
                                                <span>Estrai dati dal Codice Fiscale</span>
                                            </v-tooltip>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field v-model="cliente.cognome"
                                                      label="Cognome"
                                                      outlined
                                                      :rules="[v=> (!!v && v.length>0) || 'inserire il cognome']"
                                                      dense>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field v-model="cliente.nome"
                                                      label="Nome"
                                                      outlined
                                                      :rules="[v=> (!!v && v.length>0) || 'inserire il nome']"
                                                      dense>
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="2">
                                        <v-text-field v-model="cliente.pec"
                                                      label="PEC"
                                                      outlined
                                                      dense
                                                      :rules="[v=>(v==null || v.length==0 ||  /.+@.+\..+/.test(v) ) || 'inserire una mail valida']"></v-text-field>

                                    </v-col>
                                </v-row>

                                <v-row v-if="isPersona" class="mt-n4">
                                    <v-col cols="2">
                                        <v-text-field v-model="cliente.dataNascita"
                                                      label="Data di nascita"
                                                      outlined
                                                      :rules="[v=> v==null || v.length==0 || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                      hint="gg/mm/aaaa"
                                                      persistent-hint
                                                      clearable
                                                      dense></v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-combobox v-model="cliente.luogoNascita"
                                                    :items="listaComuni"
                                                    item-value="comune"
                                                    item-text="comune"
                                                    label="Luogo di nascita"
                                                    outlined
                                                    clearable
                                                    v-on:change="setComune"
                                                    ref="v-combobox-comuni"
                                                    :return-object="false"
                                                    dense></v-combobox>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-text-field v-model="cliente.provinciaNascita"
                                                      label="Prv.Nascita"
                                                      outlined
                                                      dense
                                                      @keyup="uppercasePRV"
                                                      :rules="[v=> (v==null || v.length==0 || /^[A-Z]{2}$/.test(v)) || 'inserire 2 caratteri']">
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="2">
                                        <v-text-field v-model="cliente.sdi"
                                                      label="SDI"
                                                      outlined
                                                      @keyup="uppercaseSDI"
                                                      :rules="[v=>(v==null || v.length==0 ||  /^[A-Z0-9]{7}$/.test(v) ) || 'inserire 7 caratteri']"
                                                      dense></v-text-field>

                                    </v-col>

                                </v-row>

                                <v-row v-if="isPersona" class="mt-n4">
                                    <v-col cols="5">
                                        <v-radio-group class="mt-0"
                                                       v-model="rstatus" row>
                                            <v-radio value="0"
                                                     color="green"
                                                     label="Standard">
                                            </v-radio>
                                            <v-radio value="1"
                                                     color="cyan"
                                                     label="Addebiti sospesi">
                                            </v-radio>
                                            <v-radio value="2"
                                                     color="orange"
                                                     label="Bloccato">
                                            </v-radio>
                                            <v-radio value="3"
                                                     color="red"
                                                     label="Eliminato">
                                            </v-radio>
                                        </v-radio-group>
                                    </v-col>

                                    <v-col cols="2" class="mx-n16" v-if="rstatus != '0'">
                                        <v-text-field v-model="cliente.dataStatus"
                                                      label="Data dello status"
                                                      outlined
                                                      :rules="[v=> v==null || v.length==0 || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                      hint="gg/mm/aaaa"
                                                      persistent-hint
                                                      clearable
                                                      dense></v-text-field>
                                    </v-col>

                                </v-row>

                            </div>

                        </v-tab-item>
                    </v-form>
                    <v-form ref="form2"
                            v-model="valid2"
                            lazy-validation>
                        <v-tab-item v-if="viewTab && (!nuovo || rubrica)">
                            <v-row v-if="rubrica && !cliente.isContatto" class="mt-2">
                                <v-col cols="3">
                                    <v-text-field v-model="cliente.cognome"
                                                  label="Cognome"
                                                  outlined
                                                  disabled
                                                  dense>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field v-model="cliente.nome"
                                                  label="Nome"
                                                  outlined
                                                  disabled
                                                  dense>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field v-model="cliente.denominazione"
                                                  label="Denominazione"
                                                  outlined
                                                  disabled
                                                  dense>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-autocomplete v-model="listaTags"
                                                    :items="listaTagsIni"
                                                    :search-input.sync="labelInput"
                                                    chips
                                                    dense
                                                    :label="listaTags.length>0 ? '':'etichetta'"
                                                    outlined
                                                    multiple
                                                    disabled
                                                    item-text="id"
                                                    item-value="id">
                                        <template v-slot:no-data>
                                            <div v-if="labelInput && checkTag()" class="ml-4">
                                                premi invio per inserire <b>{{labelInput}}</b>
                                            </div>
                                            <div v-if="labelInput && labelInput.length>2 && !checkTag()" class="ml-4">
                                                etichetta non valida (non inserire spazi o il trattino)
                                            </div>
                                            <div v-if="!labelInput || labelInput.length<=2" class="ml-4">
                                                digita un'etichetta
                                            </div>
                                        </template>
                                        <template v-slot:selection="data">
                                            <v-chip v-bind="data.attrs"
                                                    :input-value="data.selected"
                                                    close
                                                    @click="data.select"
                                                    @click:close="removeTag(data.item)">
                                                {{ data.item.id }}
                                            </v-chip>
                                        </template>
                                        <template v-slot:item="data">
                                            <template>
                                                <v-list-item-content>
                                                    <v-row>
                                                        <v-col cols="10">
                                                            <v-list-item-title>{{data.item.id}}</v-list-item-title>
                                                        </v-col>
                                                    </v-row>
                                                </v-list-item-content>
                                            </template>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row v-if="rubrica && cliente.isContatto" class="mt-2">
                                <v-col cols="3">
                                    <v-text-field v-model="cliente.cognome"
                                                  label="Cognome"
                                                  outlined
                                                  required
                                                  :rules="[v=> (!!v && v.length>0) || 'inserire il cognome']"
                                                  dense>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field v-model="cliente.nome"
                                                  label="Nome"
                                                  outlined
                                                  required
                                                  :rules="[v=> (!!v && v.length>0) || 'inserire il nome']"
                                                  dense>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field v-model="cliente.denominazione"
                                                  label="Denominazione"
                                                  outlined
                                                  dense>
                                    </v-text-field>

                                </v-col>
                                <v-col cols="3">
                                    <v-autocomplete v-model="listaTags"
                                                    :items="listaTagsIni"
                                                    :search-input.sync="labelInput"
                                                    @keyup.enter="aggiungiTag"
                                                    chips
                                                    dense
                                                    outlined
                                                    multiple
                                                    :label="listaTags.length>0 ? '':'assegna un\'etichetta'"
                                                    placeholder="assegna un'etichetta"
                                                    item-text="id"
                                                    item-value="id">
                                        <template v-slot:no-data>
                                            <div v-if="labelInput && checkTag()" class="ml-4">
                                                premi invio per inserire <b>{{labelInput}}</b>
                                            </div>
                                            <div v-if="labelInput && labelInput.length>2 && !checkTag()" class="ml-4">
                                                etichetta non valida (non inserire spazi o il trattino)
                                            </div>
                                            <div v-if="!labelInput || labelInput.length<=2" class="ml-4">
                                                digita un'etichetta
                                            </div>
                                        </template>
                                        <template v-slot:selection="data">
                                            <v-chip v-bind="data.attrs"
                                                    :input-value="data.selected"
                                                    close
                                                    @click="data.select"
                                                    @click:close="removeTag(data.item)">
                                                {{ data.item.id }}
                                            </v-chip>
                                        </template>
                                        <template v-slot:item="data">
                                            <template>
                                                <v-list-item-content>
                                                    <v-row>
                                                        <v-col cols="10">
                                                            <v-list-item-title>{{data.item.id}}</v-list-item-title>
                                                        </v-col>
                                                    </v-row>
                                                </v-list-item-content>
                                            </template>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                            <div v-if="!rubrica">
                                <br />
                            </div>
                            <v-row v-if="!nuovo" class="mt-n8">
                                <v-col cols="8">
                                    <template>
                                        <v-data-table :headers="headersRecapitiCliente"
                                                      :items="recapitiCliente"
                                                      item-key="id"
                                                      hide-default-footer
                                                      no-data-text="Non sono presenti recapiti"
                                                      class="elevation-1 ml-2 mb-2">
                                            <template slot="body.append">
                                                <tr>
                                                    <th class="title"></th>
                                                    <th class="title"></th>
                                                    <th class="title"></th>
                                                    <th class="title">
                                                        <v-icon small
                                                                class="mr-2"
                                                                @click="newRecapitoCliente">
                                                            mdi-plus
                                                        </v-icon>
                                                    </th>
                                                </tr>
                                            </template>
                                            <template v-slot:item.azioni="{ item }">
                                                <v-icon small
                                                        class="mr-2"
                                                        @click="editRecapitoCliente(item)">
                                                    mdi-pencil
                                                </v-icon>
                                                <v-icon small
                                                        @click="deleteRecapitoCliente(item)">
                                                    mdi-delete
                                                </v-icon>
                                            </template>
                                        </v-data-table>
                                    </template>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                    </v-form>
                    <v-form ref="form3"
                            v-model="valid3"
                            lazy-validation>
                        <v-tab-item v-if="viewTab && (!nuovo && !rubrica)">
                            <br />
                            <v-col cols="10">
                                <template>
                                    <v-data-table :headers="headersIndirizzarioCliente"
                                                  :items="indirizzarioCliente"
                                                  item-key="id"
                                                  hide-default-footer
                                                  no-data-text="Non sono presenti indirizzi"
                                                  class="elevation-1">
                                        <template slot="body.append">
                                            <tr>
                                                <th class="title"></th>
                                                <th class="title"></th>
                                                <th class="title"></th>
                                                <th class="title"></th>
                                                <th class="title"></th>
                                                <th class="title"></th>
                                                <th class="title">
                                                    <v-icon small
                                                            class="mr-2"
                                                            @click="newIndirizzarioCliente">
                                                        mdi-plus
                                                    </v-icon>
                                                </th>
                                            </tr>
                                        </template>
                                        <template v-slot:item.azioni="{ item }">
                                            <v-icon small
                                                    class="mr-2"
                                                    @click="editIndirizzarioCliente(item)">
                                                mdi-pencil
                                            </v-icon>
                                            <v-icon small
                                                    @click="deleteIndirizzarioCliente(item)">
                                                mdi-delete
                                            </v-icon>
                                        </template>
                                        <template v-slot:item.indirizzario.coordinate="{ item }">
                                            <a v-if="item.indirizzario.coordinate" target="_blank"
                                               style="text-decoration: none;"
                                               :href="'https://maps.google.it/?q='+item.indirizzario.coordinate">
                                                <v-icon color="green">mdi-map-marker</v-icon>
                                            </a>
                                        </template>
                                    </v-data-table>
                                </template>
                            </v-col>
                        </v-tab-item>
                    </v-form>
                    <v-form ref="form4"
                            v-model="valid4"
                            lazy-validation>
                        <v-tab-item v-if="isStudioPro && !isStudioProLight && viewTab && (!nuovo && !rubrica && (($store.state.utente && $store.state.utente.isGestoreStudio) || ($store.state.utente && $store.state.utente.operatore.professionista && $store.state.utente.operatore.professionista.id==cliente.professionista.id)))">
                            <br />

                            <v-row>

                                <v-col v-if="isContabilita" cols="4" class="ma-4">
                                    <v-row>
                                        <v-col cols="4">
                                            <v-switch v-model="isConsulenzaContabile"
                                                      label="Consulenza contabile"
                                                      @change="changeContabilita">
                                            </v-switch>
                                        </v-col>
                                        <v-col>
                                            <v-select v-model="idPeriodicitaContabile"
                                                      :items="listaPeriodicitaContabile"
                                                      item-value="ordinamento"
                                                      item-text="descrizione"
                                                      label="Periodicità Cons. Contabile"
                                                      style="margin-top:20px"
                                                      outlined
                                                      required
                                                      @change="changePerdiodicitaContabile"
                                                      :disabled="!isConsulenzaContabile"
                                                      dense></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <template>
                                                <v-data-table :headers="headersTariffeContabili"
                                                              :items="tariffeContabilitaCliente"
                                                              item-key="id"
                                                              hide-default-footer
                                                              :item-class="coloraRiga"
                                                              no-data-text="Tariffe non impostate"
                                                              class="elevation-1">
                                                    <template v-slot:item.abilitato="{ item }">
                                                        <v-simple-checkbox v-model="item.abilitato" />
                                                    </template>
                                                    <template v-slot:item.importo="{item}">
                                                        <div v-if="!item.abilitato">
                                                            <span>-</span>
                                                        </div>
                                                        <v-edit-dialog v-if="item.abilitato" persistent
                                                                       @save="saveNumero(item)"
                                                                       @open="importoEdit = isNaN(item.importo)||item.importo==0 ? '': item.importo ">
                                                            <span v-if="item.importo>0" v-text="importoEuro(item.importo)" />
                                                            <span v-else>-</span>
                                                            <template v-slot:input>
                                                                <vuetify-money class="mt-2 mb-0" v-model="importoEdit"
                                                                               label="Importo"
                                                                               outlined
                                                                               :background-color="theme ? 'dark-grey':'white'"
                                                                               dense
                                                                               :rules="[v=> !!v || 'inserire importo']"
                                                                               :options="moneyOptions">
                                                                </vuetify-money>
                                                            </template>
                                                        </v-edit-dialog>
                                                    </template>
                                                </v-data-table>
                                            </template>
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-col v-if="isLavoro" cols="4" class="ma-4">

                                    <v-row>
                                        <v-col cols="4">
                                            <v-switch v-model="isConsulenzaLavoro"
                                                      label="Consulenza lavoro"
                                                      @change="changeLavoro">
                                            </v-switch>
                                        </v-col>
                                        <v-col>
                                            <v-select v-model="idPeriodicitaLavoro"
                                                      :items="listaPeriodicitaLavoro"
                                                      item-value="ordinamento"
                                                      item-text="descrizione"
                                                      label="Periodicità Cons. Lavoro"
                                                      style="margin-top:20px"
                                                      outlined
                                                      required
                                                      @change="changePerdiodicitaLavoro"
                                                      :disabled="!isConsulenzaLavoro"
                                                      dense></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <template>
                                                <v-data-table :headers="headersTariffeLavoro"
                                                              :items="tariffeLavoroCliente"
                                                              item-key="id"
                                                              :item-class="coloraRiga"
                                                              hide-default-footer
                                                              no-data-text="Tariffe non impostate"
                                                              class="elevation-1 mb-2">
                                                    <template v-slot:item.abilitato="{ item }">
                                                        <v-simple-checkbox v-model="item.abilitato" />
                                                    </template>
                                                    <template v-slot:item.importo="{item}">
                                                        <div v-if="!item.abilitato">
                                                            <span>-</span>
                                                        </div>
                                                        <v-edit-dialog v-if="item.abilitato" persistent
                                                                       @save="saveNumero(item)"
                                                                       @open="importoEdit = isNaN(item.importo)||item.importo==0 ? '': item.importo ">
                                                            <span v-if="item.importo>0" v-text="importoEuro(item.importo)" />
                                                            <span v-else>-</span>
                                                            <template v-slot:input>
                                                                <vuetify-money class="mt-2 mb-0" v-model="importoEdit"
                                                                               label="Importo"
                                                                               outlined
                                                                               :background-color="theme ? 'dark-grey':'white'"
                                                                               dense
                                                                               :rules="[v=> !!v || 'inserire importo']"
                                                                               :options="moneyOptions">
                                                                </vuetify-money>
                                                            </template>
                                                        </v-edit-dialog>
                                                    </template>
                                                </v-data-table>
                                            </template>
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-col class="ma-4">
                                    <v-card>
                                        <v-card-text>
                                            <v-row class="ma-1">
                                                <span><strong>Copia le tariffe da un altro cliente</strong></span>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{on, attrs}">
                                                        <v-btn class="mt-n1"
                                                               icon
                                                               @click="copiaTariffe"
                                                               v-bind="attrs"
                                                               :disabled="!idClienteCopia"
                                                               v-on="on"
                                                               color="green">
                                                            <v-icon dark>
                                                                mdi-cash-sync
                                                            </v-icon>
                                                        </v-btn>
                                                        <v-btn icon>
                                                            <v-icon></v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Copia le tariffe da un altro cliente</span>
                                                </v-tooltip>
                                                <v-row class="mt-2">
                                                    <v-autocomplete v-model="idClienteCopia"
                                                                    :items="listaCliente"
                                                                    item-value="cliente.id"
                                                                    item-text="cliente.denominazioneNormalizzata"
                                                                    label="Cliente seme"
                                                                    outlined
                                                                    clearable
                                                                    dense></v-autocomplete>
                                                </v-row>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>


                                    <v-card class="mt-1">
                                        <v-card-text>
                                            <v-row class="ma-1">
                                                <span><strong>Impostazione diretta del fido</strong></span>
                                            </v-row>

                                            <v-row class="mt-n3">
                                                <v-col cols="8">
                                                    <vuetify-money v-model="importoFido"
                                                                   label="Importo fido"
                                                                   outlined
                                                                   :background-color="theme ? 'dark-grey':'white'"
                                                                   dense
                                                                   :options="moneyOptions">
                                                    </vuetify-money>
                                                </v-col>
                                                <v-col>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{on, attrs}">
                                                            <v-btn class="mx-2"
                                                                   icon
                                                                   @click="setFido(1)"
                                                                   v-bind="attrs"
                                                                   v-on="on"
                                                                   color="deep-orange darken-4">
                                                                <v-icon dark>
                                                                    mdi-credit-card-edit-outline
                                                                </v-icon>
                                                            </v-btn>
                                                            <v-btn icon>
                                                                <v-icon></v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>imposta il fido come cifra assoluta</span>
                                                    </v-tooltip>
                                                </v-col>
                                            </v-row>

                                            <v-row class="ma-1">
                                                <span><strong>Impostazione del fido come multiplo di un canone</strong></span>
                                            </v-row>

                                            <v-row class="mt-n6">
                                                <v-col>
                                                    <v-select v-model="cliente.tariffaMensilitaFido.id"
                                                              :items="listaCanoni"
                                                              item-value="id"
                                                              item-text="tipoServizio.descrizione"
                                                              label="Canoni selezionalibili"
                                                              style="margin-top:20px"
                                                              outlined
                                                              clearable
                                                              no-data-text="Impostare prima una tariffa"
                                                              required
                                                              dense>
                                                        <template v-slot:item="data">
                                                            <template>
                                                                <v-list-item-content>
                                                                    <v-row>
                                                                        <v-col cols="10">
                                                                            <v-list-item-title>{{data.item.tipoServizio.descrizione }} [{{importoEuro(data.item.importo)}}]</v-list-item-title>
                                                                            <v-list-item-subtitle class="font-weight-regular">{{data.item.tipoServizio.tipoConsulenza.descrizione }}</v-list-item-subtitle>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-list-item-content>
                                                            </template>
                                                        </template>
                                                        <template v-slot:selection="data">
                                                            <template>
                                                                {{data.item.tipoServizio.descrizione }} [{{importoEuro(data.item.importo)}}]
                                                            </template>
                                                        </template>
                                                    </v-select>
                                                </v-col>

                                            </v-row>

                                            <v-row class="mt-n7">

                                                <v-col cols="8">
                                                    <v-text-field v-model="cliente.mensilitaFido"
                                                                  label="Numero canoni"
                                                                  outlined
                                                                  :rules="[v=> v==null || v.length==0 || /^[0-9]{1,2}$/.test(v) || 'nn']"
                                                                  persistent-hint
                                                                  dense></v-text-field>

                                                </v-col>
                                                <v-col>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{on, attrs}">
                                                            <v-btn class="mx-2"
                                                                   icon
                                                                   @click="setFido(2)"
                                                                   v-bind="attrs"
                                                                   v-on="on"
                                                                   color="deep-orange darken-4">
                                                                <v-icon dark>
                                                                    mdi-credit-card-edit-outline
                                                                </v-icon>
                                                            </v-btn>
                                                            <v-btn icon>
                                                                <v-icon></v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>imposta il fido come multiplo di canone</span>
                                                    </v-tooltip>
                                                </v-col>

                                            </v-row>
                                            <v-row class="mt-n7">
                                                <v-col>
                                                    <vuetify-money v-model="cliente.importoFido"
                                                                   label="Valore del fido"
                                                                   outlined
                                                                   :background-color="theme ? 'dark-grey':'white'"
                                                                   dense
                                                                   disabled
                                                                   hint="Il valore mostra il risultato delle impostazioni"
                                                                   :options="moneyOptions">
                                                    </vuetify-money>
                                                </v-col>

                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>

                            </v-row>

                        </v-tab-item>
                        <v-tab-item v-if="isServicePro && viewTab && (!nuovo && !rubrica && ($store.state.utente && ($store.state.utente.isGestoreStudio || $store.state.utente.isReception)))">
                            <v-row>
                                <v-col cols="9">
                                    <v-data-table :headers="headersContratti"
                                                  :items="listaContratti"
                                                  item-key="contratto.id"
                                                  :expanded.sync="expanded"
                                                  show-expand
                                                  single-expand
                                                  @item-expanded="expandContratto"
                                                  ref="tabella"
                                                  :loading="loading"
                                                  :items-per-page="3"
                                                  class="elevation-1 ma-2"
                                                  :footer-props="{
                                                    'items-per-page-text':'Pagina da',
                                                    'items-per-page-all-text': '',
                                                    'items-per-page-options':[3]
                                                    }"
                                                  loading-text="Caricamento dati..."
                                                  no-data-text="Nessun contratto presente in archivio"
                                                  no-results-text="Nessuna contratto corrispodende ai criteri di ricerca">
                                        <template slot="header.operazione">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-bind="attrs" color="info" class="ml-2"
                                                           v-on="on"
                                                           icon @click="newContratto">
                                                        <v-icon>mdi-folder-plus</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>
                                                    Aggiungi Contratto
                                                </span>
                                            </v-tooltip>
                                        </template>
                                        <template v-slot:item.contratto.descrizione="{ item }">
                                            <v-text-field 
                                                          v-model="item.contratto.descrizione"
                                                          hide-details
                                                          outlined
                                                          dense
                                                          style="font-size:smaller"
                                                          :rules="[v=> !!v || '']"
                                                          >
                                            </v-text-field>
                                        </template>
                                        <template v-slot:item.dataInizio="{ item }">
                                            <v-text-field v-model="item.contratto.dataInizio"
                                                          required
                                                          type="date"
                                                          outlined
                                                          hide-details
                                                          style="font-size:smaller"
                                                          dense></v-text-field>
                                        </template>
                                        <template v-slot:item.contratto.cadenza="{ item }">
                                            <v-select 
                                                      v-model="item.contratto.tipoPeriodicita.id"
                                                      :items="listaPeriodicitaServizi"
                                                      hide-details
                                                      item-value="id"
                                                      style="font-size:smaller"
                                                      item-text="descrizione"
                                                      outlined
                                                      dense></v-select>
                                        </template>
                                        <template v-slot:item.dataFine="{ item }">
                                            <v-text-field v-model="item.contratto.dataFine"
                                                          type="date"
                                                          style="font-size:smaller"
                                                          outlined
                                                          required
                                                          hide-details
                                                          dense></v-text-field>
                                        </template>
                                        <template v-slot:item.operazione="{ item }">
                                            <v-tooltip bottom v-if="($store.state.utente && $store.state.utente.isGestoreStudio) || ($store.state.utente && $store.state.utente.isReception)">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-bind="attrs"
                                                           v-on="on"
                                                           icon @click="eliminaContratto(item)"
                                                           color="red darken-3" class="ml-2 mt-n6">
                                                        <v-icon color="error darken-2">mdi-trash-can-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>
                                                    Elimina Contratto
                                                </span>
                                            </v-tooltip>
                                        </template>
                                        <template v-slot:item.contratto.importo="{ item }">
                                            <vuetify-money 
                                                v-model="item.contratto.importo"
                                                outlined    
                                                dense       
                                                :background-color="theme ? 'dark-grey':'white'"
                                                :rules="[v=> !!v || '']" 
                                                hide-details
                                                :options="moneyOptions">
                                            </vuetify-money>
                                        </template>

                                        <template v-slot:expanded-item="{ headers, item }">
                                            <td colspan="12">
                                                <v-card>
                                                    <v-data-table class="my-2"
                                                                  :headers="headersServiziContratto"
                                                                  :items="serviziContrattoEstesi"
                                                                  :items-per-page="100"
                                                                  loading-text="Caricamento dati..."
                                                                  no-data-text="Nessun servizio presente"                                                                  
                                                                  hide-default-footer>
                                                        <template v-slot:item.dataInizio="{ item }">
                                                            <v-text-field v-model="item.servizioContratto.dataInizio"
                                                                          required
                                                                          type="date"
                                                                          outlined
                                                                          hide-details
                                                                          style="font-size:smaller"
                                                                          dense></v-text-field>
                                                        </template>
                                                        <template v-slot:item.dataFine="{ item }">
                                                            <v-text-field v-model="item.servizioContratto.dataFine"
                                                                          required
                                                                          type="date"
                                                                          outlined
                                                                          hide-details
                                                                          style="font-size:smaller"
                                                                          dense></v-text-field>
                                                        </template>
                                                        <template v-slot:item.operazione="{ item }">
                                                            <v-tooltip bottom v-if="($store.state.utente && $store.state.utente.isGestoreStudio) || ($store.state.utente && $store.state.utente.isReception)">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn v-bind="attrs"
                                                                           v-on="on"
                                                                           icon @click="eliminaServizioContratto(item)"
                                                                           color="red darken-3" class="ml-2 mt-n6">
                                                                        <v-icon color="error darken-2">mdi-trash-can-outline</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>
                                                                    Elimina Servizio
                                                                </span>
                                                            </v-tooltip>
                                                        </template>
                                                        <template v-slot:item.periodo="{ item }">
                                                            <v-select v-model="item.periodo"
                                                                      :items="listaPeriodi"
                                                                      hide-details
                                                                      item-value="id"
                                                                      item-text="descrizione"
                                                                      style="font-size:smaller"
                                                                      outlined
                                                                      dense></v-select>
                                                        </template>
                                                        <template v-slot:item.dettaglio="{ item }">
                                                            <!--nessun dettaglio-->
                                                            <span v-if="item.periodo=='00' || item.periodo=='04' || item.periodo=='01'">
                                                                nessun dettaglio
                                                            </span>
                                                            <!--settimana-->
                                                            <v-select v-if="item.periodo=='02'"
                                                                      v-model="item.dettaglio"
                                                                      :items="listaDettaglioSettimana"
                                                                      hide-details
                                                                      item-value="id"
                                                                      item-text="descrizione"
                                                                      style="font-size:smaller"
                                                                      outlined
                                                                      clearable
                                                                      required
                                                                      :rules="[v=> v.length>0 || '']"                                                                      small-chips
                                                                      multiple
                                                                      disable-lookup
                                                                      dense></v-select>
                                                            <!--mese-->
                                                            <v-select v-if="item.periodo=='03'"
                                                                      v-model="item.dettaglio"
                                                                      :items="listaDettaglioMese"
                                                                      hide-details
                                                                      item-value="id"
                                                                      small-chips
                                                                      multiple
                                                                      clearable
                                                                      required
                                                                      :rules="[v=> !!v || '']"   
                                                                      disable-lookup
                                                                      item-text="descrizione"
                                                                      style="font-size:smaller"
                                                                      outlined
                                                                      dense></v-select>
                                                        </template>
                                                        <template v-slot:item.servcon="{ item }">
                                                            <v-select v-model="item.servizioContratto.pratica.id"
                                                                      :items="listaServCon"
                                                                      hide-details
                                                                      item-value="pratica.id"
                                                                      item-text="pratica.descrizione"
                                                                      style="font-size:smaller"
                                                                      outlined
                                                                      dense></v-select>
                                                        </template>
                                                        <template slot="header.operazione">
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn v-bind="attrs" color="info" class="ml-2"
                                                                           v-on="on"
                                                                           icon @click="newServizioContratto">
                                                                        <v-icon>mdi-folder-plus</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>
                                                                    Aggiungi Servizio Contrattuale
                                                                </span>
                                                            </v-tooltip>
                                                        </template>
                                                        <!--<template v-slot:item.frequenza="{ item }">
                                                            <v-text-field v-model="item.frequenza"
                                                                          outlined
                                                                          dense
                                                                          :rules="[v=> !!v || '']"
                                                                          hide-details>
                                                            </v-text-field>
                                                        </template>-->
                                                    </v-data-table>
                                                </v-card>
                                            </td>
                                        </template>
                                    </v-data-table>

                                </v-col>

                                <v-col class="ma-4">
                                    <!--<v-card>
                                        <v-card-text>
                                            <v-row class="ma-1">
                                                <v-row>
                                                    <span><strong>Copia i contratti da:</strong></span>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{on, attrs}">
                                                            <v-btn class="mt-n1"
                                                                   icon
                                                                   @click="copiaTariffe"
                                                                   v-bind="attrs"
                                                                   :disabled="!idClienteCopia"
                                                                   v-on="on"
                                                                   color="green">
                                                                <v-icon dark>
                                                                    mdi-cash-sync
                                                                </v-icon>
                                                            </v-btn>
                                                            <v-btn icon>
                                                                <v-icon></v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Copia le tariffe da un altro cliente</span>
                                                    </v-tooltip>
                                                </v-row>
                                                <v-row class="mt-2">
                                                    <v-autocomplete v-model="idClienteCopia"
                                                                    :items="listaCliente"
                                                                    item-value="cliente.id"
                                                                    item-text="cliente.denominazioneNormalizzata"
                                                                    label="Cliente seme"
                                                                    outlined
                                                                    clearable
                                                                    dense></v-autocomplete>
                                                </v-row>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>-->


                                    <v-card class="mt-1">
                                        <v-card-text>
                                            <v-row class="ma-1">
                                                <span><strong>Impostazione del fido</strong></span>
                                            </v-row>

                                            <v-row>
                                                <v-col cols="8">
                                                    <vuetify-money v-model="importoFido"
                                                                   label="Importo fido"
                                                                   outlined
                                                                   :background-color="theme ? 'dark-grey':'white'"
                                                                   dense
                                                                   :options="moneyOptions">
                                                    </vuetify-money>
                                                </v-col>
                                                <v-col>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{on, attrs}">
                                                            <v-btn class="mx-2"
                                                                   icon
                                                                   @click="setFido(1)"
                                                                   v-bind="attrs"
                                                                   v-on="on"
                                                                   color="deep-orange darken-4">
                                                                <v-icon dark>
                                                                    mdi-credit-card-edit-outline
                                                                </v-icon>
                                                            </v-btn>
                                                            <v-btn icon>
                                                                <v-icon></v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>imposta il fido come cifra assoluta</span>
                                                    </v-tooltip>
                                                </v-col>
                                            </v-row>

                                            <v-row class="mt-n7">
                                                <v-col>
                                                    <vuetify-money v-model="cliente.importoFido"
                                                                   label="Valore attuale del fido"
                                                                   outlined
                                                                   :background-color="theme ? 'dark-grey':'white'"
                                                                   dense
                                                                   disabled
                                                                   hint="Il valore mostra il risultato delle impostazioni"
                                                                   :options="moneyOptions">
                                                    </vuetify-money>
                                                </v-col>

                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>

                            </v-row>



                        </v-tab-item>
                    </v-form>
                    <v-form ref="form5"
                            v-model="valid5"
                            lazy-validation>
                        <v-tab-item v-if="viewTab && (!nuovo && !rubrica)">
                            <v-col cols="8">
                                <template>
                                    <v-data-table :headers="headersOperatoriCliente"
                                                  :items="operatoriCliente"
                                                  item-key="id"
                                                  hide-default-footer
                                                  no-data-text="Non sono presenti operatori"
                                                  class="elevation-1">
                                        <template slot="body.append">
                                            <tr>
                                                <th class="title"></th>
                                                <th class="title"></th>
                                                <th class="title">
                                                    <v-icon small
                                                            class="mr-2"
                                                            @click="newOperatoreCliente">
                                                        mdi-plus
                                                    </v-icon>
                                                </th>
                                            </tr>
                                        </template>
                                        <template v-slot:item.azioni="{ item }">
                                            <v-icon small
                                                    class="mr-2"
                                                    @click="editOperatoreCliente(item)">
                                                mdi-pencil
                                            </v-icon>
                                            <v-icon small
                                                    @click="deleteOperatoreCliente(item)">
                                                mdi-delete
                                            </v-icon>
                                        </template>
                                    </v-data-table>
                                </template>
                            </v-col>
                        </v-tab-item>
                    </v-form>
                    <v-form ref="form6"
                            v-model="valid6"
                            lazy-validation>
                        <v-tab-item v-if="viewTab && (!nuovo && !rubrica)">
                            <v-row class="mt-4">
                                <v-col cols="3">
                                    <v-text-field v-model="utente.username"
                                                  label="Username"
                                                  autocomplete="new-password"
                                                  :disabled="usernamepresente"
                                                  required
                                                  outlined
                                                  dense
                                                  :rules="[v=>(v==null || v.length==0 ||  /.+@.+\..+/.test(v) ) || 'inserire una mail valida']"></v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field v-if="utente.username"
                                                  v-model="utente.password"
                                                  label="Password"
                                                  autocomplete="new-password"
                                                  :disabled="usernamepresente"
                                                  required
                                                  outlined
                                                  type='password'
                                                  :rules="[v=> (v!=null && v.length>=4) || 'inserire una password da almeno 4 caratteri' ]"
                                                  dense></v-text-field>

                                </v-col>
                                <v-col cols="2" v-if="!utente.dataUltimoLogin && usernamepresente">
                                    <v-btn :loading="loading3"
                                           :disabled="loading3"
                                           color="blue-grey"
                                           class="white--text"
                                           @click="sendInvitoApp">
                                        <span v-if="isServicePro">Invita ad usare l'App di ServicePro</span>
                                        <span v-else>Invita ad usare l'App di StudioPro</span>
                                        <v-icon right
                                                dark>
                                            mdi-email-fast-outline
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="2" v-if="utente.dataUltimoLogin && usernamepresente">
                                    <v-btn :loading="loading3"
                                           :disabled="loading3"
                                           color="amber lighten-1"
                                           class="white--text"
                                           @click="resetPassword">
                                        <span>Resetta password con il valore di default ({{new Date().getFullYear()}})</span>
                                        <v-icon right
                                                dark>
                                            mdi-lock-reset
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                    </v-form>
                    <v-form ref="form7"
                            v-model="valid7"
                            lazy-validation>
                        <v-tab-item v-if="viewTab && (!nuovo && !rubrica)">
                            <br />
                            <v-row>
                                <v-col cols="8">
                                    <v-data-table class="elevation-1 ma-2"
                                                  v-model="selectedItem"
                                                  single-select
                                                  item-key="id"
                                                  :loading="loadingDoc"
                                                  :headers="headersAllegati"
                                                  :items="allegatiCliente"
                                                  :footer-props="{
                                                        'items-per-page-text':'Pagina da',
                                                        'items-per-page-all-text': '',
                                                        'items-per-page-options':[8]
                                                      }"
                                                  @click:row="selezionaRiga"
                                                  loading-text="Caricamento dati..."
                                                  no-data-text="Nessun allegato presente">
                                        <template slot="body.append">
                                            <tr>
                                                <th class="title"></th>
                                                <th class="title"></th>
                                                <th class="title"></th>
                                                <th class="title"></th>
                                                <th class="title">
                                                    <v-icon class="mr-2"
                                                            @click="newAllegato"
                                                            color="success darken-2">
                                                        mdi-file-document-plus-outline
                                                    </v-icon>
                                                </th>
                                            </tr>
                                        </template>
                                        <template v-slot:item.dataIns="{ item }">
                                            <span v-text="localData(item.dataIns)" />
                                        </template>
                                        <template v-slot:item.note="{ item }">
                                            <v-tooltip slot="append" bottom>
                                                <template v-slot:activator="{ on }">                                                    
                                                    <v-text-field v-on="on" v-model="item.note"
                                                          class="mb-n6"
                                                          outlined
                                                          dense />
                                                </template>
                                                <span>{{item.allegatoName}}</span>
                                            </v-tooltip>
                                        </template>
                                        <template v-slot:item.apri="{ item }">
                                            <v-icon @click="viewAllegato(item)" color="info darken-2">mdi-magnify</v-icon>
                                        </template>
                                        <template v-slot:item.scarica="{ item }">
                                            <v-icon @click="downloadAllegato(item)" color="info darken-2">mdi-download</v-icon>
                                        </template>
                                        <template v-slot:item.azioni="{ item }">
                                            <v-icon @click="eliminaAllegato(item)" color="error darken-2">mdi-trash-can-outline</v-icon>
                                        </template>
                                    </v-data-table>
                                </v-col>
                                <v-col cols="3">

                                    <v-card v-if="allegatiOpen" :loading="loadingPdf">
                                        <v-carousel v-model="carouselId"
                                                    show-arrows
                                                    @change="changeCarusel">
                                            <v-carousel-item v-for="(item) in allegatiCliente"
                                                             :key="item.id"
                                                             target="_blank"
                                                             :href="item.allegatoPath ? item.allegatoPath : 'pdf/blank.pdf'">
                                                <pdf @progress="progress" v-if="item.isPdf" :src="item.allegatoPath"></pdf>
                                                <pdf @progress="progress" v-if="item.isBlank" :src="'pdf/blank.pdf'"></pdf>
                                                <pdf @progress="progress" v-if="item.isNotPreview" :src="'pdf/notpreview.pdf'"></pdf>
                                                <v-img v-if="item.isImage" :src="item.allegatoPath" contain max-height="300"></v-img>
                                            </v-carousel-item>
                                        </v-carousel>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <br />
                        </v-tab-item>
                    </v-form>
                </v-tabs-items>
                <span class="caption ml-4">{{cliente.denominazioneNormalizzata}} [{{cliente.id}}]</span>
            </v-card-text>
        </v-card>

        <v-dialog v-model="dialogRecapiti"
                  max-width="800px">
            <v-card>
                <v-card-title class="grey lighten-3">Recapito</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <br />
                    <v-form ref="formRecapito"
                            v-model="validRecapito"
                            class="mt-4 ml-4 mr-4"
                            lazy-validation>
                        <v-row>
                            <v-col cols="4">
                                <v-select v-model="itemRecapitoCliente.recapito.tipoRecapito.id"
                                          :items="listaTipoRecapito"
                                          item-value="id"
                                          item-text="descrizione"
                                          label="Tipo Recapito"
                                          outlined
                                          required
                                          :rules="[v=> v.length>0 || 'campo richiesto']"
                                          dense></v-select>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field v-model="itemRecapitoCliente.recapito.valore"
                                              label="Recapito"
                                              required
                                              :rules="[v=> v.length>0 || 'inserire un recapito']"
                                              outlined
                                              dense></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field v-model="itemRecapitoCliente.recapito.descrizione"
                                              label="Descrizione"
                                              outlined
                                              dense></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1"
                           text
                           @click="dialogRecapiti = false">
                        Annulla
                    </v-btn>
                    <v-btn color="blue darken-1"
                           text
                           @click="saveRecapito">
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogIndirizzario"
                  max-width="800px">
            <v-card>
                <v-card-title class="grey lighten-3">Indirizzo</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <br />
                    <v-form ref="formIndirizzario"
                            v-model="validIndirizzario"
                            class="mt-4 ml-4 mr-4"
                            lazy-validation>
                        <v-row>
                            <v-col cols="4">
                                <v-select v-model="itemIndirizzarioCliente.indirizzario.tipoIndirizzario.id"
                                          :items="listaTipoIndirizzario"
                                          item-value="id"
                                          item-text="descrizione"
                                          label="Tipo Indirizzo"
                                          outlined
                                          required
                                          :rules="[v=> v.length>0 || 'campo richiesto']"
                                          dense></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="itemIndirizzarioCliente.indirizzario.indirizzo"
                                              label="Indirizzo"
                                              required
                                              :rules="[v=> v.length>0 || 'inserire un indirizzo']"
                                              outlined
                                              dense></v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field v-model="itemIndirizzarioCliente.indirizzario.cap"
                                              label="CAP"
                                              outlined
                                              dense></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="5">
                                <v-combobox v-model="itemIndirizzarioCliente.indirizzario.comune"
                                            :items="listaComuni"
                                            item-value="comune"
                                            item-text="comune"
                                            label="Comune"
                                            :rules="[v=> !!v || 'selezionare il comune']"
                                            outlined
                                            v-on:change="setComuneIndirizzo"
                                            :return-object="false"
                                            ref="v-combobox-ind"
                                            dense></v-combobox>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field v-model="itemIndirizzarioCliente.indirizzario.provincia"
                                              label="Prov."
                                              outlined
                                              dense></v-text-field>
                            </v-col>
                            <v-col cols="5">
                                <v-text-field v-model="itemIndirizzarioCliente.indirizzario.nota"
                                              label="Nota"
                                              outlined
                                              dense></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4">
                                <v-text-field v-model="itemIndirizzarioCliente.indirizzario.latitudine"
                                              outlined
                                              label="Latitudine"
                                              :rules="[v=> v==null ||v.length==0 ||  /^[0-9]{1,3}\.[0-9]{1,15}$/.test(v) || 'esempio 37.12345']"
                                              hint="esempio 37.12345"
                                              persistent-hint
                                              dense>
                                </v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field v-model="itemIndirizzarioCliente.indirizzario.longitudine"
                                              outlined
                                              label="Longitudine"
                                              :rules="[v=> v==null ||v.length==0 ||  /^[0-9]{1,3}\.[0-9]{1,15}$/.test(v) || 'esempio 17.12345']"
                                              hint="esempio 17.12345"
                                              persistent-hint
                                              dense>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1"
                           text
                           @click="dialogIndirizzario = false">
                        Annulla
                    </v-btn>
                    <v-btn color="blue darken-1"
                           text
                           @click="saveIndirizzario">
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogOperatori"
                  max-width="800px">
            <v-card>
                <v-card-title class="grey lighten-3">Operatori</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <br />
                    <v-form ref="formOperatore"
                            v-model="validOperatore"
                            class="mt-4 ml-4 mr-4"
                            lazy-validation>
                        <v-row>
                            <v-col cols="6">
                                <v-select v-model="itemOperatoreCliente.operatoreAttivita.tipoAttivita.id"
                                          :items="listaTipoAttivita"
                                          item-value="id"
                                          item-text="descrizione"
                                          label="Tipo Attività"
                                          outlined
                                          required
                                          :rules="[v=> v.length>0 || 'campo richiesto']"
                                          dense></v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-select v-model="itemOperatoreCliente.operatoreAttivita.operatore.id"
                                          :items="listaOperatore"
                                          item-value="id"
                                          item-text="denominazioneNormalizzata"
                                          :item-disabled="disableItem"
                                          label="Operatore"
                                          outlined
                                          required
                                          :rules="[v=> v.length>0 || 'campo richiesto']"
                                          dense>                                    
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1"
                           text
                           @click="dialogOperatori = false">
                        Annulla
                    </v-btn>
                    <v-btn color="blue darken-1"
                           text
                           @click="saveOperatore">
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-file-input v-model="fileDocumento"
                      prepend-icon=""
                      id="idFileDocumentoCliente"
                      label="File Documento"
                      outlined
                      hide-input
                      type="file"
                      @change="uploadCliente"
                      dense>
        </v-file-input>

        <v-snackbar v-model="snackSuccess" centered
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackError" centered
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel" centered
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote" centered
                    timeout="2000"
                    color="info">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento remoto.
        </v-snackbar>
    </v-dialog>
</template>

<script>

    import { callService, callPost, callPostMultipart, euro, gLocalData, gCheckData, gServerData, gDataCalendar,Snack, gControllData } from '@/modules/utilities.js'
    import pdf from 'vue-pdf'
    import { bus } from '@/main.js'

    export default {
        components: {
            pdf
        },
        data: () => ({
            valid1: true,
            valid2: true,
            valid3: true,
            valid4: true,
            valid5: true,
            valid6: true,
            valid7: true,
            viewTab: true,
            expanded: [],
            rstatus: "0",
            carouselId: null,
            fileDocumento:null,
            allegatiOpen: false,
            selectedItem: [],
            dialog: false,
            tab: 0,
            tabPrec:0,
            loadingSalva: false,
            loadingAnnulla: false,
            loading: false,
            loadingDoc: false,
            loadingPdf: false,
            loading3: false,
            validRecapito: true,
            validIndirizzario: true,
            validOperatore: true,
            snackSuccess: false,
            snackError: false,
            snackCancel: false,
            snackRemote: false,
            dialogDelete: false,
            isDitta: false,
            isAzienda: false,
            isPersona: false,
            isAmministrazione: false,
            isCondominio: false,
            isConsulenzaContabile: false,
            isConsulenzaLavoro: false,
            isConsulenzaPulizia: false,
            isConsulenzaManutenzione: false,
            importoEdit: 0,
            id: '',
            theme:'',
            nuovo: false,
            usernamepresente: false,
            cliente:{
                id:'',
                tipoCliente: {
                    id:''
                },
                tipoRegimeIVA: {
                    id: ''
                },
                tipoRegimeContabile: {
                    id: ''
                },
                codice: '',
                denominazione: '',
                codiceFiscale: '',
                partitaIVA: '',
                pec:'',
                scadenzaPEC: null,
                sdi:'',
                professionista: {
                    id:''
                },
                tariffaMensilitaFido: {
                    id:''
                }
            },
            utente: {},
            checkSave: false,
            checkCF: true,
            scPEC: true,
            rubrica: false,
            moneyOptions: {
                        locale: "it-IT",
                        prefix: "€",
                        suffix: "",
                        length: 10,
                        precision: 2
            },
            idTipoCliente: '',
            listaTipoCliente: [],
            listaTipoRegimeIVA: [],
            listaTipoRegimeContabile: [],
            listaComuni: [],
            listaTags: [],
            listaTagsIni: [],
            listaCliente: [],
            listaContratti: [],
            serviziContrattoEstesi:[],
            idClienteCopia: '',
            labelInput:'',
            selectTipoRecapito: '',
            selectTipoIndirizzario: '',
            selectTipoAttivita: '',
            selectOperatore: '',
            listaTipoRecapito: [],
            listaTipoIndirizzario: [],
            listaTipoAttivita: [],
            listaOperatore: [],
            listaPeriodicitaContabile: [],
            listaPeriodicitaServizi: [],
            listaPeriodi: [],
            listaDettaglioSettimana: [],
            listaDettaglioMese: [],
            listaServCon: [],
            listaCanoni: [],
            idCanone: '',
            listaPeriodicitaLavoro: [],
            listaOperatoriProfessionisti: [],
            idPeriodicitaContabile: 1,
            idPeriodicitaLavoro: 7,
            idPeriodicitaPulizia: 1,
            idPeriodicitaManutenzione: 6,
            indexRecapitoCliente: -1,
            indexIndirizzarioCliente: -1,
            indexOperatoreCliente: -1,
            itemRecapitoCliente: {
                recapito: {
                    tipoRecapito: {
                        id:'',
                        descrizione:''
                    },
                    descrizione:'',
                    valore:''
                }
            },
            itemIndirizzarioCliente: {
                indirizzario: {
                    tipoIndirizzario: {
                        id: '',
                        descrizione: ''
                    },
                    cap: '',
                    indirizzo: '',
                    comune:'',
                    provincia: '',
                    nota: ''
                }
            },
            itemOperatoreCliente: {
                operatoreAttivita: {
                    tipoAttivita: {
                        id: '',
                        descrizione: ''
                    },
                operatore: {
                    id: '',
                    denominazioneNormalizzata: ''
                    }
                }
            },
            newItemRecapitoCliente: {
                cliente: null,
                recapito: {
                    tipoRecapito: {
                        id:'',
                        descrizione:''
                    },
                    descrizione:'',
                    valore:''
                }
            },
            newItemIndirizzarioCliente: {
                indirizzario: {
                    tipoIndirizzario: {
                        id:'',
                        descrizione:''
                    },
                    cap:'',
                    indirizzo:'',
                    comune:'',
                    provincia:'',
                    nota:''
                }
            },
            newItemOperatoreCliente: {
                operatoreAttivita: {
                    tipoAttivita: {
                        id: '',
                        descrizione: ''
                    },
                    operatore: {
                        id: '',
                        denominazioneNormalizzata: ''
                    }
                }
            },
            dialogRecapiti: false,
            dialogIndirizzario: false,
            dialogOperatori: false,
            recapitiCliente: [],
            indirizzarioCliente: [],
            operatoriCliente: [],
            tariffeContabilitaCliente: [],
            tariffeLavoroCliente: [],
            tariffePuliziaCliente: [],
            tariffeManutenzioneCliente: [],
            contrattoCorrente:null,
            allegatiCliente: [],
            listaTipiDocumentoIndentificativo: [],
            importoFido: '€ 0,00',
            impFido: 0,
            valoricf: [],
            headersRecapitiCliente: [
                { text: 'Tipo Recapito', value: 'recapito.tipoRecapito.descrizione' },
                { text: 'Recapito', value: 'recapito.valore' },
                { text: 'Descrizione', value: 'recapito.descrizione' },
                { text: '', value: 'azioni',sortable: false }
            ],    
            headersTariffeContabili: [
                { text: '', value: 'abilitato', sortable: false },
                { text: 'Tariffa', value: 'tipoServizio.descrizione', sortable: false },
                { text: 'Importo', value: 'importo', sortable: false }
            ],             
            headersTariffeLavoro: [
                { text: '', value: 'abilitato', sortable: false },
                { text: 'Tariffa', value: 'tipoServizio.descrizione', sortable: false },
                { text: 'Importo', value: 'importo', sortable: false }
            ],
            headersIndirizzarioCliente: [
                { text: 'Tipo Indirizzo', value: 'indirizzario.tipoIndirizzario.descrizione' },
                { text: 'Indirizzo', value: 'indirizzario.indirizzo' },
                { text: 'CAP', value: 'indirizzario.cap' },
                { text: 'Comune', value: 'indirizzario.comune' },
                { text: 'Provincia', value: 'indirizzario.provincia' },
                { text: 'Note', value: 'indirizzario.nota' },
                { text: 'Coordinate', value: 'indirizzario.coordinate' },
                { text: '', value: 'azioni', sortable: false }
            ],
            headersOperatoriCliente: [
                { text: 'Tipo Attivita', value: 'operatoreAttivita.tipoAttivita.descrizione' },
                { text: 'Operatore', value: 'operatoreAttivita.operatore.denominazioneNormalizzata' },
                { text: '', value: 'azioni', sortable: false }
            ],
            headersAllegati: [
                { text: '', value: 'apri', sortable: false },
                { text: '', value: 'scarica', sortable: false },
                { text: 'Data Aggiornamento', value: 'dataIns' },
                { text: 'Note', value: 'note', width: '50%' },
                { text: '', value: 'azioni', sortable: false }
            ],
            headersContratti: [
                { text: 'Descrizione', value: 'contratto.descrizione', width: '20%' },
                { text: 'Importo addebito', value: 'contratto.importo', sortable: false },
                { text: 'Frequenza addebito', value: 'contratto.cadenza' },
                { text: 'Data Inizio', value: 'dataInizio' },
                { text: 'Data Fine', value: 'dataFine' },
                { text: '', value: 'operazione', sortable: false }, 
            ],
            headersServiziContratto: [
                { text: 'Servizio associato', value: 'servcon', width: '25%' },
                //{ text: 'Ripeti ogni', value: 'frequenza', width: '10%' },
                { text: 'Periodicità', value: 'periodo', width: '15%' },
                { text: 'Dettaglio', value: 'dettaglio', width: '25%' },
                { text: 'Data Inizio', value: 'dataInizio', width: '10%' },
                { text: 'Data Fine', value: 'dataFine', width: '10%' },
                { text: '', value: 'operazione', sortable: false, width: '5%' },
            ],
        }),
        mounted() {
            if (!this.$store.state.logged) {
                this.$router.replace({ name: "login" }).catch(() => { });
            }            
        },
        methods: {
            disableItem(item) {
                return item.isCancellato;
            },
            progress(val) {
                this.loadingPdf = (val < 1)
            },
            newAllegato(item) {
                let fileDocumento = document.getElementById('idFileDocumentoCliente')
                fileDocumento.click();
            },
            controllData(v) {
                return gControllData(v);
            },
            async uploadCliente() {
                this.loadingDoc=true;
                const formData = new FormData();
                formData.append('partition', this.$store.state.partition);
                formData.append('username', this.$store.state.utente.username);
                formData.append('token', this.$store.state.utente.token);
                formData.append('idCliente', this.cliente.id);
                formData.append('fileDocumento', this.fileDocumento);
                let checkSave = this.checkSave;
                const esito = await callPostMultipart('api/allegatoCliente/upload', formData);
                this.loadingDoc=false;

                if (esito) this.snackSuccess = true;
                else this.snackError = true;

                if (esito) {
                    let param=[this.cliente.id]
                    this.allegatiCliente = await callService("api/allegatocliente", param);
                    this.allegatiOpen = this.allegatiCliente.length>0;
                }
                this.fileDocumento = null;

                this.$nextTick(() => {
                    this.checkSave=checkSave; //ripristino il valore precedente
                });            
            },
            viewAllegato(item) {
                window.open(item.allegatoPath);
            },
            downloadAllegato(item) {
                var link = document.createElement('a');
                link.href = item.allegatoPath;
                link.download = item.allegato;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
            async eliminaAllegato(item) {
                bus.$emit('msg-open', { message: "Eliminare l'allegato?", item });
                bus.$on('msg-cancel', this.eliminaAllegatoCancel);
                bus.$on('msg-ok', this.eliminaAllegatoOk);
            },
            async eliminaAllegatoCancel(item) {
                bus.$off('msg-cancel', this.eliminaAllegatoCancel);
                bus.$off('msg-ok', this.eliminaAllegatoOk);
            },
            async eliminaAllegatoOk(item) {
                bus.$off('msg-cancel', this.eliminaAllegatoCancel);
                bus.$off('msg-ok', this.eliminaAllegatoOk);
                
                let indexAllegato = this.allegatiCliente.indexOf(item);

                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    idAllegatoCliente: item.id
                };

                this.loadingDoc = true;
                let checkSave = this.checkSave;
                const esito = await callPost('api/allegatoCliente/elimina', param);
                this.loadingDoc = false;

                if (esito) {
                    this.allegatiCliente.splice(indexAllegato, 1);
                    this.snackSuccess = true;
                }
                else this.snackError = true;

                this.$nextTick(() => {
                    this.checkSave=checkSave; //ripristino il valore precedente
                });

            },   
            selezionaRiga(item) {
                this.selectedItem = [item];
                this.carouselId = this.allegatiCliente.indexOf(item);
            },
            changeCarusel(i) {
                this.selectedItem = [this.allegatiCliente[i]];
            },
            checkTag() {
                let check=
                   this.labelInput
                && this.labelInput.length>2
                && this.labelInput.indexOf('-')==-1
                && this.labelInput.indexOf(' ')==-1;
                return check;
            },
            removeTag(item) {
                this.listaTags = this.listaTags.filter(c => c != item.id);
            },
            aggiungiTag() {
                if (this.labelInput && this.labelInput.length>2) {
                    this.listaTagsIni.push({ id: this.labelInput });
                    this.listaTags.push(this.labelInput);
                    this.labelInput=""
                }                
            },
            async changePerdiodicitaContabile() {
                let param = [this.cliente.id, this.$store.state.idAnnoContabile, this.idPeriodicitaContabile];
                this.tariffeContabilitaCliente = await callService("api/tariffacliente/tariffestandardcontabilita", param);
                this.tariffeContabilitaCliente = this.tariffeContabilitaCliente.map(v => ({ ...v, abilitato: v.importo > 0 }));
            },
            async changePerdiodicitaPulizia() {
                let param = [this.cliente.id, this.$store.state.idAnnoContabile, this.idPeriodicitaPulizia];
                this.tariffePuliziaCliente = await callService("api/tariffacliente/tariffestandardpulizia", param);
                this.tariffePuliziaCliente = this.tariffePuliziaCliente.map(v => ({ ...v, abilitato: v.importo > 0 }));
            },
            async changePerdiodicitaLavoro() {
                let param = [this.cliente.id, this.$store.state.idAnnoContabile, this.idPeriodicitaLavoro];
                this.tariffeLavoroCliente = await callService("api/tariffacliente/tariffestandardlavoro", param);
                this.tariffeLavoroCliente = this.tariffeLavoroCliente.map(v => ({ ...v, abilitato: v.importo > 0 }));
            },
            async changePerdiodicitaManutenzione() {
                let param = [this.cliente.id, this.$store.state.idAnnoContabile, this.idPeriodicitaManutenzione];
                this.tariffeManutenzioneCliente = await callService("api/tariffacliente/tariffestandardmanutenzione", param);
                this.tariffeManutenzioneCliente = this.tariffeManutenzioneCliente.map(v => ({ ...v, abilitato: v.importo > 0 }));
            },
            async valoridacf() {
                let cf = this.cliente.codiceFiscaleLR;
                let param = [cf];
                let valoricf = await callService("api/cliente/valoridacf", param);
                if (valoricf.comune) {
                    this.cliente.luogoNascita = valoricf.comune;
                    this.cliente.provinciaNascita = valoricf.provincia;
                    this.cliente.dataNascita = this.localData(valoricf.dataNascita);
                    this.checkCF = true;
                }
                else {
                    this.checkCF = false;
                }
            },
            checkDupCF(cf) {
                if(!cf) return true;
                var filtro = this.$store.state.clientiEstesi.find(ce => ce.cliente.codiceFiscale === cf && ce.cliente.id != this.cliente.id);
                if(!filtro) return true;

                return false;
            },
            checkDupPIVA(piva) {
                if(!piva) return true;
                var filtro = this.$store.state.clientiEstesi.find(ce => ce.cliente.partitaIVA === piva && ce.cliente.id != this.cliente.id);
                if(!filtro) return true;

                return false;
            },
            setFido(tipo) {
                this.impFido = 0;
                if (tipo == 1) {
                    this.impFido = this.importoFido;
                    this.cliente.mensilitaFido = 0;
                    this.cliente.tariffaMensilitaFido.id = '';
                }
                else {
                    var canone = this.listaCanoni.find(x => x.id === this.cliente.tariffaMensilitaFido.id);
                    this.impFido = canone.importo * this.cliente.mensilitaFido;
                    this.importoFido = 0;
                }

                this.cliente.importoFido = this.impFido;
            },
            newContratto() {

                var d = new Date();
                var year = d.getFullYear().toString();
                var di = new Date(year, 0, 1, 0, 0, 0, 0);
                var df = new Date(year, 11, 31, 0, 0, 0, 0);

                let nContratto =
                {
                    descrizione: "nuovo contratto",
                    tipoPeriodicita:
                    {
                        id: this.listaPeriodicitaServizi[0].id
                    },
                    importo: 0,
                    dataInizio: this.controllData(di),
                    dataFine: this.controllData(df)
                }

                this.listaContratti.push(
                    {
                        contratto: nContratto
                    })
            },
            newServizioContratto() {
                this.serviziContrattoEstesi.push(
                {
                    servizioContratto: {
                        id: "0",
                        contratto: this.contrattoCorrente,
                        pratica:
                        {
                            id: this.listaServCon[0].pratica.id
                        },
                        dataInizio: this.contrattoCorrente.dataInizio,
                        dataFine: this.contrattoCorrente.dataFine
                    },
                    frequenza: 1,                     
                    periodo: "01"
                });            
            },
            async copiaTariffe() {

                this.loading3 = true;

                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    idCliente: this.cliente.id,
                    idClienteSeme: this.idClienteCopia,
                    tipoCopia: 1,
                };

                //alert(JSON.stringify(param));

                const result = await callPost('api/tariffaCliente/copiaTariffe', param);
                await this.internalCreated(result ? Snack.success : Snack.error);
                this.idClienteCopia = '';

                if (result) { 
                    bus.$emit('cliente-changed', { lista: [this.cliente.id], sessionID: "-1" });
                    await this.internalCreated(cli ? Snack.success : Snack.error);
                }
                this.loading3 = false;
            },

            changeContabilita() {
                if (!this.isConsulenzaContabile) {
                    this.idPeriodicitaContabile = 0;
                    this.tariffeContabilitaCliente = [];
                }
                else {
                    //vorrei chiamare la funzione con la periodicità di default
                    this.idPeriodicitaContabile = 1;
                    this.changePerdiodicitaContabile();
                }
            },
            changePulizia() {
                if (!this.isConsulenzaPulizia) {
                    this.idPeriodicitaPulizia = 0;
                    this.tariffePuliziaCliente = [];
                }
                else {
                    //vorrei chiamare la funzione con la periodicità di default
                    this.idPeriodicitaPulizia = 1;
                    this.changePerdiodicitaPulizia();
                }
            },
            changeLavoro() {
                if (!this.isConsulenzaLavoro) {
                    this.idPeriodicitaLavoro = 0;
                    this.tariffeLavoroCliente = [];
                }
                else {
                    //vorrei chiamare la funzione con la periodicità di default
                    this.idPeriodicitaLavoro = 6;
                    this.changePerdiodicitaLavoro();
                }
            },
            changeManutenzione() {
                if (!this.isConsulenzaManutenzione) {
                    this.idPeriodicitaManutenzione = 0;
                    this.tariffeManutenzioneCliente = [];
                }
                else {
                    //vorrei chiamare la funzione con la periodicità di default
                    this.idPeriodicitaManutenzione = 7;
                    this.changePerdiodicitaManutenzione();
                }
            },
            logout() {
                this.$store.dispatch('logout');
                this.$store.dispatch('setUtente', null);
                this.$router.replace({ name: "login" }).catch(() => { });
            },
            importoEuro(v) {
                return euro(v);
            },
            saveNumero(item) {
                item.importo = Number(this.importoEdit);                
            }, 
            setTipoCliente(idTipoCliente) {
                let tipoCliente = this.listaTipoCliente.find(x => x.id === idTipoCliente);
                if(idTipoCliente) {
                    this.isDitta= (tipoCliente.ordinamento==1);
                    this.isAzienda= (tipoCliente.ordinamento>=2 && tipoCliente.ordinamento<=5);
                    this.isPersona = (tipoCliente.ordinamento == 6 || tipoCliente.ordinamento == 7);                    
                }      
                if (this.nuovo) {
                    this.$nextTick(() => {
                        this.$refs.form1.resetValidation();
                        this.$refs.form2.resetValidation();
                        this.$refs.form3.resetValidation();
                        this.$refs.form4.resetValidation();
                        this.$refs.form5.resetValidation();
                        this.$refs.form6.resetValidation();
                        this.$refs.form7.resetValidation();
                    });
                }                
            },
            setTipoClienteServicePro(idTipoCliente) {
                let tipoCliente = this.listaTipoCliente.find(x => x.id === idTipoCliente);
                if (idTipoCliente) {
                    this.isDitta = (tipoCliente.ordinamento == 2);
                    this.isAmministrazione = (tipoCliente.ordinamento == 7);
                    this.isPersona = (tipoCliente.ordinamento == 1);
                    this.isCondominio = (tipoCliente.ordinamento == 8);
                }
                if (this.nuovo) {
                    this.$nextTick(() => {
                        this.$refs.form1.resetValidation();
                        this.$refs.form2.resetValidation();
                        this.$refs.form3.resetValidation();
                        this.$refs.form4.resetValidation();
                        this.$refs.form5.resetValidation();
                        this.$refs.form6.resetValidation();
                        this.$refs.form7.resetValidation();
                    });
                } 
            },
            setComune(comune) {
                let comuneOjb = this.listaComuni.find(x => x.comune === comune);
                if (comuneOjb) {
                    this.cliente.provinciaNascita = comuneOjb.siglaProvincia;
                }
            },
            setComuneIndirizzo(comune) {
                let comuneOjb = this.listaComuni.find(x => x.comune === comune);
                if (comuneOjb) {
                    this.itemIndirizzarioCliente.indirizzario.cap = comuneOjb.cap;
                    this.itemIndirizzarioCliente.indirizzario.provincia = comuneOjb.siglaProvincia;
                }
            },
            saveRecapito() {
                if (this.$refs["v-combobox-ind"]) this.$refs["v-combobox-ind"].blur();

                this.$nextTick(() => {
                    if (!this.$refs.formRecapito.validate()) return;

                    if (this.indexRecapitoCliente > -1) {

                        Object.assign(this.recapitiCliente[this.indexRecapitoCliente], this.itemRecapitoCliente);
                        //ho l'id, ma devo visualizzare la colonna descrizione 
                        let obj = this.listaTipoRecapito.find(x => x.id === this.itemRecapitoCliente.recapito.tipoRecapito.id);
                        this.itemRecapitoCliente.recapito.tipoRecapito.descrizione = obj.descrizione;
                        this.indexRecapitoCliente = -1;
                        this.dialogRecapiti = false;
                    }
                    else {
                        let obj = this.listaTipoRecapito.find(x => x.id === this.itemRecapitoCliente.recapito.tipoRecapito.id);
                        this.itemRecapitoCliente.recapito.tipoRecapito.descrizione = obj.descrizione;
                        this.recapitiCliente.push(this.itemRecapitoCliente);
                        this.dialogRecapiti = false;
                    }
                });
            },
            saveIndirizzario() {
                if (!this.$refs.formIndirizzario.validate()) return;

                if (this.indexIndirizzarioCliente > -1) {
                    Object.assign(this.indirizzarioCliente[this.indexIndirizzarioCliente], this.itemIndirizzarioCliente);
                    //ho l'id, ma devo visualizzare la colonna descrizione 
                    let obj = this.listaTipoIndirizzario.find(x => x.id === this.itemIndirizzarioCliente.indirizzario.tipoIndirizzario.id);
                    this.itemIndirizzarioCliente.indirizzario.tipoIndirizzario.descrizione = obj.descrizione;
                    this.indexIndirizzarioCliente = -1;
                    this.dialogIndirizzario = false;
                }
                else {
                    let obj = this.listaTipoIndirizzario.find(x => x.id === this.itemIndirizzarioCliente.indirizzario.tipoIndirizzario.id);
                    this.itemIndirizzarioCliente.indirizzario.tipoIndirizzario.descrizione = obj.descrizione;

                    this.indirizzarioCliente.push(this.itemIndirizzarioCliente);
   
                    this.dialogIndirizzario = false;
                }
            },
            saveOperatore() {
                if (!this.$refs.formOperatore.validate()) return;

                if (this.indexOperatoreCliente > -1) {

                    Object.assign(this.operatoriCliente[this.indexOperatoreCliente], this.itemOperatoreCliente);
                    //ho l'id, ma devo visualizzare la colonna descrizione 
                    let obj = this.listaTipoAttivita.find(x => x.id === this.itemOperatoreCliente.operatoreAttivita.tipoAttivita.id);
                    let obj1 = this.listaOperatore.find(x => x.id === this.itemOperatoreCliente.operatoreAttivita.operatore.id);
                    this.itemOperatoreCliente.operatoreAttivita.tipoAttivita.descrizione = obj.descrizione;
                    this.itemOperatoreCliente.operatoreAttivita.operatore.denominazioneNormalizzata = obj1.denominazioneNormalizzata;
                    this.indexOperatoreCliente = -1;
                    this.dialogOperatori = false;
                }
                else {
                    let obj = this.listaTipoAttivita.find(x => x.id === this.itemOperatoreCliente.operatoreAttivita.tipoAttivita.id);
                    let obj1 = this.listaOperatore.find(x => x.id === this.itemOperatoreCliente.operatoreAttivita.operatore.id);
                    this.itemOperatoreCliente.operatoreAttivita.tipoAttivita.descrizione = obj.descrizione;
                    this.itemOperatoreCliente.operatoreAttivita.operatore.denominazioneNormalizzata = obj1.denominazioneNormalizzata;
                    this.operatoriCliente.push(this.itemOperatoreCliente);
                    this.dialogOperatori = false;
                }
            },
            deleteRecapitoCliente(item) {
                var i=this.recapitiCliente.indexOf(item);
                this.recapitiCliente.splice(i, 1);
            },
            deleteIndirizzarioCliente(item) {
                var i = this.indirizzarioCliente.indexOf(item);
                this.indirizzarioCliente.splice(i, 1);
            },
            deleteOperatoreCliente(item) {
                var i = this.operatoriCliente.indexOf(item);
                this.operatoriCliente.splice(i, 1);
            },
            newRecapitoCliente(item) {
                this.indexRecapitoCliente=-1;
                this.itemRecapitoCliente = JSON.parse(JSON.stringify(this.newItemRecapitoCliente));
                this.dialogRecapiti = true;
                this.$nextTick(() => {
                    this.$refs.formRecapito.resetValidation();
                });
            },    
            newIndirizzarioCliente(item) {
                this.indexIndirizzarioCliente = -1;                
                this.itemIndirizzarioCliente = JSON.parse(JSON.stringify(this.newItemIndirizzarioCliente));
                this.dialogIndirizzario = true;
                this.$nextTick(() => {
                    this.$refs.formIndirizzario.resetValidation();
                });
            },
            newOperatoreCliente(item) {
                this.indexOperatoreCliente = -1;
                this.itemOperatoreCliente = JSON.parse(JSON.stringify(this.newItemOperatoreCliente));
                this.dialogOperatori = true;
                this.$nextTick(() => {
                    this.$refs.formOperatore.resetValidation();
                });
            },            
            editRecapitoCliente(item) {
                this.indexRecapitoCliente=this.recapitiCliente.indexOf(item);
                this.itemRecapitoCliente = JSON.parse(JSON.stringify(item));
                this.dialogRecapiti = true;
                this.$nextTick(() => {
                    this.$refs.formRecapito.resetValidation();
                });
            },
            editIndirizzarioCliente(item) {
                this.indexIndirizzarioCliente = this.indirizzarioCliente.indexOf(item);
                this.itemIndirizzarioCliente = JSON.parse(JSON.stringify(item));
                this.dialogIndirizzario = true;
                this.$nextTick(() => {
                    this.$refs.formIndirizzario.resetValidation();
                });
            },
            editOperatoreCliente(item) {
                this.indexOperatoreCliente = this.operatoriCliente.indexOf(item);
                this.itemOperatoreCliente = JSON.parse(JSON.stringify(item));
                this.dialogOperatori = true;
                this.$nextTick(() => {
                    this.$refs.formOperatore.resetValidation();
                });
            },
            uppercaseCF() {
                this.cliente.codiceFiscale = this.cliente.codiceFiscale.toUpperCase();
                this.checkCF=true;
            },
            uppercaseCFLR() {
                this.cliente.codiceFiscaleLR = this.cliente.codiceFiscaleLR.toUpperCase();
                this.checkCF=true;
            },
            uppercaseSDI() {
                this.cliente.sdi = this.cliente.sdi.toUpperCase();
            },
            uppercaseIBAN() {
                this.cliente.iban = this.cliente.iban.toUpperCase();
            },
            uppercasePRV() {
                this.cliente.provinciaNascita = this.cliente.provinciaNascita.toUpperCase();
            },
            checkData(d) {
                return gCheckData(d);
            },
            localData(v) {
                return gLocalData(v);
            },
            serverData(stringDate) {                             
                return gServerData(stringDate);                
            },
            dataCalendar(stringDate) {                             
                return gDataCalendar(stringDate);                
            },            
            validate() {
                this.$refs.form1.validate()
                this.$refs.form2.validate()
                this.$refs.form3.validate()
                this.$refs.form4.validate()
                this.$refs.form5.validate()
                this.$refs.form6.validate()
                this.$refs.form7.validate()
            },
            reset() {
                this.$refs.form1.reset()
                this.$refs.form2.reset()
                this.$refs.form3.reset()
                this.$refs.form4.reset()
                this.$refs.form5.reset()
                this.$refs.form6.reset()
                this.$refs.form7.reset()
            },
            resetValidation() {
                this.$refs.form1.resetValidation()
                this.$refs.form2.resetValidation()
                this.$refs.form3.resetValidation()
                this.$refs.form4.resetValidation()
                this.$refs.form5.resetValidation()
                this.$refs.form6.resetValidation()
                this.$refs.form7.resetValidation()

            },
            coloraRiga(item) {
                if (!item.abilitato) return "style-lock";
                return "";
            },
            async annulla() {
                this.loadingAnnulla = true;
                await this.internalCreated(Snack.cancel);
                this.loadingAnnulla = false;
            },
            async onClienteChanged({ lista, sessionID }) {
                if (sessionID=="-1" || sessionID == this.$store.state.sessionID) return;

                if(lista.find(cod=>cod==this.cliente.id)) {
                    await this.internalCreated(Snack.remote);
                }
            },
            async expandContratto({ item, value }) {
                if (value) {
                    this.serviziContrattoEstesi = item.listaServiziContratto;
                    this.contrattoCorrente = item.contratto;
                }
            },
            async sendInvitoApp() {
                this.loading3 = true;
                //invia la mail al cliente corrente
                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    idCliente: this.cliente.id
                };

                const result = await callPost('api/broadcast/inviaInvitoApp', param);

                await this.internalCreated(result ? Snack.success : Snack.error);
                
                this.loading3 = false;
            },
            async resetPassword() {
                this.loading3 = true;
                //invia la mail al cliente corrente
                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    //sessionID: this.$store.state.sessionID,
                    idCliente: this.cliente.id
                };

                //alert(JSON.stringify(param));

                const result = await callPost('api/utente/resetPassword', param);

                await this.internalCreated(result ? Snack.success : Snack.error);

                this.loading3 = false;
            },
            eliminaContratto(item) {
                var i = this.listaContratti.indexOf(item);
                this.listaContratti.splice(i, 1);
            }, 
            eliminaServizioContratto(item) {
                var i = this.serviziContrattoEstesi.indexOf(item);
                this.serviziContrattoEstesi.splice(i, 1);
            },             
            async salva() {
                if (this.$refs["v-combobox-comuni"]) this.$refs["v-combobox-comuni"].blur();
                if (this.$refs["v-combobox-comuni-rl"]) this.$refs["v-combobox-comuni-rl"].blur();

                if (!this.viewTab) {
                    await this.internalCreated(Snack.error);
                    return;
                }

                this.$nextTick(async () => {

                    if (!this.$refs.form1.validate()) return;
                    if (!this.$refs.form2.validate()) return;
                    if (!this.$refs.form3.validate()) return;
                    if (!this.$refs.form4.validate()) return;
                    if (!this.$refs.form5.validate()) return;
                    if (!this.$refs.form6.validate()) return;


                    //cambio date//

                    let cliente = JSON.parse(JSON.stringify(this.cliente));
                    cliente.scadenzaPEC = this.serverData(cliente.scadenzaPEC);
                    cliente.scadenzaFirmaDigitale = this.serverData(cliente.scadenzaFirmaDigitale);
                    cliente.inizioAttivita = this.serverData(cliente.inizioAttivita);
                    cliente.scadenzaCaricheOrganoAmministrativo = this.serverData(cliente.scadenzaCaricheOrganoAmministrativo);
                    cliente.dataNascita = this.serverData(cliente.dataNascita);
                    cliente.dataAttoCostitutivo = this.serverData(cliente.dataAttoCostitutivo);
                    cliente.dataStatus = this.serverData(cliente.dataStatus);
                    cliente.dataRilascioDocumentoIdentificativo = this.serverData(cliente.dataRilascioDocumentoIdentificativo);
                    cliente.dataScadenzaDocumentoIdentificativo = this.serverData(cliente.dataScadenzaDocumentoIdentificativo);
                    cliente.tags = this.listaTags.join('-');

                    //tolgo le tariffe non abilitate
                    this.tariffeContabilitaCliente = this.tariffeContabilitaCliente.filter(t => t.abilitato);
                    this.tariffeLavoroCliente = this.tariffeLavoroCliente.filter(t => t.abilitato);
                    this.tariffePuliziaCliente = this.tariffePuliziaCliente.filter(t => t.abilitato);
                    this.tariffeManutenzioneCliente = this.tariffeManutenzioneCliente.filter(t => t.abilitato);

                    for (let cIdx = 0; cIdx < this.listaContratti.length; cIdx++) {
                        if (this.listaContratti[cIdx].listaServiziContratto) {
                            for (let csIdx = 0; csIdx < this.listaContratti[cIdx].listaServiziContratto.length; csIdx++) {
                                if(this.listaContratti[cIdx].listaServiziContratto[csIdx].dettaglio) {
                                    this.listaContratti[cIdx].listaServiziContratto[csIdx].dettaglio = this.listaContratti[cIdx].listaServiziContratto[csIdx].dettaglio.join('-');
                                }
                            }
                        }
                    }

                    console.log(JSON.stringify(cliente));

                    let paramJson = {
                        partition: this.$store.state.partition,
                        username: this.$store.state.utente.username,
                        token: this.$store.state.utente.token,
                        sessionID: this.$store.state.sessionID,
                        cliente: cliente,
                        recapitiCliente: this.nuovo ? [] : this.recapitiCliente,
                        indirizzarioCliente: this.nuovo ? [] : this.indirizzarioCliente,
                        operatoriCliente: this.nuovo ? [] : this.operatoriCliente,
                        tariffeContabilitaCliente: this.nuovo ? [] :this.tariffeContabilitaCliente,
                        tariffeLavoroCliente: this.nuovo ? [] : this.tariffeLavoroCliente,
                        //tariffePuliziaCliente: this.nuovo ? [] : this.tariffePuliziaCliente,
                        //tariffeManutenzioneCliente: this.nuovo ? [] : this.tariffeManutenzioneCliente,
                        allegatiCliente: this.nuovo ? [] : this.allegatiCliente,
                        contrattiCliente: this.nuovo ? [] : this.listaContratti,
                        utente: this.nuovo ? null : this.utente,
                        status: this.rstatus,
                        idAnnoContabile: this.$store.state.idAnnoContabile
                    };
                                            
                    this.loadingSalva = true;

                    let cli = await callPost('api/cliente', paramJson);
                    if (cli) {
                        this.cliente.id = cli.id;
                        this.nuovo = false;
                        //alert("save");
                        bus.$emit('cliente-changed', { lista: [this.cliente.id], sessionID: "-1" });
                    }

                    await this.internalCreated(cli ? Snack.success : Snack.error);

                    this.loadingSalva = false;
                });
            },
            async internalCreated(snack) {
                
                let theme = this.$cookie.get('theme') == "true"; //trasformo da string a bool
                this.theme = theme;
                
                this.expanded=[];
                this.viewTab = false;
                this.tabPrec = this.tab;
                this.usernamepresente = false;
                let param=[];
                this.listaTipoRecapito = this.$store.state.listaTipoRecapito;
                this.listaTipoCliente = this.$store.state.listaTipoCliente;
                this.listaTipoRegimeIVA = this.$store.state.listaTipoRegimeIVA;
                this.listaTipoRegimeContabile = this.$store.state.listaTipoRegimeContabile;
                this.listaComuni =this.$store.state.listaComuni;
                this.listaTipoIndirizzario = this.$store.state.listaTipoIndirizzario;
                this.listaTipoAttivita = this.$store.state.listaTipoAttivita;
                this.listaOperatore = this.$store.state.listaOperatore;
                this.listaOperatoriProfessionisti=this.$store.state.listaOperatoriProfessionisti;
                this.listaPeriodicitaContabile = this.$store.state.listaPeriodicitaContabile;
                this.listaPeriodicitaLavoro = this.$store.state.listaPeriodicitaLavoro;
                this.listaPeriodicitaServizi = this.$store.state.listaPeriodicitaServizi;
                this.listaTipiDocumentoIndentificativo = this.$store.state.listaTipiDocumentoIndentificativo;
                this.listaPeriodi = await callService("api/contratto/listaperiodi", param);
                this.listaDettaglioSettimana = await callService("api/contratto/listadettagliosettimana", param);
                this.listaDettaglioMese = await callService("api/contratto/listadettagliomese", param);
                this.listaServCon = await callService('api/pratica/listaservcon', param);
                this.listaTagsIni = await callService("api/tags", param);
                this.importoFido = '€ 0,00';
                param=[this.cliente.id];
                let cliente = await callService("api/cliente",param);
                this.allegatiOpen = false;


                if (!this.nuovo) {
                    param = [this.cliente.id];
                    this.recapitiCliente = await callService("api/recapitocliente", param);
                    this.indirizzarioCliente = await callService("api/indirizzariocliente", param);
                    this.operatoriCliente = await callService("api/operatorecliente", param);
                    this.utente = await callService("api/utente/utenteCliente", param);
                    this.allegatiCliente = await callService("api/allegatocliente", param);
                    this.allegatiOpen = this.allegatiCliente.length > 0;
                                     
                    if (this.utente.username != null) this.usernamepresente = true;

                    param = [this.cliente.id, this.$store.state.idAnnoContabile];
                    this.tariffeContabilitaCliente = await callService("api/tariffacliente/tariffecontabilita", param);
                    this.tariffeContabilitaCliente = this.tariffeContabilitaCliente.map(v => ({ ...v, abilitato: v.importo > 0 }))
                    this.tariffeLavoroCliente = await callService("api/tariffacliente/tariffelavoro", param);
                    this.tariffeLavoroCliente = this.tariffeLavoroCliente.map(v => ({ ...v, abilitato: v.importo > 0 }))
                    //this.tariffePuliziaCliente = await callService("api/tariffacliente/tariffepulizia", param);
                    //this.tariffePuliziaCliente = this.tariffePuliziaCliente.map(v => ({ ...v, abilitato: v.importo > 0 }))
                    //this.tariffeManutenzioneCliente = await callService("api/tariffacliente/tariffemanutenzione", param);
                    //this.tariffeManutenzioneCliente = this.tariffeManutenzioneCliente.map(v => ({ ...v, abilitato: v.importo > 0 }))
                    this.listaContratti = await callService("api/contratto/contratti", param);

                    for(let cIdx=0;cIdx<this.listaContratti.length;cIdx++) {
                        this.listaContratti[cIdx].contratto.dataInizio = this.dataCalendar(this.listaContratti[cIdx].contratto.dataInizio);
                        this.listaContratti[cIdx].contratto.dataFine = this.dataCalendar(this.listaContratti[cIdx].contratto.dataFine);
                        for(let csIdx=0;csIdx<this.listaContratti[cIdx].listaServiziContratto.length;csIdx++) {
                            this.listaContratti[cIdx].listaServiziContratto[csIdx].servizioContratto.dataInizio = this.dataCalendar(this.listaContratti[cIdx].listaServiziContratto[csIdx].servizioContratto.dataInizio);
                            this.listaContratti[cIdx].listaServiziContratto[csIdx].servizioContratto.dataFine = this.dataCalendar(this.listaContratti[cIdx].listaServiziContratto[csIdx].servizioContratto.dataFine);
                            this.listaContratti[cIdx].listaServiziContratto[csIdx].dettaglio = this.listaContratti[cIdx].listaServiziContratto[csIdx].dettaglio ? this.listaContratti[cIdx].listaServiziContratto[csIdx].dettaglio.split('-') : [];
                        }
                    }
                    this.listaCanoni = await callService("api/tariffacliente/tariffefido", param);
                }   

                this.listaCliente = this.$store.state.clientiEstesi;

                this.isConsulenzaContabile = this.tariffeContabilitaCliente.length>0;
                if(this.isConsulenzaContabile) {
                    this.idPeriodicitaContabile = this.tariffeContabilitaCliente[0].tipoServizio.tipoPeriodicita.ordinamento;
                }
            
                this.isConsulenzaLavoro = this.tariffeLavoroCliente.length > 0;
                if (this.isConsulenzaLavoro) {
                    this.idPeriodicitaLavoro = this.tariffeLavoroCliente[0].tipoServizio.tipoPeriodicita.ordinamento;
                }   

                this.isConsulenzaPulizia = this.tariffePuliziaCliente.length > 0;
                if (this.isConsulenzaPulizia) {
                    this.idPeriodicitaPulizia = this.tariffePuliziaCliente[0].tipoServizio.tipoPeriodicita.ordinamento;
                }

          
                cliente.scadenzaPEC = this.localData(cliente.scadenzaPEC);
                cliente.scadenzaFirmaDigitale = this.localData(cliente.scadenzaFirmaDigitale);
                cliente.inizioAttivita = this.localData(cliente.inizioAttivita);
                cliente.scadenzaCaricheOrganoAmministrativo = this.localData(cliente.scadenzaCaricheOrganoAmministrativo);
                cliente.dataNascita = this.localData(cliente.dataNascita);
                cliente.dataAttoCostitutivo = this.localData(cliente.dataAttoCostitutivo);
                cliente.dataStatus = this.localData(cliente.dataStatus);
                cliente.dataRilascioDocumentoIdentificativo = this.localData(cliente.dataRilascioDocumentoIdentificativo);
                cliente.dataScadenzaDocumentoIdentificativo = this.localData(cliente.dataScadenzaDocumentoIdentificativo);


                if (!cliente.tipoRegimeIVA) cliente.tipoRegimeIVA = { id: '' };
                if (!cliente.tipoRegimeContabile) cliente.tipoRegimeContabile = { id: '' };
                if (!cliente.tariffaMensilitaFido) cliente.tariffaMensilitaFido = { id: '' };
                this.listaTags=cliente.tags ? cliente.tags.split('-') : [];
                if (this.isContabilita || this.isLavoro) {
                    this.setTipoCliente(cliente.tipoCliente.id);
                }
                else {
                    this.setTipoClienteServicePro(cliente.tipoCliente.id);
                }
                this.rstatus = "0";
                if (cliente.isAddebitiSospesi) this.rstatus = "1";
                if (cliente.isSospeso) this.rstatus = "2";
                if (cliente.isCancellato) this.rstatus = "3";
                cliente.isContatto = (this.nuovo && this.rubrica) ? true : (this.nuovo && !this.rubrica) ? false : cliente.isContatto;


                //copio adesso sul cliente di this per non avere sfarfallamenti

                this.cliente = cliente;

                if (this.isServicePro && this.nuovo) {
                    //this.cliente.professionista.id = this.$store.state.utente.operatore.professionista.id;
                    this.cliente.professionista.id = this.listaOperatoriProfessionisti[0].professionista.id;
                    //alert(this.cliente.professionista.id);
                }

                this.snackSuccess = (snack == Snack.success);
                this.snackError = (snack == Snack.error);
                this.snackCancel = (snack == Snack.cancel);
                this.snackRemote = (snack == Snack.remote);

                this.$nextTick(() => {                    
                    this.viewTab = true;
                    this.checkSave = false;
                    this.tab = this.tabPrec;
                });
            },
            chiudi() {
                if(!this.checkSave || !this.viewTab) {
                    this.cliente = {
                        id:'',
                        tipoCliente: {
                            id:''
                        },
                        tipoRegimeIVA: {
                            id: ''
                        },
                        tipoRegimeContabile: {
                            id: ''
                        },
                        codice: '',
                        denominazione: '',
                        codiceFiscale: '',
                        partitaIVA: '',
                        pec:'',
                        scadenzaPEC: null,
                        sdi:'',
                        professionista: {
                            id:''
                        },
                        tariffaMensilitaFido: {
                            id:''
                        }
                    };
                    this.dialog=false;     
                }
                else  {
                    bus.$emit('msg-open', { message: "Sono state rilevate delle modifiche.", item: this.cliente, okTxt:"Rimani sull'anagrafica cliente",cancelTxt:"Chiudi senza salvare"});                
                    bus.$on('msg-cancel', this.esci); 
                    bus.$on('msg-ok', this.rimani);
                }
            },
            async rimani(item) {
                bus.$off('msg-cancel', this.esci);
                bus.$off('msg-ok', this.rimani);
            },
            async esci(item) {
                bus.$off('msg-cancel', this.esci);
                bus.$off('msg-ok', this.rimani);
                this.cliente = {
                        id:'',
                        tipoCliente: {
                            id:''
                        },
                        tipoRegimeIVA: {
                            id: ''
                        },
                        tipoRegimeContabile: {
                            id: ''
                        },
                        codice: '',
                        denominazione: '',
                        codiceFiscale: '',
                        partitaIVA: '',
                        pec:'',
                        scadenzaPEC: null,
                        sdi:'',
                        professionista: {
                            id:''
                        },
                        tariffaMensilitaFido: {
                            id:''
                        }
                    };
                    this.dialog=false; 
            }
        },
        computed: {
            admin(){
                return this.$store.state.admin;
            },
            isLavoro() {
                return this.$store.state.lplApp && this.$store.state.lplApp.isLavoro;
            },
            isContabilita(){
                return this.$store.state.lplApp && this.$store.state.lplApp.isContabilita;
            },
            isPulizia() {
                return this.$store.state.lplApp && this.$store.state.lplApp.isPulizia;
            },
            isManutenzione() {
                return this.$store.state.lplApp && this.$store.state.lplApp.isManutenzione;
            },
            isChiamata() {
                return this.$store.state.lplApp && this.$store.state.lplApp.isChiamata;
            },
            isServicePro() {
                return this.$store.state.lplApp && (this.$store.state.lplApp.isPulizia || this.$store.state.lplApp.isChiamata || this.$store.state.lplApp.isManutenzione);
            },
            isStudioPro() {
                return this.$store.state.lplApp && (this.$store.state.lplApp.isLavoro || this.$store.state.lplApp.isContabilita);
            },
            isStudioProLight() {
                return this.$store.state.parametri && this.$store.state.parametri.isStudioProLight;
            },
            
        },
        watch:{
            cliente: {
              handler() {
                this.checkSave = true;               
              },
              deep: true,
            },
            recapitiCliente: {
              handler() {
                this.checkSave = true;               
              },
              deep: true,
            },
            indirizzarioCliente: {
              handler() {
                this.checkSave = true;               
              },
              deep: true,
            },
            operatoriCliente: {
              handler() {
                this.checkSave = true;               
              },
              deep: true,
            },
            tariffeContabilitaCliente: {
              handler() {
                this.checkSave = true;               
              },
              deep: true,
            },
            tariffeLavoroCliente: {
              handler() {
                this.checkSave = true;               
              },
              deep: true,
            },
            tariffePuliziaCliente: {
                handler() {
                    this.checkSave = true;
                },
                deep: true,
            },
            tariffeManutenzioneCliente: {
                handler() {
                    this.checkSave = true;
                },
                deep: true,
            },
            allegatiCliente: {
                handler() {
                    this.checkSave = true;
                },
                deep: true,
            },  
            utente : {
              handler() {
                this.checkSave = true;               
              },
              deep: true,
            },
            listaTags: {
              handler() {
                this.checkSave = true;               
              },
              deep: true,
            }
        },
        async created() {
            let theme = this.$cookie.get('theme') == "true"; //trasformo da string a bool
            this.theme = theme;
            this.$updaterHub.$on('cliente-changed', this.onClienteChanged);            
            var vm = this;

            bus.$on('dialog-show-cliente', async function (cliente, isRubrica) {

                if(vm.$refs.form) vm.$refs.form.reset();

                vm.checkCF = true;
                vm.rubrica = isRubrica;
                vm.dialog = true;
                vm.loading = true;
                vm.nuovo = cliente.id == "0";
                vm.cliente.id = cliente.id;
                vm.tab=0;
                await vm.internalCreated(Snack.nothing);
                vm.loading = false;
            });      
        },
        beforeDestroy() {
            this.$updaterHub.$off('cliente-changed', this.onClienteChanged);
            bus.$off('dialog-show-cliente');
        }
    }

</script>

<style>
    .style-lock {
        color: grey
    }
</style>
