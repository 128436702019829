<template>
    
    <v-dialog v-model="dialog" persistent max-width="1600px">

        <v-card :loading="loading">
            <v-card-text>
                <v-form ref="form" :disabled="pratica.isChiusa"
                        v-model="valid"
                        lazy-validation>
                    <v-tabs>
                        <v-tab>{{headerDialog}}</v-tab>
                        <v-tab v-if="!nuovo">Documenti</v-tab>
                        <v-tab-item>
                            <v-row class="mt-4">
                                <v-col cols="4">
                                    <v-row>
                                        <v-col>
                                            <v-select v-model="pratica.tipoPratica.id"
                                                      :items="tipiPraticaEstesi"
                                                      item-value="tipoPratica.id"
                                                      item-text="tipoPratica.descrizione"
                                                      label="Tipo Pratica"
                                                      :rules="[v=> !!v || 'indicare il tipo di pratica']"
                                                      :disabled="!this.nuovo"
                                                      outlined
                                                      @change="changeTipoPratica"
                                                      dense>
                                            </v-select>
                                        </v-col>      
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-autocomplete v-model="pratica.cliente.id"
                                                            :items="listaCliente"
                                                            item-value="cliente.id"
                                                            item-text="cliente.denominazioneNormalizzata"
                                                            label="Cliente"
                                                            :rules="[v=> !!v || 'indicare il cliente']"
                                                            :disabled="!this.nuovo"
                                                            outlined
                                                            clearable
                                                            dense></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="6">
                                    <v-row>
                                        <v-col cols="3">
                                            <v-select v-model="pratica.operatoreCreazione.id"
                                                      :items="listaOperatore"
                                                      item-value="id"
                                                      item-text="denominazioneNormalizzata"
                                                      label="Pratica Richiesta da"
                                                      :rules="[v=> !!v || 'indicare un operatore']"
                                                      :disabled="!this.nuovo || !$store.state.utente.isReception"
                                                      outlined
                                                      dense></v-select>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-text-field v-model="pratica.numeroPratica"
                                                          label="Numero Pratica"
                                                          outlined
                                                          clearable
                                                          dense></v-text-field>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-text-field v-model="pratica.dataInizio"
                                                          label="Data Inizio"
                                                          outlined
                                                          :rules="[v=> (!v || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v))) || 'gg/mm/aaaa']"
                                                          hint="gg/mm/aaaa"
                                                          persistent-hint
                                                          clearable
                                                          dense></v-text-field>

                                        </v-col>
                                        <v-col cols="3">
                                            <v-text-field v-model="pratica.dataScadenza"
                                                          label="Data Scadenza"
                                                          outlined
                                                          :rules="[v=> (!!v && v.length>0 && (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v))) || 'gg/mm/aaaa']"
                                                          hint="gg/mm/aaaa"
                                                          persistent-hint
                                                          clearable
                                                          dense></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field v-model="pratica.descrizione"
                                                          label="Descrizione Pratica"
                                                          outlined
                                                          :rules="[v=> (!!v && v.length>0) || 'inserire una descrizione']"
                                                          :hint="addebito ? 'pratica addebitata il '+  localData(addebito.data):'pratica non addebidata' "
                                                          persistent-hint
                                                          dense>
                                            </v-text-field>
                                        </v-col>
                                        <v-col>
                                            <vuetify-money v-model="pratica.importo"
                                                           label="Importo Pratica"
                                                           outlined
                                                           :disabled="addebito!=null"
                                                           :background-color="theme ? 'gray':'white'"
                                                           dense
                                                           :rules="[v=> !!v || 'inserire importo']"                                                           
                                                           :options="moneyOptions">
                                            </vuetify-money>
                                        </v-col>
                                        <v-col>
                                            <v-btn :dark ="!pratica.isChiusa && $store.state.annoAperto"
                                                   :disabled="pratica.isChiusa || !$store.state.annoAperto" 
                                                   v-if="!addebito" @click="inserisciAddebito(false)" color="red darken-3" class="ml-2">
                                                Addebita
                                            </v-btn>
                                            <v-btn :disabled="pratica.isChiusa"
                                                   :dark ="!pratica.isChiusa"
                                                   v-if="addebito && (($store.state.utente && $store.state.utente.operatore && $store.state.utente.operatore.professionista && pratica.cliente.professionista && $store.state.utente.operatore.professionista.id == pratica.cliente.professionista.id) || $store.state.utente.isGestoreStudio)" @click="eliminaAddebito" color="red darken-3"
                                                   class="ml-2">
                                                Elimina Addebito
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="2">
                                    <v-textarea v-model="pratica.note"
                                                outlined
                                                rows="4"
                                                hide-details
                                                label="Note Pratica">
                                    </v-textarea>
                                </v-col>
                            </v-row>
                            <v-row class="mt-n6">
                                <v-col>
                                    <v-card v-if="false && !$store.state.conf.isService" class="mx-2"><!--oscurato per il momento-->
                                        <vue-mermaid v-if="!loading" :nodes="diagram"
                                                     :type="type"
                                                     :config="{ startOnLoad:true, securityLevel: 'loose', flowchart:{ useMaxHeigth:true, useMaxWidth:true, htmlLabels:false, cloneCssStyles: false } }">
                                        </vue-mermaid>
                                    </v-card>
                                    <v-data-table :headers="$store.state.conf.isService ? headersService : headers"
                                                  :items="listaTask"
                                                  ref="tabella"
                                                  :loading="loading"
                                                  :items-per-page="8"
                                                  class="elevation-1 ma-2"
                                                  :footer-props="{
                                                        'items-per-page-text':'Pagina da',
                                                        'items-per-page-all-text': '',
                                                        'items-per-page-options':[8]
                                                      }"
                                                  loading-text="Caricamento dati..."
                                                  no-data-text="Nessun task presente in archivio"
                                                  no-results-text="Nessuna task corrispodende ai criteri di ricerca">
                                        <template slot="header.operazione">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-bind="attrs" color="info" class="ml-2"
                                                           v-on="on"
                                                           icon @click="newTask">
                                                        <v-icon>mdi-folder-plus</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>
                                                    Aggiungi Task
                                                </span>
                                            </v-tooltip>
                                        </template>
                                        <template v-slot:item.descrizione="{ item }">
                                            <v-row>
                                                <v-icon v-if="item.isFinale" color="blue">mdi-arrow-right-bottom</v-icon>
                                                <v-text-field v-model="item.descrizione"
                                                              outlined
                                                              dense
                                                              :rules="[v=> !!v || '']"
                                                              hide-details>
                                                </v-text-field>
                                            </v-row>                                            
                                        </template>
                                        <template v-slot:item.dataPresaInCarico="{ item }">
                                            <span class="text-subtitle" v-text="localDateTime(item.dataPresaInCarico)" />

                                            <a v-if="item.coordinatePiC" target="_blank"
                                               style="text-decoration: none;"
                                               :href="'https://maps.google.it/?q='+item.coordinatePiC">
                                                <v-icon color="brown">mdi-map-marker</v-icon>
                                            </a>
                                        </template>
                                        <template v-slot:item.operatore="{ item }">
                                            <v-select v-model="item.operatore.id"
                                                      :items="listaOperatore"
                                                      hide-details
                                                      item-value="id"
                                                      clearable
                                                      item-text="denominazioneNormalizzata"
                                                      outlined
                                                      @change="changeOperatore(item)"
                                                      dense>
                                                      <template v-slot:item="data">
                                                        <template>
                                                            <v-list-item-content>
                                                                <v-list-item-title :class="'text-subtitle-2 font-weight-medium '+(data.item.isCancellato ? 'red--text':'')">{{data.item.denominazioneNormalizzata}}</v-list-item-title>
                                                            </v-list-item-content>
                                                        </template>
                                                    </template>
                                            </v-select>
                                        </template>
                                        <template v-slot:item.note="{item}">
                                            <v-row dense>
                                                <span class="mt-2">{{tail(item.note,20)}}</span>
                                                <v-edit-dialog>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn v-bind="attrs"
                                                                   v-on="on"
                                                                   icon>
                                                                <v-icon v-if="!item.note">mdi-note-plus-outline</v-icon>
                                                                <v-icon v-else color="teal">mdi-note-search</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span v-if="!item.note">
                                                            Aggiungi Note
                                                        </span>
                                                        <span v-else>
                                                            Visualizza Note
                                                        </span>
                                                    </v-tooltip>
                                                    <template v-slot:input>
                                                        <v-textarea label="Note" class="mt-2 mb-n2" dense outlined v-model="item.note">

                                                        </v-textarea>
                                                    </template>
                                                </v-edit-dialog>
                                            </v-row>

                                        </template>
                                        <template v-slot:item.check="{ item }">
                                            <v-icon v-if="item.dataEsecuzione" color="teal" small>mdi-check-bold</v-icon>
                                        </template>
                                        <template v-slot:item.order="{ item }">
                                            <v-icon class="ma-0" @click="up(item)" color="blue">mdi-arrow-up-bold-box</v-icon>
                                            <v-icon class="ma-0" @click="down(item)" color="blue">mdi-arrow-down-bold-box</v-icon>
                                            <v-icon class="ma-0" @click="item.isFinale=false" color="blue">mdi-arrow-left-bold-box</v-icon>
                                            <v-icon class="ma-0" @click="item.isFinale=true" color="blue">mdi-arrow-right-bold-box</v-icon>
                                        </template>
                                        <template v-slot:item.dataEsecuzione="{ item }">
                                            <v-btn :disabled="pratica.isChiusa" v-if="item.dataPresaInCarico && !item.dataEsecuzione" @click="completa(item)" color="blue darken-2" dark class="ml-2">
                                                Completa
                                            </v-btn>
                                            <div v-else>
                                                <span class="text-subtitle" v-text="localDateTime(item.dataEsecuzione)" />

                                                <a v-if="item.coordinateEs" target="_blank"
                                                   style="text-decoration: none;"
                                                   :href="'https://maps.google.it/?q='+item.coordinateEs">
                                                    <v-icon color="brown">mdi-map-marker</v-icon>
                                                </a>
                                            </div>
                                        </template>
                                        <template v-slot:item.completato="{ item }">
                                            <v-tooltip v-if="item.dataEsecuzione" bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn :disabled="pratica.isChiusa"
                                                           v-bind="attrs"
                                                           v-on="on"
                                                           icon @click="annullaCompleta(item)"
                                                           color="red darken-3" class="ml-2">
                                                        <v-icon>mdi-close-thick</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>
                                                    Annulla Completamento
                                                </span>
                                            </v-tooltip>

                                        </template>
                                        <template v-slot:item.operazione="{ item }">
                                            <v-tooltip bottom v-if="($store.state.utente.operatore) || ($store.state.utente && $store.state.utente.isGestoreStudio)">
                                                <!--<v-tooltip bottom v-if="($store.state.utente.operatore && $store.state.utente.operatore.professionista && pratica.cliente.professionista && $store.state.utente.operatore.professionista.id == pratica.cliente.professionista.id) || ($store.state.utente && $store.state.utente.isGestoreStudio)">-->
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn :disabled="pratica.isChiusa"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            icon @click="eliminaTask(item)"
                                                            color="red darken-3" class="ml-2">
                                                        <v-icon color="error darken-2">mdi-trash-can-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>
                                                    Elimina Task
                                                </span>
                                            </v-tooltip>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-tab-item>

                        <v-tab-item v-if="!nuovo">
                            <br />
                            <v-row>
                                <v-col cols="8">
                                    <v-data-table class="elevation-1 ma-2"
                                                  v-model="selectedItem"
                                                  single-select
                                                  item-key="id"
                                                  :loading="loadingDoc"
                                                  :headers="headersAllegati"
                                                  :items="allegatiCliente"
                                                  :footer-props="{
                                                        'items-per-page-text':'Pagina da',
                                                        'items-per-page-all-text': '',
                                                        'items-per-page-options':[8]
                                                      }"
                                                  @click:row="selezionaRiga"
                                                  loading-text="Caricamento dati..."
                                                  no-data-text="Nessun allegato presente">
                                        <template slot="body.append">
                                            <tr>
                                                <th class="title"></th>
                                                <th class="title"></th>
                                                <th class="title"></th>
                                                <th class="title"></th>
                                                <th class="title"></th>
                                                <th class="title">
                                                    <v-icon class="mr-2"
                                                            @click="newAllegato"
                                                            color="success darken-2">
                                                        mdi-file-document-plus-outline
                                                    </v-icon>
                                                </th>
                                            </tr>
                                        </template>
                                        <template v-slot:item.dataIns="{ item }">
                                            <span v-text="localData(item.dataIns)" />
                                        </template>
                                        <template v-slot:item.isGenerale="{ item }">
                                            <v-checkbox class="mt-2 mb-n3" v-model="item.isGenerale" />
                                        </template>
                                        <template v-slot:item.note="{ item }">
                                            <v-tooltip slot="append" bottom>
                                                <template v-slot:activator="{ on }">                                                    
                                                    <v-text-field v-on="on" v-model="item.note"
                                                          class="mb-n6"
                                                          outlined
                                                          dense />
                                                </template>
                                                <span>{{item.allegatoName}}</span>
                                            </v-tooltip>
                                        </template>
                                        <template v-slot:item.apri="{ item }">
                                            <v-icon @click="viewAllegato(item)" color="info darken-2">mdi-magnify</v-icon>
                                        </template>
                                        <template v-slot:item.scarica="{ item }">
                                            <v-icon @click="downloadAllegato(item)" color="info darken-2">mdi-download</v-icon>
                                        </template>
                                        <template v-slot:item.azioni="{ item }">
                                            <v-icon @click="eliminaAllegato(item)" color="error darken-2">mdi-trash-can-outline</v-icon>
                                        </template>
                                    </v-data-table>
                                </v-col>
                                <v-col cols="3">
                                    <v-card v-if="allegatiOpen" :loading="loadingPdf">
                                        <v-carousel v-model="carouselId"
                                                    show-arrows                                                    
                                                    @change="changeCarusel">
                                            <v-carousel-item v-for="(item) in allegatiCliente" 
                                                             :key="item.id"
                                                             target="_blank"
                                                             :href="item.allegatoPath ? item.allegatoPath : 'pdf/blank.pdf'">
                                                <pdf @progress="progress" v-if="item.isPdf" :src="item.allegatoPath"></pdf>
                                                <pdf @progress="progress" v-if="item.isBlank" :src="'pdf/blank.pdf'"></pdf>
                                                <pdf @progress="progress" v-if="item.isNotPreview" :src="'pdf/notpreview.pdf'"></pdf>
                                                <v-img v-if="item.isImage" :src="item.allegatoPath" contain max-height="300"></v-img>
                                            </v-carousel-item>
                                        </v-carousel>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <br />
                        </v-tab-item>


                        <v-spacer></v-spacer>
                        <v-btn class="mt-2 mr-2" color="teal" :dark="!(!valid || pratica.isChiusa)"
                               @click="salva"
                               :loading="loadingSalva"
                               :disabled="!valid || pratica.isChiusa">
                            Salva
                        </v-btn>
                        <v-btn class="mt-2 mr-2" color="teal" :dark="!(!valid || pratica.isChiusa)"
                               @click="salvaModello"
                               :loading="loadingSalva"
                               :disabled="!valid || pratica.isChiusa">
                            Salva come nuovo modello
                        </v-btn>
                        <v-btn class="mt-2 mr-2" color="orange darken-2" 
                               :loading="loadingAnnulla"
                               :disabled="pratica.isChiusa"
                               :dark ="!pratica.isChiusa"
                               @click="annulla">
                            Annulla modifiche
                        </v-btn>
                        <v-btn class="mt-2 mr-2"
                               color="blue darken-2" dark
                               @click.stop="chiudi">
                            Esci
                        </v-btn>
                    </v-tabs>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn v-if="!nuovo && pratica.isChiusa" class="mt-2 mr-2"
                       color="red darken-3" dark
                       @click="riapriPratica">
                    Riapri Pratica
                </v-btn>
                <v-btn v-if="!nuovo && !pratica.isChiusa" class="mt-2 mr-2"
                       color="red darken-3" dark
                       @click="chiudiPratica">
                    Imposta Pratica come Chiusa
                </v-btn>
                <v-btn v-if="!nuovo && !addebito && (($store.state.utente && $store.state.utente.operatore && $store.state.utente.operatore.professionista && pratica.cliente.professionista && $store.state.utente.operatore.professionista.id == pratica.cliente.professionista.id) || ($store.state.utente && $store.state.utente.isGestoreStudio))"  class="mt-2 mr-2"
                       color="red darken-3" dark
                       @click="eliminaPratica">
                    Elimina Pratica
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-snackbar v-model="snackSuccess" centered
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackError" centered
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel" centered
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote" centered
                    timeout="2000"
                    color="info">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento remoto.
        </v-snackbar>
        <v-file-input v-model="fileDocumento"
                      prepend-icon=""
                      id="idFileDocumentoPratica"
                      label="File Documento"
                      outlined
                      hide-input
                      type="file"
                      @change="uploadPratica"
                      dense>
        </v-file-input>
    </v-dialog>
</template>
<script>

    import { callService, callPost, callPostMultipart,Snack, euro, gLocalData, gLocalDateTime, gCheckData, gServerData } from '@/modules/utilities.js'
    import pdf from 'vue-pdf'
    import { bus } from '@/main.js'

    export default {
        components: {
            pdf
        },
        data: () => ({
            dialog: false,
            theme: false,
            valid: true,
            nuovo: false,
            carouselId: null,
            fileDocumento:null,
            allegatiOpen: false,
            checkSave: false,
            selectedItem: [],
            allegatiCliente: [],
            snackSuccess: false,
            snackError: false,
            snackCancel: false,
            snackRemote: false,
            dialogDelete: false,
            loading: false,
            loadingDoc: false,
            loadingPdf: false,
            loadingSalva: false,
            loadingAnnulla: false,
            listaOperatore: [],
            listaCliente: [],
            listaTag: [],
            listaTagIni: [
                {tag:'tag1'},
                {tag:'tag2'},
            ],
            labelInput:'',
            headers: [
                { text: '', value: 'order', sortable:false },
                { text: '', value: 'check' },
                { text: 'Task', value: 'descrizione' },
                { text: 'Operatore Assegnato', value: 'operatore',width:'20%' },
                { text: 'Note', value: 'note', align:'left' },                   
                { text: 'Data Presa in Carico', value: 'dataPresaInCarico' },
                { text: 'Data Completamento', value: 'dataEsecuzione' },
                { text: '', value: 'completato',sortable: false }, 
                { text: '', value: 'operazione', sortable: false }, 
             ],
            headersService: [
                { text: '', value: 'order', sortable: false },
                { text: '', value: 'check' },
                { text: 'Task', value: 'descrizione' },
                { text: 'Operatore Assegnato', value: 'operatore',width:'20%' },
                { text: 'Note', value: 'note', align:'left' },                   
                { text: 'Inizio Attività', value: 'dataPresaInCarico' },
                { text: 'Fine Attività', value: 'dataEsecuzione' },
                { text: '', value: 'completato',sortable: false }, 
                { text: '', value: 'operazione', sortable: false }, 
             ], 
            headersAllegati: [
                { text: '', value: 'apri', sortable: false },
                { text: '', value: 'scarica', sortable: false },
                { text: 'Data Aggiornamento', value: 'dataIns' },
                { text: 'Note', value: 'note' },
                { text: 'Visibile anche in anagrafica', value: 'isGenerale' },
                { text: '', value: 'azioni', sortable: false }
            ],
            listaTask:[],            
            moneyOptions: {
                locale: "it-IT",
                prefix: "€",
                suffix: "",
                length: 10,
                precision: 2
            },
            pratica: {
                id:'',
                operatore: {
                    id:''
                },
                operatoreCreazione: {
                    id: ''
                },
                cliente: {
                    id: ''
                },
                tipoPratica: {
                    id:''
                }
            },
            addebito: null,
            tipiPraticaEstesi: [],
            diagram: [],
            old_diagrams: [
                {
                    id: "1",
                    text: "Registrazione Cliente in Studio Pro",
                    edgeType: "round",
                    next: ["2"],
                    editable: true,
                    
                },
                { id: "2", text: "Recupero Documenti", edgeType: "round", next: ["3"], editable: true},
                { id: "3", text: "Pratica Completata", edgeType: "round", editable: true,
                    style: "fill:teal,color:white,stroke:#333,stroke-width:4px", },
            ],
            config: {
                theme: 'default'
            },
            type: ''
        }),
        mounted() {
            if (!this.$store.state.logged) {
                this.$router.replace({ name: "login" }).catch(()=>{});
            }            
        },
        computed: {
            headerDialog() {
                if(this.nuovo) return "Nuova Pratica";
                return "Gestione Pratica";
            },
        },
        methods: {
            progress(val) {
                this.loadingPdf=(val<1)
            },
            down(item) {
                let idx = this.listaTask.indexOf(item);
                if (idx == this.listaTask.length - 1) return;
                let temp = JSON.parse(JSON.stringify(item));
                this.listaTask.splice(idx, 1, this.listaTask[idx+1]);
                this.listaTask.splice(idx + 1, 1, temp);

            },
            up(item) {
                let idx = this.listaTask.indexOf(item);
                if (idx == 0) return;
                let temp = JSON.parse(JSON.stringify(item));
                this.listaTask.splice(idx, 1, this.listaTask[idx - 1]);
                this.listaTask.splice(idx - 1, 1, temp);

            },
            tail(s,n) {
                if(!s) return "";
                if(s.length<n) return s;
                return s.substring(0,n-3)+"...";
            },            
            importoEuro(v) {   
                return euro(v);
            },
            newAllegato(item) {
                let fileDocumento = document.getElementById('idFileDocumentoPratica')
                fileDocumento.click();
            },
            async uploadPratica() {
                this.loadingDoc=true;
                const formData = new FormData();
                formData.append('partition', this.$store.state.partition);
                formData.append('username', this.$store.state.utente.username);
                formData.append('token', this.$store.state.utente.token);
                formData.append('idCliente', this.pratica.cliente.id);
                formData.append('fileDocumento', this.fileDocumento);
                formData.append('isGenerale', false);
                formData.append('idPratica', this.pratica.id);

                const esito = await callPostMultipart('api/allegatoCliente/upload', formData);
                this.loadingDoc=false;
                let checkSave=this.checkSave;
                if (esito) this.snackSuccess = true;
                else this.snackError = true;

                if (esito) {
                    let param=[this.pratica.cliente.id,this.pratica.id]
                    this.allegatiCliente = await callService("api/allegatocliente", param);
                    this.allegatiOpen = this.allegatiCliente.length>0;
                }
                this.fileDocumento = null;

                this.$nextTick(() => {
                    this.checkSave=checkSave; //ripristino il valore precedente
                });
            },
            viewAllegato(item) {
                window.open(item.allegatoPath);
            },
            downloadAllegato(item) {
                var link = document.createElement('a');
                link.href = item.allegatoPath;
                link.download = item.allegato;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
            async eliminaAllegato(item) {
                bus.$emit('msg-open', { message: "Eliminare l'allegato?", item });
                bus.$on('msg-cancel', this.eliminaAllegatoCancel);
                bus.$on('msg-ok', this.eliminaAllegatoOk);
            },
            async eliminaAllegatoCancel(item) {
                bus.$off('msg-cancel', this.eliminaAllegatoCancel);
                bus.$off('msg-ok', this.eliminaAllegatoOk);
            },
            async eliminaAllegatoOk(item) {
                bus.$off('msg-cancel', this.eliminaAllegatoCancel);
                bus.$off('msg-ok', this.eliminaAllegatoOk);

                let indexAllegato = this.allegatiCliente.indexOf(item);

                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    idAllegatoCliente: item.id
                };
                let checkSave = this.checkSave;
                this.loadingDoc = true;

                const esito = await callPost('api/allegatoCliente/elimina', param);

                this.loadingDoc = false;

                if (esito) {
                    this.allegatiCliente.splice(indexAllegato, 1);
                    this.allegatiOpen = this.allegatiCliente.length > 0;
                    this.snackSuccess = true;
                }
                else this.snackError = true;

                this.$nextTick(() => {
                    this.checkSave=checkSave; //ripristino il valore precedente
                });
            },   
            selezionaRiga(item) {
                this.selectedItem = [item];
                this.carouselId = this.allegatiCliente.indexOf(item);
            },
            changeCarusel(i) {
                this.selectedItem = [this.allegatiCliente[i]];
            },
            newTask() {
                this.listaTask.push(
                    {
                        id: new Date().getTime().toString(),
                        idTask: (this.listaTask.length+1).toString(),
                        descrizione: "nuovo task",
                        operatore: {
                            id:''
                            }
                    })
            },
            eliminaTask(item) {
                var i = this.listaTask.indexOf(item);
                this.listaTask.splice(i, 1);
            },
            eliminaPratica() {
                bus.$emit('msg-open', { message: "Eliminare la pratica?", item: this.pratica });                
                bus.$on('msg-cancel', this.eliminaCancel); 
                bus.$on('msg-ok', this.eliminaOk);
            },
            eliminaCancel(item) {
                bus.$off('msg-cancel', this.eliminaCancel);
                bus.$off('msg-ok', this.eliminaOk);
            }, 
            async eliminaOk(pratica) {
                bus.$off('msg-cancel', this.eliminaCancel);
                bus.$off('msg-ok', this.eliminaOk);

                await this.save(false,false,true);
            },
            chiudiPratica() {
                let idx = this.listaTask.findIndex(t=>t.operatore.id && t.operatore.id!='0' && t.dataEsecuzione==null);
                let msgImportoKO = "La pratica non è ancora stata addebitata al cliente e non risulta impostato l'importo. Alla chiusura non verrà addebitato nulla";
                let msgImportoOk = "La pratica non è ancora stata addebitata al cliente. Alla chiusura verrà addebitato l'importo di "+this.importoEuro(this.pratica.importo);
                bus.$emit('msg-open', { message: "Chiudere la pratica? "+(idx==-1 ? "":" Sono presenti task assegnati ma non chiusi che saranno completati in automatico")+
                                                  (!this.addebito ? (this.pratica.importo>0 ? msgImportoOk: msgImportoKO):''), item: this.pratica });                
                bus.$on('msg-cancel', this.chiudiCancel); 
                bus.$on('msg-ok', this.chiudiOk);
            },
            chiudiCancel(item) {
                bus.$off('msg-cancel', this.chiudiCancel);
                bus.$off('msg-ok', this.chiudiOk);
            },
            async inserisciAddebito(noMsg) {
                let ok = this.pratica.importo>0;
                if(!ok) {
                    bus.$emit('msg-simple-open', {title: "Importo errato",message:"Inserire un importo maggiore di 0"});
                    return;
                }
                this.loading = true;
                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    idAnnoContabile: this.$store.state.idAnnoContabile,                    
                    importo: this.pratica.importo,
                    descrizione: 'Pratica '+(this.pratica.numeroPratica ? this.pratica.numeroPratica:'') +" - "+this.pratica.descrizione,
                    idCliente: this.pratica.cliente.id,
                    idPratica: this.pratica.id
                };

                this.addebito = await callPost('api/addebito/pratica', param);
                if (this.addebito) {
                    bus.$emit('cliente-changed', { lista: [this.pratica.cliente.id], sessionID: "-1" });
                    if (noMsg) {
                        this.loading = false;
                        return true;
                    }
                    this.snackSuccess = true;                    
                }
                else {
                    this.snackError = true;
                    if(noMsg) {
                        this.loading = false;
                        return false;
                    }
                }
                this.loading = false;
            },
            async chiudiOk(pratica) {
                bus.$off('msg-cancel', this.chiudiCancel);
                bus.$off('msg-ok', this.chiudiOk);
                if(!this.addebito && this.pratica.importo>0) {
                    let ok = await this.inserisciAddebito(true);
                    if(!ok) return;
                }
                await this.save(true,false,false);
            },
            riapriPratica() {
                bus.$emit('msg-open', { message: "Riaprire la pratica?", item: this.pratica });                
                bus.$on('msg-cancel', this.riapriCancel); 
                bus.$on('msg-ok', this.riapriOk);
            },
            riapriCancel(item) {
                bus.$off('msg-cancel', this.riapriCancel);
                bus.$off('msg-ok', this.riapriOk);
            },
            async riapriOk(pratica) {
                bus.$off('msg-cancel', this.riapriCancel);
                bus.$off('msg-ok', this.riapriOk);

                await this.save(false,true,false);
            },
            changeOperatore(item) {
                if(item.operatore.id==null) {
                    item.dataPresaInCarico=null;
                    item.dataEsecuzione=null;
                }
                else {
                    if(!this.$store.state.conf.isService) {
                        //solo per StudioPro l'assegnazione costituisce l'inizio del task
                        item.dataPresaInCarico=new Date();
                    }
                }                
            },    
            completa(item) {
                item.dataEsecuzione=new Date();
            },
            annullaCompleta(item) {
                item.dataEsecuzione=null;
            },
            changeTipoPratica() {
                let tpe = this.tipiPraticaEstesi.find(x=>x.tipoPratica.id==this.pratica.tipoPratica.id);
                this.diagram = tpe.diagramma;
                this.listaTask = tpe.listaTask;
            },
            editNode(nodeId) {
                //alert(nodeId);
            },
            logout() {
                this.$store.dispatch('logout');
                this.$store.dispatch('setUtente', null);
                this.$router.replace({ name: "login" });
            },
            checkData(d) {
                return gCheckData(d);
            },
            localData(v) {
                return gLocalData(v);
            },
            localDateTime(v) {
                return gLocalDateTime(v);
            },
            serverData(stringDate) {
                return gServerData(stringDate);
            },
            validate() {
                this.$refs.form.validate()
            },
            reset() {
                this.$refs.form.reset()
            },
            resetValidation() {
                this.$refs.form.resetValidation()
            },
            descrizioneApp(v) {
                if (v == false) return "Risposta via Mail/Telefono";
                return "Risposta via App StudioPro";
            },
            descrizioneRisposta(v) {
                if (v == false) return "Non autorizzata dal cliente";
                return "Autorizzata dal cliente";
            },
            async annulla() {
                this.loadingAnnulla = true;
                await this.internalCreated(Snack.cancel);    
                this.loadingAnnulla = false;
            },
            async salva() {
                await this.save(false, false, false);    
            },
            salvaModello() {
                let idx = this.listaTask.findIndex(t=>t.operatore.id && t.operatore.id!='0' && t.dataEsecuzione==null);
                bus.$emit('msg-input-open',
                        { message:"Lasciando come descrizione '"+this.pratica.tipoPratica.descrizione+"' verrà aggiornato il modello relativo a questo Tipo Pratica. Indicando una descrizione diversa verrà creato un nuovo modello a partire da questo. ",
                          label:"Descrizione Tipo Pratica", value:this.pratica.tipoPratica.descrizione,
                          item:this.pratica
                        });                
                bus.$on('msg-input-cancel', this.salvaModelloCancel); 
                bus.$on('msg-input-ok', this.salvaModelloOk);
            },
            salvaModelloCancel(pratica) {
                bus.$off('msg-input-cancel', this.salvaModelloCancel);
                bus.$off('msg-input-ok', this.salvaModelloOk);
            },
            async salvaModelloOk(pratica, descrizione) {
                bus.$off('msg-input-cancel', this.salvaModelloCancel);
                bus.$off('msg-input-ok', this.salvaModelloOk);
                await this.save(false, false, false, descrizione);   

            },
            async eliminaAddebito(){
                this.loading = true;
                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    idAnnoContabile: this.$store.state.idAnnoContabile,                    
                    idCliente: this.pratica.cliente.id,
                    idPratica: this.pratica.id
                };

                this.esitoASOK = await callPost('api/addebito/eliminaAddebitoPratica', param);
                if (this.esitoASOK) {
                    this.addebito=null;
                    bus.$emit('cliente-changed', { lista: [this.pratica.cliente.id], sessionID: "-1" });
                    this.snackSuccess = true;
                }
                else {
                    this.snackError = true;
                }
                this.loading = false;
            },
            
            async save(chiudi, riapri, elimina, descrizione) {

                if (!this.$refs.form.validate()) return;

                let pratica = JSON.parse(JSON.stringify(this.pratica));
                pratica.dataInizio = this.serverData(pratica.dataInizio);
                pratica.dataScadenza = this.serverData(pratica.dataScadenza);

                let paramJson = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    idOperatore: this.$store.state.utente.operatore.id,
                    pratica: pratica,
                    listaTask: this.listaTask,
                    chiudi: chiudi,
                    riapri: riapri,
                    descrizione: descrizione,
                    allegatiCliente:this.allegatiCliente
                };

                this.loadingSalva = true;

                if(elimina) {
                    let ok = await callPost('api/pratica/elimina', paramJson);
                    if(ok) {
                        bus.$emit('pratica-deleted', { lista: [this.pratica.id], sessionID: "-1" });
                        //bus.$emit('pratica-snack-succes',{});
                        this.loadingSalva = false;
                        this.dialog = false;    
                    }
                    else {
                        await this.internalCreated(Snack.error);
                        this.loadingSalva = false;   
                    }
                }
                else {
                    let pra = await callPost('api/pratica/save', paramJson);

                    if (pra && (chiudi || !riapri)) {
                        this.pratica.id = pra.id;
                        this.nuovo = false;
                        bus.$emit('pratica-changed', { lista: [this.pratica.id], sessionID: "-1" });
                        //bus.$emit('pratica-snack-succes',{});
                        await this.internalCreated(Snack.success);
                        this.loadingSalva = false;
                    }
                    else if (pra && riapri) {
                        await this.internalCreated(Snack.success);
                        bus.$emit('pratica-changed', { lista: [this.pratica.id], sessionID: "-1" });
                        this.loadingSalva = false;   
                    }
                    else {
                        await this.internalCreated(Snack.error);
                        this.loadingSalva = false;   
                    }
                }
            },
            chiudi() {
                if(!this.checkSave) {                    
                    this.dialog=false;     
                }
                else  {
                    bus.$emit('msg-open', { message: "Sono state rilevate delle modifiche.", item: this.pratica, okTxt:"Rimani sulla pratica",cancelTxt:"Chiudi senza salvare"});                
                    bus.$on('msg-cancel', this.esci); 
                    bus.$on('msg-ok', this.rimani);
                }
            },
            async rimani(item) {
                bus.$off('msg-cancel', this.esci);
                bus.$off('msg-ok', this.rimani);
            },
            async esci(item) {
                bus.$off('msg-cancel', this.esci);
                bus.$off('msg-ok', this.rimani);
                this.dialog=false; 
            },
            async internalCreated(snack) {

                if(this.nuovo) {
                    this.listaCliente = this.$store.state.clientiEstesi.filter(ce=>!ce.cliente.isCancellato);                 
                }   
                else {
                    this.listaCliente = this.$store.state.clientiEstesi; 
                }

                let param = [];
                this.tipiPraticaEstesi = await callService("api/pratica/listaTipiPratica", param);
                param = [this.pratica.id];
                let praticaEstesa = await callService("api/pratica/pratica", param); 
                
                this.listaOperatore = praticaEstesa.listaOperatoriCombo;

                param=[this.pratica.cliente.id, this.pratica.id]
                this.allegatiCliente = await callService("api/allegatocliente", param);
                this.allegatiOpen = this.allegatiCliente.length > 0;
                                    
                let pratica = praticaEstesa.pratica;
                pratica.dataInizio = this.localData(pratica.dataInizio);
                pratica.dataScadenza = this.localData(pratica.dataScadenza);

                this.pratica = pratica;
                this.addebito = praticaEstesa.addebito;
                praticaEstesa.listaTask.forEach(
                    function(t) {
                        if(t.operatore==null) t.operatore = {ID:"0"};
                        if(!t.dataEsecuzione) t.dataEsecuzione=null; //inserito perché dal json non arriva il campo per risparmiare spazio
                });
                this.listaTask = praticaEstesa.listaTask;
                this.diagram = praticaEstesa.diagramma;
                if (this.nuovo) {
                    this.pratica.operatoreCreazione.id = this.$store.state.utente.operatore.id;
                }

                this.$nextTick(() => {                    
                    this.checkSave = false;
                });

                this.snackSuccess = (snack == Snack.success);
                this.snackError = (snack == Snack.error);
                this.snackCancel = (snack == Snack.cancel);
                this.snackRemote = (snack == Snack.remote);

            },
            async showPratica(pratica) {

                this.type = null;
                this.dialog = true;
                this.$nextTick(() => {
                    this.$refs.form.resetValidation();
                });
                this.loading = true;
                this.nuovo = pratica.id == "0";
                this.pratica = pratica;
                await this.internalCreated(Snack.nothing);

                this.loading = false;
                this.type = "flowchart LR";
                this.$nextTick(() => {
                     this.$refs.form.resetValidation();
                });
            }
        },        
        watch:{
            pratica: {
              handler() {
                this.checkSave = true;               
              },
              deep: true,
            },
            listaTask: {
              handler() {
                this.checkSave = true;               
              },
              deep: true,
            }            
        },
        async created() {
            let theme = this.$cookie.get('theme') == "true"; //trasformo da string a bool
            this.theme = theme;
            this.listaCliente = this.$store.state.listaCliente;

            var vm = this;

            bus.$on('dialog-show-pratica', this.showPratica);
        },
        beforeDestroy() {
            bus.$off('dialog-show-pratica',this.showPratica);
        }
    }

</script>

