<template>
    <v-container fluid>
        <v-slide-y-transition mode="out-in">
            <v-row>
                <v-col>
                    <template>
                        <v-card>
                            <v-card-text>
                                <v-row class="mt-n5">
                                    <v-col cols="10">
                                        <v-row>
                                            <v-col cols="4">
                                                <v-text-field v-model="searchText"
                                                              append-icon="mdi-magnify"
                                                              :label="$store.state.conf.isService ? 'Ricerca un servizio':'Ricerca una pratica'"
                                                              single-line
                                                              :hint="hintSelezione"
                                                              persistent-hint
                                                              outlined
                                                              clearable
                                                              @keyup="changeSearch"
                                                              dense>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select v-model="operatore.id"
                                                          :items="listaOperatore"
                                                          item-value="id"
                                                          item-text="denominazioneNormalizzata"
                                                          label="Filtra per Operatore con Task assegnato"
                                                          clearable
                                                          outlined
                                                          @change="changeSearch"
                                                          dense>
                                                            <template v-slot:item="data">
                                                                <template>
                                                                    <v-list-item-content>
                                                                        <v-list-item-title :class="'text-subtitle-2 font-weight-medium '+(data.item.isCancellato ? 'red--text':'')">{{data.item.denominazioneNormalizzata}}</v-list-item-title>
                                                                    </v-list-item-content>
                                                                </template>
                                                            </template>
                                                        </v-select>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-select v-model="operatore2.id"
                                                          :items="listaOperatore"
                                                          item-value="id"
                                                          item-text="denominazioneNormalizzata"
                                                          label="Filtra per Operatore con Task non completato"
                                                          clearable
                                                          outlined
                                                          @change="changeSearch"
                                                          dense>
                                                          <template v-slot:item="data">
                                                            <template>
                                                                <v-list-item-content>
                                                                    <v-list-item-title :class="'text-subtitle-2 font-weight-medium '+(data.item.isCancellato ? 'red--text':'')">{{data.item.denominazioneNormalizzata}}</v-list-item-title>
                                                                </v-list-item-content>
                                                            </template>
                                                          </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-n8">
                                            <v-col cols="2">
                                                <v-text-field class="mt-2 mx-1"
                                                              v-model="dataDa"
                                                              label="Dal giorno di inizio"
                                                              outlined
                                                              clearable
                                                              persistent-hint
                                                              type="date"
                                                              @change="changeSearch"
                                                              dense>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-text-field class="mt-2"
                                                              v-model="dataA"
                                                              label="Al giorno di inizio"
                                                              outlined
                                                              clearable
                                                              persistent-hint
                                                              type="date"
                                                              @change="changeSearch"
                                                              dense>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-text-field class="mt-2 mx-1"
                                                              v-model="dataDas"
                                                              label="Dal giorno di scadenza"
                                                              outlined
                                                              clearable
                                                              persistent-hint
                                                              type="date"
                                                              @change="changeSearch"
                                                              dense>
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-text-field class="mt-2"
                                                              v-model="dataAs"
                                                              label="Al giorno di scadenza"
                                                              outlined
                                                              clearable
                                                              persistent-hint
                                                              type="date"
                                                              @change="changeSearch"
                                                              dense>
                                                </v-text-field>
                                            </v-col>
                                            <v-col v-if="!$store.state.conf.isService" cols="3">
                                                <v-select
                                                          v-model="professionista.id"
                                                          :items="listaOperatoriProfessionisti"
                                                          item-value="professionista.id"
                                                          item-text="denominazioneNormalizzata"
                                                          label="Professionista"
                                                          clearable
                                                          class="mt-2"
                                                          outlined
                                                          @change="changeSearch"
                                                          dense></v-select>
                                            </v-col>
                                            <v-col cols="1" class="mt-3">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn icon
                                                               color="teal"
                                                               v-bind="attrs"
                                                               v-on="on"
                                                               @click="nuovo">
                                                            <v-icon class="ma-0">mdi-calendar-plus</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span v-html="$store.state.conf.isService ? 'Nuovo Servizio':'Nuova Pratica'" />
                                                </v-tooltip>
                                            </v-col>
                                            <v-col cols="2" v-if="$store.state.conf.isService">
                                                <v-switch class="mt-0"
                                                          v-model="storico"
                                                          @click="change"
                                                          :label="storico ? 'storico: ultimo anno' : 'storico: ultimo mese'">
                                                </v-switch>
                                                <v-switch class="mt-n6"
                                                          v-model="isApp"
                                                          @click="changeSearch"
                                                          :label="isApp ? 'solo da App' : 'tutti i servizi'">
                                                </v-switch>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="2" class="mt-3">
                                        <v-row>
                                            <v-radio-group @change="changeSearch" hide-details class="mt-0 mx-5" v-model="statoPratica" row mandatory>
                                                <v-radio label="In lavorazione"
                                                         value="4"></v-radio>
                                                <v-radio v-if="$store.state.conf.isService"
                                                         :label="$store.state.conf.isService ? 'Servizi aperti':'Pratiche aperte'"
                                                         value="1"></v-radio>
                                                <v-radio :label="$store.state.conf.isService ? 'Tutti i servizi':'Tutte le pratiche'"
                                                         value="2"></v-radio>
                                                <v-radio :label="$store.state.conf.isService ? 'Servizi chiusi':'Pratiche chiuse'"
                                                         value="3"></v-radio>
                                            </v-radio-group>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-data-table v-if="$store.state.conf.isService"
                                          v-model="selezione"
                                          :headers="headers1"
                                          :items="praticheEstese"
                                          ref="tabella"
                                          :item-key="pratica.id"
                                          :loading="loading"
                                          :items-per-page="nPag"
                                          progress="78"
                                          class="elevation-1 mt-n8"
                                          :footer-props="{
                                            'items-per-page-text':'Pagina da',
                                            'items-per-page-all-text': 'Tutti',
                                            'items-per-page-options':[5,8,10,20,-1]
                                          }"
                                          @current-items="filterItems"
                                          @pagination="pagination"
                                          :search="search"
                                          :custom-filter="customFilter"
                                          loading-text="Caricamento dati..."
                                          :no-data-text="$store.state.conf.isService ? 'Nessun servizio presente in archivio':'Nessuna pratica presente in archivio'"
                                          :no-results-text="$store.state.conf.isService ? 'Nessun servizio corrisponde ai criteri di ricerca':'Nessuna pratica corrisponde ai criteri di ricerca'">

                                <template v-slot:item.menu="{item}">
                                    <v-btn icon>
                                        <v-icon color="orange darken-2" @click="anagrafica(item.pratica)">
                                            mdi-launch
                                        </v-icon>
                                    </v-btn>

                                </template>

                                <template v-slot:item.creazione="{ item }">
                                    <span v-if="item.pratica.operatoreCreazione" class="font-weight-light" v-text="item.pratica.operatoreCreazione.denominazioneNormalizzata" />
                                    <br  v-if="item.pratica.operatoreCreazione"/>
                                    <span class="text-subtitle" v-text="localData(item.pratica.dataCreazione)" />
                                </template>
                                <template v-slot:item.pratica.dataInizio="{ item }">
                                    <span class="text-subtitle" v-text="localData(item.pratica.dataInizio)" />
                                </template>
                                <template v-slot:item.pratica.tipoPratica.descrizione="{ item }">
                                    <span v-text="item.pratica.tipoPratica.descrizione" />
                                    <br />
                                    <span class="font-weight-light" v-text="item.pratica.descrizione" />
                                    <br />
                                    <v-chip class="mb-1" v-if="item.pratica.isApp" small dark label color="teal">
                                        Richiesta APP
                                    </v-chip>
                                    <v-chip class="mb-1" v-if="item.pratica.contratto" small dark label color="orange">
                                        Da contratto
                                    </v-chip>
                                </template>
                                <template v-slot:item.pratica.cliente.codice="{ item }">
                                    <span v-text="item.pratica.cliente.codice" />
                                    <br />
                                    <span class="font-weight-light" v-text="item.pratica.cliente.denominazioneNormalizzata" />
                                </template>

                                <template v-slot:item.operatoriProgress="{ item }">
                                    <v-chip small dark color="blue light"
                                            v-for="operatore in item.listaOperatoriTaskProgress"
                                            :key="operatore.id"
                                            v-text="operatore.nomeInizialeCognome">
                                    </v-chip>
                                </template>
                                <template v-slot:item.operatoriAperti="{ item }">
                                    <v-chip small dark color="red darken-3"
                                            v-for="operatore in item.listaOperatoriTaskAperti"
                                            :key="operatore.id"
                                            v-text="operatore.nomeInizialeCognome">
                                    </v-chip>
                                </template>
                                <template v-slot:item.operatoriChiusi="{ item }">
                                    <v-chip small dark color="teal"
                                            v-for="operatore in item.listaOperatoriTaskChiusi"
                                            :key="operatore.id"
                                            v-text="operatore.nomeInizialeCognome">
                                    </v-chip>
                                </template>
                                <template v-slot:item.pratica.dataScadenza="{ item }">
                                    <span class="text-subtitle" v-text="localData(item.pratica.dataScadenza)" />
                                </template>

                                <template v-slot:item.task1="{ item }">
                                    <span>{{item.taskProgress}}/{{item.taskAperti}}/{{item.taskChiusi}}</span>
                                </template>

                                <template v-slot:item.chiusa="{ item }">
                                    <v-icon v-if="item.pratica.isChiusa" color="teal">mdi-check-bold</v-icon>
                                    <v-tooltip v-if="item.addebito" bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" color="blue">mdi-currency-eur</v-icon>
                                        </template>
                                        <span>{{importoEuro(item.addebito.importo)}}</span>
                                    </v-tooltip>
                                </template>
                                <template v-slot:header.chiusa="{ item }">
                                    <v-icon small color="teal">mdi-check-bold</v-icon>
                                    <span v-html="$store.state.conf.isService ? 'Chiuso':'Chiusa'" />
                                    <br />
                                    <v-icon small color="blue">mdi-currency-eur</v-icon>
                                    <span v-html="$store.state.conf.isService ? 'Addebitato':'Addebitata'" />
                                </template>
                            </v-data-table>
                            <v-data-table v-else
                                          v-model="selezione"
                                          :headers="headers"
                                          :items="praticheEstese"
                                          ref="tabella"
                                          :item-key="pratica.id"
                                          :loading="loading"
                                          :items-per-page="nPag"
                                          progress="78"
                                          class="elevation-1 mt-n8"
                                          :footer-props="{
                                            'items-per-page-text':'Pagina da',
                                            'items-per-page-all-text': 'Tutti',
                                            'items-per-page-options':[5,8,10,20,-1]
                                          }"
                                          @current-items="filterItems"
                                          @pagination="pagination"
                                          :search="search"
                                          :custom-filter="customFilter"
                                          loading-text="Caricamento dati..."
                                          :no-data-text="$store.state.conf.isService ? 'Nessun servizio presente in archivio':'Nessuna pratica presente in archivio'"
                                          :no-results-text="$store.state.conf.isService ? 'Nessun servizio corrisponde ai criteri di ricerca':'Nessuna pratica corrisponde ai criteri di ricerca'">

                                <template v-slot:item.menu="{item}">
                                    <v-btn icon>
                                        <v-icon color="orange darken-2" @click="anagrafica(item.pratica)">
                                            mdi-launch
                                        </v-icon>
                                    </v-btn>

                                </template>

                                <template v-slot:item.creazione="{ item }">
                                    <span class="font-weight-light" v-text="item.pratica.operatoreCreazione.denominazioneNormalizzata" />
                                    <br />
                                    <span class="text-subtitle" v-text="localData(item.pratica.dataCreazione)" />
                                </template>
                                <template v-slot:item.pratica.dataInizio="{ item }">
                                    <span class="text-subtitle" v-text="localData(item.pratica.dataInizio)" />
                                </template>
                                <template v-slot:item.pratica.tipoPratica.descrizione="{ item }">
                                    <span v-text="item.pratica.tipoPratica.descrizione" /> [ <span v-text="item.pratica.numeroPratica" /> ]
                                    <br />
                                    <span class="font-weight-light" v-text="item.pratica.descrizione" />
                                </template>
                                <template v-slot:item.pratica.cliente.codice="{ item }">
                                    <span v-text="item.pratica.cliente.codice" />
                                    <br />
                                    <span class="font-weight-light" v-text="item.pratica.cliente.denominazioneNormalizzata" />
                                </template>

                                <template v-slot:item.operatoriProgress="{ item }">
                                    <v-chip small dark color="blue light"
                                            v-for="operatore in item.listaOperatoriTaskProgress"
                                            :key="operatore.id"
                                            v-text="operatore.nomeNormalizzato">
                                    </v-chip>
                                </template>
                                <template v-slot:item.operatoriAperti="{ item }">
                                    <v-chip small dark color="red darken-3"
                                            v-for="operatore in item.listaOperatoriTaskAperti"
                                            :key="operatore.id"
                                            v-text="operatore.nomeNormalizzato">
                                    </v-chip>
                                </template>
                                <template v-slot:item.operatoriChiusi="{ item }">
                                    <v-chip small dark color="teal"
                                            v-for="operatore in item.listaOperatoriTaskChiusi"
                                            :key="operatore.id"
                                            v-text="operatore.nomeNormalizzato">
                                    </v-chip>
                                </template>
                                <template v-slot:item.pratica.dataScadenza="{ item }">
                                    <span class="text-subtitle" v-text="localData(item.pratica.dataScadenza)" />
                                </template>

                                <template v-slot:item.task="{ item }">
                                    <span>{{item.taskAperti}}/{{item.taskChiusi}}</span>
                                </template>

                                <template v-slot:item.chiusa="{ item }">
                                    <v-icon v-if="item.pratica.isChiusa" color="teal">mdi-check-bold</v-icon>
                                    <v-tooltip v-if="item.addebito" bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" color="blue">mdi-currency-eur</v-icon>
                                        </template>
                                        <span>{{importoEuro(item.addebito.importo)}}</span>
                                    </v-tooltip>
                                </template>
                                <template v-slot:header.chiusa="{ item }">
                                    <v-icon small color="teal">mdi-check-bold</v-icon>
                                    <span v-html="$store.state.conf.isService ? 'Chiuso':'Chiusa'" />
                                    <br />
                                    <v-icon small color="blue">mdi-currency-eur</v-icon>
                                    <span v-html="$store.state.conf.isService ? 'Addebitato':'Addebitata'" />
                                </template>
                            </v-data-table>
                        </v-card>
                    </template>
                </v-col>
            </v-row>
        </v-slide-y-transition>

        <v-snackbar v-model="snackSuccess"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackError"
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel"
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote"
                    timeout="2000"
                    color="info">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento remoto.
        </v-snackbar>


    </v-container>   

</template>




<script>
    import { callService, callPost, euro, gLocalData, gCheckData, gServerData, gDataCalendar, gControllData, Snack } from '@/modules/utilities.js'
    import { bus } from '@/main.js'

    export default {
                
                data: () => ({
                    selezione: [],
                    filtro: [],
                    statoAll: false,
                    loading: true,
                    showError: false,
                    isApp: false,
                    viewMenuUtente: false,
                    snackSuccess: false,
                    snackError: false,
                    snackCancel: false,
                    snackRemote: false,
                    x: 0,
                    y: 0,
                    mb: 1,
                    dialogMB: false,
                    oggetto: '',
                    testo: '',
                    validMB: false,
                    loadingMB: false,
                    esitoMBOK: false,
                    esitoMBKO: false,
                    search: '',
                    storico: false,
                    searchText: '',
                    praticheEstese: [],
                    pratica: {
                        id:'0',
                        tipoPratica: {
                            id:'0'
                        }
                    },
                    headers: [
                        { text: 'Azioni', value: 'menu' },
                        { text: 'Tipologia', value: 'pratica.tipoPratica.descrizione', width: '15%' },
                        { text: 'Cliente', value: 'pratica.cliente.codice', sortable: true, width: '15%' },
                        { text: 'Creazione', value: 'creazione', sortable: false },
                        { text: 'Data Inizio', value: 'pratica.dataInizio' },
                        { text: 'Data Scadenza', value: 'pratica.dataScadenza' },
                        { text: 'Operatori con Task Aperti', value: 'operatoriAperti',sortable:false },
                        { text: 'Operatori con Task Completati', value: 'operatoriChiusi', sortable: false },
                        { text: 'Task aperti/completati', value: 'task', sortable: false },                      
                        { text: 'Chiusa/Addebitata', value: 'chiusa' },
                    ],
                    headers1: [
                        { text: 'Azioni', value: 'menu' },
                        { text: 'Tipologia', value: 'pratica.tipoPratica.descrizione', width: '15%' },
                        { text: 'Cliente', value: 'pratica.cliente.codice', sortable: true, width: '15%' },
                        { text: 'Creazione', value: 'creazione', sortable: false },
                        { text: 'Data Inizio', value: 'pratica.dataInizio' },
                        { text: 'Data Scadenza', value: 'pratica.dataScadenza' },
                        { text: 'Operatori con Task in Lavorazione', value: 'operatoriProgress', sortable: false },
                        { text: 'Operatori con Task Aperti', value: 'operatoriAperti', sortable: false },
                        { text: 'Operatori con Task Completati', value: 'operatoriChiusi', sortable: false },
                        { text: 'Task in lavorazione/ aperti/completati', value: 'task1', sortable: false },
                        { text: 'Chiusa/Addebitata', value: 'chiusa' },
                    ],
                    oggetto:'',
                    testo: '',
                    dataA: '',
                    dataDa: '',
                    dataAs: '',
                    dataDas: '',
                    conteggioUtenti: [],
                    notifica: true,
                    operatore: { id: '' },
                    operatore2: { id: '' },
                    professionista: { id: '' },
                    cliente: { id: '' },
                    notifica1: true,
                    notifica2: true,
                    moneyOptions: {
                        locale: "it-IT",
                        prefix: "€",
                        suffix: "",
                        length: 10,
                        precision: 2
                    },
                    statoPratica: '4'
                }),
                methods: {
                    localData(v) {
                        return gLocalData(v);
                    },
                    importoEuro(v) {
                        return euro(v);
                    },
                    controllData(v) {
                        return gControllData(v);
                    },
                    dataCalendar(v) {
                        return gDataCalendar(v);
                    },
                    descrizioneApp(v) {
                        if (v == false) return "Mail/Telefono";
                        return "App StudioPro";
                    },
                    descrizioneRisposta(v) {
                        if (v == false) return "Non autorizzata";
                        return "Autorizzata";
                    },
                    async elimina(item) {
                        bus.$emit('msg-open', { message: "Eliminare la pratica?", item });
                        bus.$on('msg-cancel', this.eliminaCancel);
                        bus.$on('msg-ok', this.eliminaOk);
                    }, 
                    async eliminaCancel(item) {
                        bus.$off('msg-cancel', this.eliminaCancel);
                        bus.$off('msg-ok', this.eliminaOk);
                    },
                    async eliminaOk(item) {
                        bus.$off('msg-cancel', this.eliminaCancel);
                        bus.$off('msg-ok', this.eliminaOk);

                        let param = {
                            partition: this.$store.state.partition,
                            username: this.$store.state.utente.username,
                            token: this.$store.state.utente.token,
                            sessionID: this.$store.state.sessionID,
                            id: item.id
                        };

                        this.loading = true;

                        const result = await callPost('api/pratica/eliminaPratica', param);

                        if (result) {
                            await this.internalCreated(Snack.success);
                        }
                        else {
                            await this.internalCreated(Snack.error);
                        }
                        this.loading = false;
                    },
                    filterItems(v){
                        this.$nextTick(() => {
                            this.filtro=this.$refs.tabella.$children[0].filteredItems;
                        });
                    },
                    anagrafica(pratica) {
                        this.pratica = JSON.parse(JSON.stringify(pratica));
                        if(this.$store.state.conf.isService) {
                            bus.$emit('dialog-show-servizio', this.pratica);
                        }
                        else {
                            bus.$emit('dialog-show-pratica', this.pratica);
                        }
                    },
                    nuovo() {
                        this.pratica = {
                            id: "0",
                            tipoPratica: { id:"0"},
                            cliente: {
                                id: "0", tipoCliente: {id:"0"},},
                            operatoreCreazione: { id:"0"}
                        }

                        if(this.$store.state.conf.isService) {
                            bus.$emit('dialog-show-servizio', this.pratica);
                        }
                        else {
                            bus.$emit('dialog-show-pratica', this.pratica);
                        }
                    },
                    toggleAll() {
                        this.statoAll = !this.statoAll;
                        this.$store.dispatch('setStatoAll',this.statoAll);

                        if(this.statoAll) {
                            this.selezione=this.filtro;
                        }
                        else {
                            this.selezione=[];
                        };
                        this.$store.dispatch('setSelezione',this.selezione);
                    },
                    toggleSelect() {
                        this.$nextTick(() => {
                            this.$store.dispatch('setSelezione',this.selezione);
                        });
                    },
                    async onPraticaChanged({ lista, sessionID }) {

                        if (sessionID == this.$store.state.sessionID) return;
                        for (var i = 0; i < lista.length; i++) {

                            let param = [lista[i]];
                            let praticaEstesa = await callService("api/pratica/pratica", param);

                            let pratica = praticaEstesa.pratica;

                            //cerco nella lista la pratica ricalcolata

                            const index = this.praticheEstese.findIndex(u => {
                                return u.pratica.id === pratica.id;
                            });

                            if (index >= 0) {
                                this.praticheEstese.splice(index, 1, praticaEstesa);
                            }
                            else {
                                this.praticheEstese.push(praticaEstesa);
                            }
                        }
                    },                    
                    async onPraticaDeleted({ lista, sessionID }) {

                        if (sessionID == this.$store.state.sessionID) return;
                        for (var i = 0; i < lista.length; i++) {

                            let idPratica = lista[i];
                            
                            //cerco nella lista la pratica eliminata

                            const index = this.praticheEstese.findIndex(u => {
                                return u.pratica.id === idPratica;
                            });

                            if (index >= 0) {
                                this.praticheEstese.splice(index, 1);
                            }                            
                        }
                    },
                    onSnackSuccess() {
                        this.snackSuccess = true;
                    },
                    pagination(pag) {
                        let nPag = pag.itemsPerPage;
                        this.$cookie.set('pratiche-pagination', nPag.toString(), { expires: '1Y' });
                    },
                    async internalCreated(snack) {

                        this.loading = true;
                        let param = [this.$store.state.conf.isService,this.storico];
                        this.praticheEstese = await callService('api/pratica/lista', param);
                        //console.log(JSON.stringify(this.praticheEstese));
                        this.loading = false;
                        //this.dataA = this.controllData(new Date());
                        //var d = new Date();
                        //d.setDate(d.getDate() - 1);
                        //this.dataDa = this.controllData(d);
                        //this.dataDas = this.controllData(d);
                        //var d1 = new Date();
                        //d1.setDate(d1.getDate() + 30);
                        //this.dataAs = this.controllData(d1);

                        //if (!this.$store.state.conf.isService)
                        //{
                        //    d.setDate(d.getDate() - 365);
                        //    this.dataDa = this.controllData(d);
                        //    this.dataDas = this.controllData(d);
                        //    d1.setDate(d1.getDate() + 335);
                        //    this.dataAs = this.controllData(d1);
                        //    this.dataA = this.controllData(d1);
                        //}


                        this.search = "x"; //faccio partire il filtro perché chiedo tutte le prstiche ma filtro sulle aperte
                        this.snackSuccess = (snack == Snack.success);
                        this.snackError = (snack == Snack.error);
                        this.snackCancel = (snack == Snack.cancel);
                        this.snackRemote = (snack == Snack.remote);
                    },       
                    customFilter(value, search, item) {
                        //console.log(JSON.stringify(item));
                        let ok = true;
                        if (ok && this.searchText) { //codice/dnz/fiscale in OR
                            let src = this.searchText.toLowerCase();
                            let ok1 = item.pratica.descrizione && item.pratica.descrizione.toLowerCase().indexOf(src) >= 0;
                            let ok2 = !ok1 && item.pratica.tipoPratica.descrizione.toLowerCase().indexOf(src) >= 0;
                            let ok3 = !ok1 && !ok2 && item.pratica.cliente && item.pratica.cliente.codice && item.pratica.cliente.codice.toLowerCase().indexOf(src) >= 0;
                            let ok4 = !ok1 && !ok2 && !ok3 && item.pratica.cliente.denominazioneNormalizzata.toLowerCase().indexOf(src) >= 0;
                            let ok5 = !ok1 && !ok2 && !ok3 && !ok4 && item.pratica.numeroPratica && item.pratica.numeroPratica.toLowerCase().indexOf(src) >= 0;
                            ok = ok1 || ok2 || ok3 || ok4 || ok5;
                        }

                        if (ok && this.professionista.id) {
                            ok = item.pratica.cliente.professionista.id == this.professionista.id;
                        }
                        
                        if (ok && this.operatore.id) {
                            let ok1 = item.listaOperatoriTaskAperti.findIndex(o => o.id == this.operatore.id) >= 0;
                            let ok2 = item.listaOperatoriTaskChiusi.findIndex(o => o.id == this.operatore.id) >= 0;
                            let ok3 = item.listaOperatoriTaskProgress.findIndex(o => o.id == this.operatore.id) >= 0;
                            ok = ok1 || ok2 || ok3;
                        }    

                        if (ok && this.operatore2.id) {
                            let ok1 = item.listaOperatoriTaskAperti.findIndex(o => o.id == this.operatore2.id) >= 0;
                            let ok2 = item.listaOperatoriTaskChiusi.findIndex(o => o.id == "XXX") >= 0;
                            let ok3 = item.listaOperatoriTaskProgress.findIndex(o => o.id == this.operatore2.id) >= 0;
                            ok = ok1 || ok2 || ok3;
                        }    

                        if (this.$store.state.conf.isService) {
                            if (ok) {
                                if (this.statoPratica == '2') //TUTTI//
                                    ok = ok;
                                else if (this.statoPratica == '1') //APERTE
                                    ok = !item.pratica.isChiusa
                                else if (this.statoPratica == '3') //CHIUSE
                                    ok = item.pratica.isChiusa
                                else if (this.statoPratica == '4') //IN Lavorazione
                                    ok = item.taskProgress > 0
                            }

                            if (ok) {
                                if (this.isApp) {
                                    ok = item.pratica.isApp;
                                }
                            }
                        }
                        else
                        {
                            if (ok) {
                                if (this.statoPratica == '2') //TUTTI//
                                    ok = ok;
                                else if (this.statoPratica == '4' || this.statoPratica == '1') //APERTE
                                    ok = !item.pratica.isChiusa
                                else if (this.statoPratica == '3') //CHIUSE
                                    ok = item.pratica.isChiusa
                            }
                        }


                        if (ok && this.dataDa) {
                            ok = this.dataCalendar(item.pratica.dataInizio) >= this.dataCalendar(this.dataDa);
                        }
                        if (ok && this.dataA) {
                            ok = this.dataCalendar(item.pratica.dataInizio) <= this.dataCalendar(this.dataA);
                        }

                        if (ok && this.dataDas) {
                            ok = this.dataCalendar(item.pratica.dataScadenza) >= this.dataCalendar(this.dataDas);
                        }
                        if (ok && this.dataAs) {
                            ok = this.dataCalendar(item.pratica.dataScadenza) <= this.dataCalendar(this.dataAs);
                        }
                     
                        return ok;
                    },
                    changeSearch() {
                            this.search =
                            this.searchText +
                            this.operatore.id + this.operatore2.id +                          
                            this.statoPratica + this.dataDa + this.dataA +
                            this.dataDaS + this.dataAS
                    },
                    async change() {
                         await this.internalCreated(Snack.nothing);
                    },                    
                },
                computed: {
                    hintSelezione() {
                        if(this.selezione.length==1) return "1 scadenza selezionata su "+this.filtro.length;
                        if(this.selezione.length>1) return this.selezione.length+" scadenze selezionate su "+this.filtro.length;
                        return "";
                    },
                    labelMB() {
                        return "Comunicazioni ai clienti (" + this.selezione.length + ")";
                    },
                    labelNuove() {
                        return this.selezione.filter(s => s.dataInvioRichiesta == null && s.dataRisposta == null).length;
                    },
                    labelSollecito() {
                        return this.selezione.filter(s => s.dataInvioRichiesta != null && s.dataRisposta == null).length;
                    },
                    labelNoMessaggi() {
                        return this.selezione.filter(s => s.dataInvioRichiesta != null && s.dataRisposta != null).length;
                    },                                     
                },                
                async created() {

                    this.clientiEstesi = this.$store.state.clientiEstesi;
                    this.listaOperatore = this.$store.state.listaOperatore;
                    this.listaOperatoriProfessionisti = this.$store.state.listaOperatoriProfessionisti;

                    bus.$on('pratica-changed', this.onPraticaChanged);
                    bus.$on('pratica-deleted', this.onPraticaDeleted);
                    bus.$on('pratica-snack-succes', this.onSnackSuccess);

                    this.nPag = Number(this.$cookie.get('pratiche-pagination')); //leggo il coockie della paginazione

                    await this.internalCreated(Snack.nothing);

                    
                },
                beforeDestroy() {
                    bus.$off('pratica-changed', this.onPraticaChanged);
                    bus.$off('pratica-deleted', this.onPraticaDeleted);
                    bus.$off('pratica-snack-succes', this.onSnackSuccess);
                }
    }
</script>



