<template>
    <v-form ref="form"
            v-model="valid"
            class="mt-4 ml-4 mr-4"
            lazy-validation>

        <v-card class="ma-2">
            <v-row dense>
                <v-col cols="2">
                    <v-select class="mt-2 ml-2" v-model="idOperatoreProfessionista"
                        :items="listaOperatoriProfessionisti"
                        item-value="id"
                        item-text="denominazioneNormalizzata"
                        label="Professionista"
                        @change="changeSearch"
                        outlined
                        clearable
                        dense>
                    </v-select>
                </v-col>
                <v-col cols="2">
                    <v-text-field class="mt-2 mx-1"
                                    v-model="dataDa"
                                    label="Data iniziale"
                                    outlined
                                    clearable
                                    persistent-hint
                                    type="date"
                                    @change="changeSearch"
                                    dense>
                    </v-text-field>
                </v-col>
                <v-col cols="2">
                    <v-text-field class="mt-2"
                                    v-model="dataA"
                                    label="Data finale"
                                    outlined
                                    clearable
                                    persistent-hint
                                    type="date"
                                    @change="changeSearch"
                                    dense>
                    </v-text-field>
                </v-col>
                
            </v-row>            
            <v-data-table no-data-text="Non sono presenti movimenti"
                                                    :headers="headers"
                                                    :loading="loading"
                                                    :items="listaMovimenti"
                                                    :items-per-page="10"
                                                    dense
                                                    class="elevation-1 ma-2"
                                                    ref="tabella"
                                                    :search="search"
                                                    :custom-filter="customFilter"
                                                    @current-items="filterItems"
                                                    :footer-props="{
                                        'items-per-page-text':'Pagina da',
                                        'items-per-page-all-text': 'Tutti',
                                        'items-per-page-options':[5,8,10,20,-1]
                                        }">
                                <template v-slot:item.data="{ item }">
                                    <span v-text="localData(item.data)" />
                                </template>                                   
                                <template v-slot:item.dare="{ item }">
                                    <span v-if="item.dare!=0" class="deep-orange--text text--darken-4"
                                            dark>
                                        <b>{{importoEuro(item.dare)}}</b>
                                    </span>
                                </template>
                                <template v-slot:item.avere="{ item }">
                                    <span v-if="item.avere!=0" :class="item.isAbbuono ? 'orange--text darken-2--text' :'teal--text'"
                                            dark>
                                        <b>{{importoEuro(item.avere)}}</b>
                                    </span>
                                </template>
                                <template v-slot:footer>
                                    <v-divider></v-divider>
                                    <v-row dense>                                       
                                        <v-col cols="2">
                                            <v-text-field class="ma-2" 
                                                            v-model="totaleDare"
                                                            label="Totale Dare"
                                                            outlined    
                                                            readonly                                                        
                                                            hide-details
                                                            reverse
                                                            dense>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="2">
                                            <v-text-field class="ma-2" 
                                                            v-model="imponibileDare"
                                                            label="Imponibile"
                                                            outlined
                                                            readonly
                                                            hide-details
                                                            reverse
                                                            dense>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="2">
                                            <v-text-field class="ma-2" 
                                                            v-model="ivaDare"
                                                            label="Iva (22%)"
                                                            outlined
                                                            readonly
                                                            hide-details
                                                            reverse
                                                            dense>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>        
                                    <v-row dense>                                       
                                        <v-col cols="2">
                                            <v-text-field class="ma-2"
                                                            v-model="totaleAvere"
                                                            label="Totale Avere"
                                                            outlined
                                                            readonly
                                                            hide-details
                                                            reverse
                                                            dense>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="2">
                                            <v-text-field class="ma-2" 
                                                            v-model="imponibileAvere"
                                                            label="Imponibile"
                                                            outlined
                                                            readonly
                                                            hide-details
                                                            reverse
                                                            dense>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="2">
                                            <v-text-field class="mt-2 mr-2" 
                                                            v-model="ivaAvere"
                                                            label="Iva (22%)"
                                                            outlined
                                                            readonly
                                                            hide-details
                                                            reverse
                                                            dense>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>        
                                </template>
            </v-data-table>

            <v-snackbar v-model="snackSuccess"
                        timeout="2000"
                        color="success">
                <v-icon dark>
                    mdi-checkbox-marked-circle
                </v-icon>
                Operazione eseguita con successo.
            </v-snackbar>
            <v-snackbar v-model="snackError"
                        timeout="2000"
                        color="error">
                <v-icon dark>
                    mdi-alert-circle
                </v-icon>
                Errore durante l'esecuzione dell'operazione.
            </v-snackbar>
            <v-snackbar v-model="snackCancel"
                        timeout="2000"
                        color="warning">
                <v-icon dark>
                    mdi-alert-circle
                </v-icon>
                Modifiche annullate.
            </v-snackbar>
        </v-card>
    </v-form>
</template>



<script>

    import { callService, callPost, callPostMultipart,gDataCalendar, euro, gLocalData, gCheckData, gServerData, Snack } from '@/modules/utilities.js'

    export default {
        data: () => ({
            iva: 22,
            valid: true,
            snackSuccess: false,
            snackError: false,
            snackCancel: false,
            loading: false,      
            moneyOptions: {
                locale: "it-IT",
                prefix: "€",
                suffix: "",
                length: 10,
                precision: 2
            },                                 
            chartOptions: {
                height: 120,
                legend: { position: 'none' },
                backgroundColor: { fill:'transparent' },
                vAxis: { format: '€ #' }
            },         
            listaMovimenti:[],
            listaOperatoriProfessionisti:[],
            search: '',
            searchState:[],
            filtro: [],
            totaleDare:null,
            totaleAvere:null,
            imponibileDare:null,
            imponibileAvere:null,
            ivaDare:null,
            ivaAvere:null,
            idOperatoreProfessionista: null,
            dataDa:null,
            dataA:null,
            headers: [
                { text: 'Data', value: 'data'},
                { text: 'Professionista', value: 'professionista.denominazioneNormalizzata'},                
                { text: 'Cliente', value: 'cliente.denominazioneNormalizzata'},
                { text: 'Descrizione', value: 'descrizione'},
                { text: 'Dare', value: 'dare',align:'end' },
                { text: 'Avere', value: 'avere', align:'end' },
            ],   
        }),
        methods: {             
            importoEuro(v) {
                return euro(v);
            },
            localData(v) {
                return gLocalData(v);
            },
            checkData(v) {
                return gCheckData(v);
            },
            serverData(v) {
                return gServerData(v);
            },
            dataCalendar(v) {
                return gDataCalendar(v);
            },
            customFilter(value, search, item) {
                let ok = true;
                
                if (ok && this.idOperatoreProfessionista) {
                    ok = item.professionista.id==this.idOperatoreProfessionista;
                }

                if (ok && this.dataDa) {
                    ok = this.dataCalendar(item.data) >= this.dataCalendar(this.dataDa);
                }
                if (ok && this.dataA) {
                    ok = this.dataCalendar(item.data) <= this.dataCalendar(this.dataA);
                }
                
                return ok;
            },
            changeSearch() {
                this.searchState =
                [this.idOperatoreProfessionista,
                this.dataDa,
                this.dataA
                ]; 
                this.search=this.searchState.toString();                
            },
            filterItems(v) {
                this.$nextTick(() => {
                    this.filtro = this.$refs.tabella.$children[0].filteredItems;
                    let totaleDare = this.filtro
                                        .map(item => item.dare)
                                        .reduce((prev, curr) => prev + curr, 0);
                    let totaleAvere = this.filtro
                                        .map(item => item.avere)
                                        .reduce((prev, curr) => prev + curr, 0);

                    this.totaleDare= this.importoEuro(totaleDare);
                    this.totaleAvere= this.importoEuro(totaleAvere);

                    this.imponibileDare =this.importoEuro(totaleDare*(100-this.iva)/100);
                    this.ivaDare =this.importoEuro(totaleDare*this.iva/100);
                    
                    this.imponibileAvere =this.importoEuro(totaleAvere*(100-this.iva)/100);
                    this.ivaAvere =this.importoEuro(totaleAvere*this.iva/100);
                });
            },
        },
        computed: {
           
        },
        async created() {
            let param = [this.$store.state.idAnnoContabile];
            this.loading=true;
            this.listaOperatoriProfessionisti=this.$store.state.listaOperatoriProfessionisti;
            this.listaMovimenti = await callService("api/movimenti/reportlight", param);
            this.loading=false;
        }
    }</script>
