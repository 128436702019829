<template>
    <v-form ref="form"
            v-model="valid"
            class="mt-4 ml-4 mr-4"
            lazy-validation>

        <v-card class="ma-2" :loading="loading">

            <template>
                <v-tabs>
                    <v-tab>Report Excel</v-tab>
                    <v-tab-item>

                        <template>

                            <v-row>
                                <v-col>
                                    <v-card :loading="loadingRE1"
                                            class="mx-auto my-12"
                                            color="#fffcda"
                                            max-width="400">
                                        <template slot="progress">
                                            <v-progress-linear color="green"
                                                               height="10"
                                                               indeterminate></v-progress-linear>
                                        </template>

                                        <v-card-title>Incassi potenziali</v-card-title>

                                        <v-card-text>
                                            <v-row align="center"
                                                   class="mx-0">
                                            </v-row>

                                            <div class="my-4 text-subtitle-1">
                                                Basato sulle tariffe impostate
                                            </div>

                                        </v-card-text>

                                        <v-card-actions>
                                            <v-btn color="teal"
                                                   text
                                                   @click="reportE1">
                                                Genera file Excel
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>

                                <v-col>
                                    <v-card :loading="loadingRE2"
                                            class="mx-auto my-12"
                                            color="#fffcda"
                                            max-width="400">
                                        <template slot="progress">
                                            <v-progress-linear color="green"
                                                               height="10"
                                                               indeterminate></v-progress-linear>
                                        </template>

                                        <v-card-title>Incassi potenziali per cliente</v-card-title>

                                        <v-card-text>
                                            <v-row align="center"
                                                   class="mx-0">
                                            </v-row>

                                            <div class="my-4 text-subtitle-1">
                                                Basato sulle tariffe impostate
                                            </div>

                                        </v-card-text>

                                        <v-card-actions>
                                            <v-btn color="teal"
                                                   text
                                                   @click="reportE2">
                                                Genera file Excel
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>

                                <v-col>
                                    <v-card :loading="loadingRE3"
                                            class="mx-auto my-12"
                                            color="#fffcda"
                                            max-width="400"
                                            disabled>
                                        <template slot="progress">
                                            <v-progress-linear color="green"
                                                               height="10"
                                                               indeterminate></v-progress-linear>
                                        </template>

                                        <v-card-title>Incassi effettivi ad oggi</v-card-title>

                                        <v-card-text>
                                            <v-row align="center"
                                                   class="mx-0">
                                            </v-row>

                                            <div class="my-4 text-subtitle-1">
                                                Incassato effettivo dello studio
                                            </div>

                                        </v-card-text>

                                        <v-card-actions>
                                            <v-btn color="teal"
                                                   text
                                                   @click="reportE3">
                                                Genera file Excel
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </template>                       

                    </v-tab-item>

                    <v-tab>Report PDF</v-tab>
                    <v-tab-item>
                        <template>
                            <v-row>
                                <v-col>
                                    <v-card :loading="loadingRP1"
                                            class="mx-auto my-12"
                                            color="#daf5ea"
                                            max-width="400"
                                            disabled>
                                        <template slot="progress">
                                            <v-progress-linear color="red"
                                                               height="10"
                                                               indeterminate></v-progress-linear>
                                        </template>

                                        <v-card-title>Incassi potenziali</v-card-title>

                                        <v-card-text>
                                            <v-row align="center"
                                                   class="mx-0">
                                            </v-row>

                                            <div class="my-4 text-subtitle-1">
                                                Basato sulle tariffe impostate
                                            </div>

                                        </v-card-text>

                                        <v-card-actions>
                                            <v-btn color="teal"
                                                   text
                                                   @click="reportP1">
                                                Genera file PDF
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </template>
                    </v-tab-item>

                    <v-spacer></v-spacer>

                </v-tabs>
            </template>

            <v-snackbar v-model="snackSuccess"
                        timeout="2000"
                        color="success">
                <v-icon dark>
                    mdi-checkbox-marked-circle
                </v-icon>
                Operazione eseguita con successo.
            </v-snackbar>
            <v-snackbar v-model="snackError"
                        timeout="2000"
                        color="error">
                <v-icon dark>
                    mdi-alert-circle
                </v-icon>
                Errore durante l'esecuzione dell'operazione.
            </v-snackbar>
            <v-snackbar v-model="snackCancel"
                        timeout="2000"
                        color="warning">
                <v-icon dark>
                    mdi-alert-circle
                </v-icon>
                Modifiche annullate.
            </v-snackbar>
        </v-card>
    </v-form>
</template>



<script>

    import { callService, callPost, callPostMultipart, euro, gLocalData, gCheckData, gServerData, Snack } from '@/modules/utilities.js'

    export default {
        data: () => ({
            valid: true,
            snackSuccess: false,
            snackError: false,
            snackCancel: false,
            loading: false,
            loadingRE1: false,
            loadingRE2: false,
            loadingRE3: false,
            loadingRP1: false,
            fileExcel: '',
            isSelecting: false,
            idProfessionista: '',
            moneyOptions: {
                locale: "it-IT",
                prefix: "€",
                suffix: "",
                length: 10,
                precision: 2
            },                                 
            chartOptions: {
                height: 120,
                legend: { position: 'none' },
                backgroundColor: { fill:'transparent' },
                vAxis: { format: '€ #' }
            },            
        }),
        methods: {
            async internalCreated(snack) {
                let param = [];
                let configurazione = await callService("api/configurazione/valoriconf", param);
                this.configurazione = configurazione;
                this.snackSuccess = (snack == Snack.success);
                this.snackError = (snack == Snack.error);
                this.snackCancel = (snack == Snack.cancel);
            },
            async reportE1() {
                this.loadingRE1 = true;
                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    idAnnoContabile: this.$store.state.idAnnoContabile,
                    idProfessionista: this.idProfessionista,
                };
                let outputPdf = await callPost('api/excel/fatturatoatteso', param);
                if (outputPdf) {
                    this.fileExcel = outputPdf.fileOutput;
                    window.open(this.fileExcel);
                    this.fileExcel = '';
                }
                else this.snackError = true;
                this.loadingRE1 = false;
            },
            async reportE2() {
                this.loadingRE2 = true;
                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    idAnnoContabile: this.$store.state.idAnnoContabile,
                    idProfessionista: this.idProfessionista,
                };
                let outputPdf = await callPost('api/excel/fatturatoattesoclienti', param);
                if (outputPdf) {
                    this.fileExcel = outputPdf.fileOutput;
                    window.open(this.fileExcel);
                    this.fileExcel = '';
                }
                else this.snackError = true;
                this.loadingRE2 = false;
            },
            async reportE3() {
                this.loadingRE3 = true;
                setTimeout(() => (this.loadingRE3 = false), 2000);
            },
            async reportP1() {
                this.loadingRP1 = true;
                setTimeout(() => (this.loadingRP1 = false), 2000);
            },
            scaricaExcel() {
                //alert(this.filePdf);
                window.open(this.fileExcel);
                this.fileExcel = '';
            },
            importoEuro(v) {
                return euro(v);
            },
            localData(v) {
                return gLocalData(v);
            },
            checkData(v) {
                return gCheckData(v);
            },
            serverData(v) {
                return gServerData(v);
            },
        },
        computed: {
            importoPagamentoEuro() {
                return euro(this.importoPagamento);
            },
            descrizioneOperatore() {
                let p = this.listaoperatori.find(x => x.id === this.idOperatore);
                if (p) return p.denominazioneNormalizzata;
                else return "";
            }
        },
        async created() {

            if (this.$store.state.utente.operatore.isProfessionista && this.$store.state.utente.isGestoreStudio) {
                this.idProfessionista = "all";
            }
            else if (this.$store.state.utente.operatore.isProfessionista) {
                this.idProfessionista = this.$store.state.utente.operatore.professionista.id;
            }
            else {
                this.idProfessionista = "all";
            }
        }
    }</script>
